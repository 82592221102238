import React, { useState, useEffect } from 'react';
import api from 'api';
import HeroContentType from 'containers/user/learn/components/hero-content-type/hero-content-type';
import NothingFound from 'containers/user/learn/components/empty-row/empty-row';
import Cards from 'containers/user/learn/components/cards/cards';
import Loader from 'components/partials/loader/loader';
import SeeMoreButton from 'containers/user/learn/components/see-more-btn/see-more-btn';
import { useCurrentUser } from 'state/current-user/current-user.thunk';

const Guides = () => {
  const [guides, setGuides] = useState([]);
  const [loading, setLoading] = useState(false);
  const [displayAll, setDisplayAll] = useState(false);

  const user = useCurrentUser();

  useEffect(() => {
    setLoading(true);
    api
      .getGuides(user?.data?.oktaUserId)
      .then((res) => {
        setGuides(
          res?.data?.data?.map((el, ind) => ({
            name: el.attributes?.name,
            route: `/learn/content/guides/${el.id}`,
            coverDescription: el.attributes?.coverDescription,
            coverImage: el.attributes?.coverImage?.data?.attributes?.url,
          }))
        );
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, []);

  const displayedGuides = displayAll ? guides : guides.slice(0, 3);

  return (
    <div className="w-full">
      <Loader loading={loading} />
      <HeroContentType
        title="Guides"
        description="Check out a collection of guides and tutorials created by MARKETview partners for MARKETview partners."
        breadcrumbs={[
          { label: 'Learn', href: '/learn' },
          { label: 'Guides', isActive: true },
        ]}
        customClass="pt-5"
      />
      <div className="flex flex-col items-center mt-10">
        {!displayedGuides.length && !loading ? (
          <NothingFound contentType="guides" />
        ) : (
          <Cards items={displayedGuides} contentType="Guide" />
        )}

        {guides.length > 3 && (
          <SeeMoreButton
            onClick={() => setDisplayAll(!displayAll)}
            showAll={displayAll}
          />
        )}
      </div>
    </div>
  );
};

export default Guides;
