import { useState } from 'react';

import { Link as RouterLink } from 'react-router-dom';
import uniqId from 'uniqid';
import Icons from 'assets/icons';
import ResponsiveWrapper from 'components/shared/others/responsive-wrapper/responsive-wrapper';
import classNames from 'classnames';

const Link = ({ name, route }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <RouterLink
      to={route}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="py-5 px-3 border-b border-gray-05 font-semibold hover:bg-gray-00 transition duration-200 flex justify-between items-center">
        <div
          className={classNames('text-dark-01', {
            'text-indigo-02': isHovered,
          })}
        >
          {name}
        </div>

        <Icons.Arrow
          className={classNames('shrink-0 text-blue-02', {
            'text-indigo-02': isHovered,
          })}
        />
      </div>
    </RouterLink>
  );
};

const Links = ({ items = [] }) => (
  <ResponsiveWrapper md="1120" lg="1120">
    {items.map(({ name, route }) => (
      <Link key={uniqId()} name={name} route={route} />
    ))}
  </ResponsiveWrapper>
);

export default Links;
