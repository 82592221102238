export const getIsRelatedContentShown = (content, keyToCheck) =>
  !!content.linkedWebinars?.data.length ||
  !!content.linkedDownloads?.data.length ||
  !!content.linkedGuides?.data.length ||
  !!content[keyToCheck]?.data.length;

export const getRelatedContent = (content, keyToCheck) => [
  ...content?.linkedWebinars?.data,
  ...content?.linkedDownloads?.data,
  ...content?.linkedGuides?.data,
  ...content[keyToCheck]?.data,
];
