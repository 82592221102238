import Icons from 'assets/icons';
//LEARN_POPOVER_MENU_ITEMS
export const getLearnPopoverMenuItems = ({
  articles,
  downloads,
  guides,
  webinars,
  courses,
}) => [
  {
    chapter: 'Categories',
    route: '/learn/categories',
    disabled: true,
    options: [
      {
        icon: <Icons.DataAnalysis customClass="w-6 h-6 -ml-1 mr-1" />,
        name: 'Data Analysis Fundamentals',
        description: 'Small description will be here',
        route: '/learn/categories/data-analysis',
        disabled: true,
      },
      {
        icon: <Icons.Optimizing customClass="w-6 h-6 -ml-1 mr-1" />,
        name: 'Optimizing Performance',
        description: 'Small description will be here',
        route: '/learn/categories/optimizing-performance',
        disabled: true,
      },
      {
        icon: <Icons.AdvancedAnalytics customClass="w-6 h-6 -ml-1 mr-1" />,
        name: 'Advanced Analytics Techniques',
        description: 'Small description will be here',
        route: '/learn/categories/advanced-analytics',
        disabled: true,
      },
      {
        icon: <Icons.DataVisualization customClass="w-6 h-6 -ml-1 mr-1" />,
        name: 'Data Visualization and Reporting',
        description: 'Small description will be here',
        route: '/learn/categories/data-visualization',
        disabled: true,
      },
      {
        icon: <Icons.Collaboration customClass="w-6 h-6 -ml-1 mr-1" />,
        name: 'Collaboration and Workflow',
        description: 'Small description will be here',
        route: '/learn/categories/collaboration-and-workflow',
        disabled: true,
      },
    ],
  },
  {
    chapter: 'Content',
    route: '/learn/content',
    options: [
      {
        icon: <Icons.Articles customClass="w-6 h-6 mr-1" />,
        name: 'Articles',
        description: 'Expert Insights on Trending Topics',
        route: '/learn/content/articles',
        disabled: !articles,
      },
      {
        icon: <Icons.Courses customClass="w-6 h-6 mr-1" />,
        name: 'Courses',
        description: 'Learn How To Use MARKETview',
        route: '/learn/courses',
        disabled: !courses,
      },
      {
        icon: <Icons.Downloads customClass="w-6 h-6 mr-1" />,
        name: 'Downloads',
        description: 'Access Infographics & Reports',
        route: '/learn/content/downloads',
        disabled: !downloads,
      },
      {
        icon: <Icons.Webinars customClass="w-6 h-6 mr-1" />,
        name: 'Webinars',
        description: 'Watch Webinar Recordings',
        route: '/learn/content/webinars',
        disabled: !webinars,
      },
      {
        icon: <Icons.Guides customClass="w-6 h-6 mr-1" />,
        name: 'Guides',
        description: 'Browse Tutorials and Tips',
        route: '/learn/content/guides',
        disabled: !guides,
      },
    ],
  },
];
