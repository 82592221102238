import uniqid from 'uniqid';

import { STATES } from 'constants/states';

export const BASIC_INFO_FORM_FIELDS = [
  {
    name: 'name',
    label: 'Client Name',
    rules: {
      required: 'This field is required. Please enter Client Name',
      pattern: /^.+\S$/,
    },
    mode: 'CREATE_AND_EDIT_MODE',
    key: uniqid(),
  },
  {
    name: 'externalId',
    label: 'Client ID',
    componentProps: { helperText: 'The ID of the school in PowerBI' },
    rules: {
      required: 'This field is required. Please enter Client ID',
      pattern: /^.+\S$/,
    },
    mode: 'CREATE_MODE',
    key: uniqid(),
  },
  {
    name: 'authorizedDomains',
    label: 'Authorized domains',
    componentProps: {
      isMulti: true,
      helperText: `e.g. "@schoolname.edu" and press space or enter to separate multiple domains`,
    },
    rules: {
      required: 'This field is required. Please enter Authorized domains',
      min: 1,
    },
    isSelect: true,
    mode: 'CREATE_AND_EDIT_MODE',
    key: uniqid(),
  },
  {
    name: 'state',
    label: 'State',
    componentProps: {
      options: STATES,
    },
    rules: {
      required: 'This field is required. Please select an option from the list',
    },
    isSelect: true,
    mode: 'CREATE_AND_EDIT_MODE',
    key: uniqid(),
  },
  {
    name: 'expireDate',
    label: 'Expire date',
    rules: {
      required: 'This field is required. Please enter Expire date',
      pattern: /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/,
    },
    mode: 'EDIT_MODE',
    key: uniqid(),
  },
];
