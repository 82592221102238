import React from 'react';
import HomeReportTiles from 'containers/user/home/components/home-report-tiles';
import ResponsiveWrapper from 'components/shared/others/responsive-wrapper/responsive-wrapper';

const Home = () => (
  <ResponsiveWrapper>
    <div className="my-5 font-black font-family text-4xl text-indigo-02">
      Welcome to MARKETview
    </div>
    <HomeReportTiles />
  </ResponsiveWrapper>
);

export default Home;
