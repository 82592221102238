import { useState, useEffect } from 'react';

import { COURSE_STATUSES } from 'containers/user/learn/containers/content/cources/course.constants';

import api from 'api';

const useFetchCourse = (userId, courseId) => {
  const [loading, setLoading] = useState(true);
  const [course, setCourse] = useState(null);
  const [courseMetadata, setCourseMetadata] = useState(null);

  useEffect(() => {
    const fetchCourseDetails = async () => {
      setLoading(true);
      let courseMetadata = null;

      try {
        const courseRes = await api.getCourse(courseId, userId);

        const { id, attributes = {} } = courseRes.data.data;

        attributes.lessons.data = attributes.lessons?.data.filter(
          (lesson) => lesson.attributes.publishedAt
        ); // filter unpublished lessons

        let averageProgress = 0;
        let status = COURSE_STATUSES.UNKNOWN;

        try {
          const courseMetadataRes = await api.getCourseFullData({
            userId,
            courseId,
          });

          setCourseMetadata(courseMetadataRes);

          courseMetadata = courseMetadataRes.data;

          if (courseMetadata.lessons && courseMetadata.lessons.length > 0) {
            const courseLessonIds = new Set(
              attributes.lessons?.data?.map((lesson) => lesson.id)
            );

            const filteredLessons = courseMetadata.lessons.filter((lesson) =>
              courseLessonIds.has(lesson.id)
            );

            if (filteredLessons.length > 0) {
              const totalProgress = filteredLessons.reduce(
                (acc, lesson) => acc + (lesson.progress || 0),
                0
              );

              averageProgress = Math.round(
                totalProgress / attributes.lessons?.data?.length
              );
            }
          }

          status =
            COURSE_STATUSES[courseMetadata.status] || COURSE_STATUSES.UNKNOWN;
        } catch (metadataError) {
          if (metadataError.response?.status === 404) {
            console.error('Course metadata not found');
          }
        }

        const enrichedCourse = {
          ...attributes,
          id,
          averageProgress,
          status: averageProgress === 100 ? COURSE_STATUSES.DONE : status,
          name: attributes.name,
          route: `/learn/course/${courseId}`,
          coverDescription: attributes.coverDescription,
          coverImage: attributes.coverImage?.url,
          firstLessonId: attributes.lessons?.data[0]?.id,
        };

        setCourse(enrichedCourse);
      } catch (error) {
        console.error('Failed to fetch course:', error);
      } finally {
        setLoading(false);
      }
    };

    if (userId && courseId) {
      fetchCourseDetails();
    }
  }, [userId, courseId]);

  return { loading, course, courseMetadata };
};

export default useFetchCourse;
