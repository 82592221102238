import React, { useState, useEffect, useLayoutEffect } from 'react';
import uniqid from 'uniqid';

import Heading from 'components/partials/heading/heading';
import Loader from 'components/partials/loader/loader';
import Breadcrumbs from 'components/partials/beadcrumbs/breadcrumbs';
// import WasResourseHelpfulBanner from 'containers/user/learn/components/was-resource-helpful/was-resource-helpful';
import RelatedContent from 'containers/user/learn/components/related-content/related-content';
import PageNav from 'containers/user/learn/components/page-nav/page-nav';

import api from 'api';

import { useCurrentUser } from 'state/current-user/current-user.thunk';

import { getBlocksFromContent } from 'containers/user/learn/heplers/get-blocks-from-content';
import {
  getIsRelatedContentShown,
  getRelatedContent,
} from 'containers/user/learn/heplers/content-builder-helpers';
import { onResize } from 'helpers/resize';
import ResponsiveWrapper from 'components/shared/others/responsive-wrapper/responsive-wrapper';

const Guide = ({ guideId }) => {
  const [guide, setGuide] = useState({});
  const [loading, setLoading] = useState(false);
  const [isTabletView, setIsTabletView] = useState(false);

  const user = useCurrentUser();

  useEffect(() => {
    setLoading(true);
    api
      .getGuide(guideId, user?.data?.oktaUserId)
      .then((res) => {
        setGuide(res.data.data.attributes);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, []);

  useLayoutEffect(() => {
    onResize(setIsTabletView);
  }, []);

  return loading ? (
    <Loader {...{ loading }} />
  ) : (
    <ResponsiveWrapper md="1120" lg="1120" customClass="flex flex-col">
      <Breadcrumbs
        breadcrumbs={[
          { label: 'Learn', href: '/learn' },
          { label: 'Guides', href: '/learn/content/guides' },
        ]}
        customClass="!mt-5"
      />
      <Heading customClass="text-4xl font-semibold mobile:pt-4 xs:pt-6 mobile:pb-6 xs:pb-12">
        {guide.name}
      </Heading>
      {isTabletView && (
        <PageNav
          content={guide.content}
          isTabletView={true}
          navTitle="In this guide"
        />
      )}
      <div className="flex mobile:flex-col md:flex-row order-1 justify-between">
        <ResponsiveWrapper md="780" lg="780" customClass="flex flex-col">
          {guide.content?.map((component) =>
            getBlocksFromContent({ ...component, key: uniqid() })
          )}
          {/* <WasResourseHelpfulBanner relatedTags={guide.relatedTags} /> */}
          {getIsRelatedContentShown(guide, 'guideIsLinkedTo') && (
            <RelatedContent
              content={getRelatedContent(guide, 'guideIsLinkedTo')}
            />
          )}
        </ResponsiveWrapper>
        <div className="flex flex-col">
          {!isTabletView && (
            <PageNav content={guide.content} navTitle="In this guide" />
          )}
        </div>
      </div>
    </ResponsiveWrapper>
  );
};

export default Guide;
