import { saveAs } from 'file-saver';

import CustomButton from 'components/partials/custom-button/custom-button';
import Icons from 'assets/icons';

const UploadButton = ({ label, file }) => {
  const dowloadFile = () => {
    const fileName = file?.data?.attributes?.name;
    const fileUrl = file?.data?.attributes?.url;

    if (!fileUrl || !fileName) {
      console.error('File data is missing');

      return;
    }

    saveAs(fileUrl, fileName);
  };

  return (
    <div className="flex flex-1 mobile:mb-6 xs:mb-8">
      <CustomButton
        onClick={dowloadFile}
        variant="primary"
        iconAfter={<Icons.Download customClass="w-4 h-4 ml-2" />}
      >
        {label}
      </CustomButton>
    </div>
  );
};

export default UploadButton;
