import React, { useContext, useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import classNames from 'classnames';

import CustomInput from 'components/partials/custom-text-input/custom-text-input';
import CustomSelect from 'components/partials/custom-select/custom-select';

import { UserProfileContext } from 'containers/admin/users/user/user-profile/user-profile';

import { useClients } from 'state/clients/clients.thunk';

import {
  mapClientsToSelectOptions,
  mapRolesToSelectOptions,
} from 'helpers/forms';

import { PERSONAL_INFO_FORM_ALWAYS_EDITABLE_FIELDS } from 'containers/admin/users/user/forms/personal-info-form/personal-info-form-fields.constants';
import { USER_DETAILS_FORM_FIELDS } from 'containers/admin/users/user/forms/user-details-form/user-details-form-fields.constants';
import { USER_ROLES_FORM_FIELDS } from 'containers/admin/users/user/forms/user-details-form/user-role-form-fields.constants.';
import { useReadOnly } from 'hooks/read-only';

const UserDetailsForm = ({
  currentUserRole,
  currentClient,
  isNotNewUser,
  isFormDisabled,
  isSelectDisabled,
  isMVUser,
}) => {
  const { control, errors, clearErrors, resetField } =
    useContext(UserProfileContext);

  const [isSelectVisible, setIsSelectVisible] = useState(false);
  const [allClients, setAllClients] = useState([]);
  const [showHelperText, setShowHelperText] = useState(true);
  const [currentSelectedRole, setCurrentSelectedRole] = useState(null);
  const [currentSelectedClient, setCurrentSelectedClient] = useState(null);
  const [currentAuthorizedDomains, setCurrentAuthorizedDomains] = useState(
    '@domain.com, @domain.net.'
  );
  const [userDetailsFields, setUserDetailsFields] = useState(
    USER_DETAILS_FORM_FIELDS
  );

  const { load } = useClients();

  const readOnly = useReadOnly();

  const domainText =
    currentClient?.authorizedDomains || currentAuthorizedDomains;

  const baseStyles = classNames('flex flex-col', {
    'w-full': isFormDisabled,
    'w-3/6': !isFormDisabled && !isNotNewUser,
  });

  const filterFormFieldsByMode = (filterKey) =>
    USER_DETAILS_FORM_FIELDS.filter((field) => field.mode === filterKey);

  const calculateFormFieldDisability = (fields) =>
    fields.map((field) => {
      const disabled =
        !PERSONAL_INFO_FORM_ALWAYS_EDITABLE_FIELDS.has(field.name) && readOnly;

      return { ...field, disabled };
    });

  const addOptions = (label) => {
    switch (label) {
      case 'Role':
        return mapRolesToSelectOptions(USER_ROLES_FORM_FIELDS);

      case 'Client':
        return mapClientsToSelectOptions(allClients);
    }
  };

  const customOnChange = (label, field) => {
    switch (label) {
      case 'Role':
        return (item) => {
          setCurrentSelectedRole(item);

          if (item.value !== 'user') {
            setUserDetailsFields(
              userDetailsFields.filter((elem) => {
                if (elem.name !== 'client') return elem;
              })
            );

            setShowHelperText(false);
            setCurrentSelectedClient(null);
          } else {
            setShowHelperText(true);
            setUserDetailsFields(USER_DETAILS_FORM_FIELDS);
          }

          field.onChange(item.value);
        };

      case 'Client':
        return (item) => {
          if (item) setCurrentSelectedClient(item);

          field.onChange(item);
        };
    }
  };

  useEffect(() => {
    if (!isNotNewUser) {
      return;
    }

    const fields = calculateFormFieldDisability(
      filterFormFieldsByMode('CREATE_AND_EDIT_MODE')
    );

    setUserDetailsFields(fields);
  }, [isNotNewUser]);

  useEffect(() => {
    if (currentUserRole === 'user' || currentUserRole === 'Campus User') {
      const defaultRole = {
        label: 'Campus User',
        value: 'user',
      };

      setCurrentSelectedRole(defaultRole);
    }
  }, [currentUserRole]);

  useEffect(() => {
    if (!currentSelectedRole) {
      return;
    }

    const { value } = currentSelectedRole;
    const selectIsDisplayed = value === 'user';

    !selectIsDisplayed && clearErrors('client');

    !selectIsDisplayed && resetField('client');

    clearErrors('email');

    setIsSelectVisible(selectIsDisplayed);
  }, [currentSelectedRole, currentSelectedClient]);

  useEffect(() => {
    if (!isSelectDisabled) {
      load({
        pageSize: 9999,
      }).then(({ data }) => setAllClients(data));
    }
  }, [isSelectDisabled]);

  useEffect(() => {
    const selectedClient = allClients.find(
      (client) => client.id === currentSelectedClient?.value
    );

    if (selectedClient)
      setCurrentAuthorizedDomains(selectedClient?.authorizedDomains);
  }, [currentSelectedClient]);

  return (
    <div className={baseStyles}>
      {userDetailsFields.map(
        ({
          label,
          name,
          key,
          isSelect,
          renderComponentProps,
          disabled,
          alwaysVisible,
        }) => (
          <Controller
            key={key}
            name={name}
            control={control}
            render={({ field }) =>
              isSelect ? (
                (isSelectVisible || alwaysVisible) && (
                  <CustomSelect
                    customClass="w-36"
                    fieldError={errors[name]}
                    label={label}
                    labelInTop
                    clearInputErr={() => clearErrors(name)}
                    labelFor={name}
                    errors={errors}
                    disabledBgColor={
                      (isSelectDisabled || isFormDisabled) && '#F2F2F2'
                    }
                    disabled={isSelectDisabled || isFormDisabled || disabled}
                    options={
                      isSelectDisabled
                        ? isMVUser
                          ? addOptions(label)
                          : [{ label: 'Campus User', value: 'user' }]
                        : addOptions(label)
                    }
                    required={
                      isMVUser
                        ? !isSelectDisabled
                        : isSelectDisabled
                        ? isFormDisabled && disabled
                        : !isFormDisabled
                    }
                    {...field}
                    onChange={customOnChange(label, field)}
                  />
                )
              ) : (
                <CustomInput
                  customClass="w-36"
                  errorsNames={errors[name]}
                  domainText={name === 'email' ? domainText : ''}
                  label={label}
                  labelFor={name}
                  labelInTop
                  inputWrapperCustomClass={'bg-gray-06'}
                  disabled={isFormDisabled || disabled}
                  disabledClassName={
                    (isFormDisabled || readOnly) && 'bg-gray-06'
                  }
                  required={isMVUser ? !isSelectDisabled : !isFormDisabled}
                  {...field}
                  {...renderComponentProps}
                  helperText={
                    name === 'email'
                      ? showHelperText &&
                        (currentSelectedClient || currentClient)
                        ? renderComponentProps.helperText
                        : ''
                      : renderComponentProps.helperText
                  }
                />
              )
            }
          />
        )
      )}
    </div>
  );
};

export default UserDetailsForm;
