import React from 'react';
import classNames from 'classnames';

import { withRouter, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

const SwitcherItem = withRouter(({ exact, location, link, label }) => {
  const match = matchPath(location.pathname, { path: link });
  const active =
    match && (exact ? location.pathname === link : link === match.path);

  return (
    <RouterLink
      className={classNames('py-2 px-5 rounded-lg font-family', {
        'text-gray-03 shadow-md bg-white font-semibold': active,
        'text-gray-04 font-normal': !active,
      })}
      to={link}
    >
      {label}
    </RouterLink>
  );
});

export default SwitcherItem;
