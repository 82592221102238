import uniqid from 'uniqid';
import { Link as RouterLink } from 'react-router-dom';

import ResponsiveWrapper from 'components/shared/others/responsive-wrapper/responsive-wrapper';
import LessonsSidebarItem from 'containers/user/learn/containers/content/cources/course-details/course-sidebar/course-sidebar-item/course-sidebar-item';
import HorizontalProgressBar from 'containers/user/learn/components/horizontal-progress-bar/horizontal-progress-bar';
import Progress from 'containers/user/learn/components/progress/progress';

import Icons from 'assets/icons';

import { countAverageProgress } from 'containers/user/learn/containers/content/cources/courses.utils';

const LessonsSidebar = ({
  lessons,
  lessonOnClick,
  courseId,
  currentLessonId,
  withHeader = true,
  customWrapperClass = '',
  lessonsProgress,
}) => {
  const averageProgress = countAverageProgress(lessonsProgress);

  if (!lessons) {
    return;
  }

  return (
    <div className={`w-[300px] px-1.5 ${customWrapperClass}`}>
      {withHeader && (
        <>
          <RouterLink
            to={`/learn/course/${courseId}`}
            className="flex font-semibold items-center gap-1 text-blue-02 hover:text-indigo-02 group mb-5"
          >
            <Icons.Arrow customClass="h-4 w-4 rotate-180 transition-transform duration-300 group-hover:-translate-x-1" />
            <span>See course overview</span>
          </RouterLink>
          <HorizontalProgressBar
            progress={averageProgress}
            customClass="mb-1"
          />
          <Progress averageProgress={averageProgress} customClass="mb-3" />

          <div className="text-base font-semibold text-blue-03 mb-3">
            Lessons
          </div>
        </>
      )}

      <div className="flex flex-col !w-full">
        <ol className="!w-full">
          {lessons.map((lesson, index) => (
            <LessonsSidebarItem
              {...lesson}
              key={uniqid()}
              index={index}
              onClick={lessonOnClick}
              courseId={courseId}
              currentLessonId={currentLessonId}
              progress={lessonsProgress[`lesson-${lesson?.id}`]}
            />
          ))}
        </ol>
      </div>
    </div>
  );
};

export default LessonsSidebar;
