import React, { useState, useEffect } from 'react';
import CustomTextInput from 'components/partials/custom-text-input/custom-text-input';

import {
  formatNumberWithSuffix,
  parseFormattedNumber,
  formatWithThousandSeparator,
} from 'components/partials/formatted-input/formatted-input.helpers';

const FormattedTextInput = ({
  value: initialValue,
  onChange = () => {},
  errorsNames,
  disabled,
  withSuffix = true,
  ...props
}) => {
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (initialValue) {
      const formattedValue = withSuffix
        ? formatNumberWithSuffix(initialValue)
        : formatWithThousandSeparator(initialValue);

      setInputValue(formattedValue);
    } else if (!isNaN(initialValue)) {
      setInputValue('');
    }
  }, [initialValue]);

  const handleInputChange = (e) => {
    const { value } = e.target;
    const cleanedValue = value.replace(/,/g, '');

    setInputValue(formatWithThousandSeparator(cleanedValue));

    if (!!errorsNames) {
      onChange(value);
    }
  };

  const handleBlur = () => {
    const parsedValue = parseFormattedNumber(inputValue.replace(/,/g, ''));

    onChange(parsedValue);
    if (!isNaN(parsedValue)) {
      const formattedValue = withSuffix
        ? formatNumberWithSuffix(parsedValue)
        : formatWithThousandSeparator(parsedValue);

      setInputValue(formattedValue);
    }
  };

  return (
    <CustomTextInput
      {...props}
      disabled={disabled}
      value={inputValue}
      onChange={handleInputChange}
      errorsNames={errorsNames}
      onBlur={handleBlur}
    />
  );
};

export default FormattedTextInput;
