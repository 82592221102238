import React, { useContext } from 'react';
import { Controller } from 'react-hook-form';

import CustomSelect from 'components/partials/custom-select/custom-select';
import FileUploader from 'components/partials/file-uploader/file-uploader';
import Textarea from 'components/partials/textarea/textarea';

import { FileContext } from 'containers/admin/clients/client/tabs-components/files-tab/file/file';

const FILE_NAMES = [
  'Geographic Analysis',
  'Market Position Analysis',
  'Retention Analysis',
  'Goals & Planning',
];

const FilesDetailsForm = ({
  customClass = '',
  control,
  errors,
  isFormDisabled = false,
}) => {
  const { clearErrors } = useContext(FileContext);

  return (
    <div className={`${customClass} flex`}>
      <div className="flex flex-col w-full mr-10 justify-between">
        <Controller
          name="fileName"
          control={control}
          render={({ field }) => (
            <CustomSelect
              isCreateble={true}
              fieldError={errors['fileName']}
              label="File name"
              labelInTop={true}
              clearInputErr={() => clearErrors('fileName')}
              labelFor="fileName"
              errors={errors}
              disabled={isFormDisabled}
              placeholder="Start typing to select or use your option..."
              disabledBgColor="#F2F2F2"
              options={FILE_NAMES.map((name) => ({
                value: name,
                label: name,
              }))}
              customClass="!mt-0 mb-2"
              required={!isFormDisabled}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="file"
          defaultValue=""
          render={({ field }) => (
            <FileUploader
              acceptableFiles=".xlsx,.pdf,.docx,.pptx"
              helpText="The file must be either .pdf, .pptx, .xlsx or .docx and must be less
      than 50 MB"
              label="Document"
              error={errors['file']}
              disabled={isFormDisabled}
              required={!isFormDisabled}
              {...field}
            />
          )}
        />
      </div>
      <Controller
        control={control}
        name="description"
        render={({ field }) => (
          <Textarea
            placeholder="Please type file description..."
            label="File description"
            disabled={isFormDisabled}
            {...field}
          />
        )}
      />
    </div>
  );
};

export default FilesDetailsForm;
