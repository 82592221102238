import React from 'react';

import Divider from 'components/shared/others/divider/divider';
import ResponsiveWrapper from 'components/shared/others/responsive-wrapper/responsive-wrapper';

import SOCBadge from 'assets/images/soc_2.svg';

const Footer = () => (
  <div className="flex flex-col items-center w-full global-footer">
    <Divider customClass="mb-5" />
    <ResponsiveWrapper customClass="flex w-[inherit] height-[46px] items-center mb-5 !m-auto">
      <div className="w-2/3 flex flex-wrap justify-between">
        <div className="flex">
          <a
            href="https://marketviewedu.com/terms-conditions/"
            target="_blank"
            className="text-indigo-02 font-semibold font-family"
          >
            Terms &amp; Conditions
          </a>
          <a
            href="https://marketviewedu.com/marketview-platform-privacy-policy/"
            target="_blank"
            className="ml-8 text-indigo-02 font-semibold font-family"
          >
            Privacy Policy
          </a>
        </div>
      </div>

      <div className="w-1/3 font-bold text-sm italic text-right">
        <a
          href="https://www.aicpa.org/interestareas/frc/assuranceadvisoryservices/aicpasoc2report.html"
          target="_blank"
          className="flex justify-end"
        >
          <img
            src={SOCBadge}
            alt="MARKETview is proud to be SOC 2 Type II Certified"
            className="w-[95px] h-[46px]"
          />
        </a>
      </div>
    </ResponsiveWrapper>
  </div>
);

export default Footer;
