import api from 'api/api.config';

//content
export const getArticles = (oktaUserId) =>
  api.get(`/${oktaUserId}/cms/articles?populate=*`);

export const getArticle = (articleId, oktaUserId) =>
  api.get(
    `/${oktaUserId}/cms/articles/${articleId}?populate[0]=content.videoSrc.data.attributes.url&populate[1]=content.imageSrc.data.attributes.url&populate[2]=authorAvatar&populate[3]=linkedArticles&populate[4]=linkedWebinars&populate[5]=linkedDownloads&populate[6]=linkedGuides`
  );

export const getGuides = (oktaUserId) =>
  api.get(`/${oktaUserId}/cms/guides?populate=*`);

export const getGuide = (guideId, oktaUserId) =>
  api.get(
    `/${oktaUserId}/cms/guides/${guideId}?populate[0]=content.videoSrc.data.attributes.url&populate[1]=content.imageSrc.data.attributes.url&populate[2]=authorAvatar&populate[3]=linkedArticles&populate[4]=linkedWebinars&populate[5]=linkedDownloads&populate[6]=linkedGuides&populate[7]=guideIsLinkedTo`
  );

export const getDownloads = (oktaUserId) =>
  api.get(
    `/${oktaUserId}/cms/downloads?populate=*&sort=assetCreationDate:desc`
  );

export const getDownload = (downloadId, oktaUserId) =>
  api.get(`/${oktaUserId}/cms/downloads/${downloadId}?populate=*`);

export const getWebinars = (oktaUserId) =>
  api.get(`/${oktaUserId}/cms/webinars?populate=*&sort=webinarDate:desc`);

export const getWebinar = (webinarId, oktaUserId) =>
  api.get(
    `/${oktaUserId}/cms/webinars/${webinarId}?populate[0]=content.videoSrc.data.attributes.url&populate[1]=linkedArticles&populate[2]=linkedWebinars&populate[3]=linkedDownloads&populate[4]=linkedGuides&populate[5]=webinarIsLinkedTo&&populate[6]=content.imageSrc.data.attributes.url&&populate[7]=presenters.presenterAvatar.data.attributes.url&&populate[8]=content.file.data.attributes.url`
  );

export const getCourses = (oktaUserId) =>
  api.get(`/${oktaUserId}/cms/courses?populate=*&sort=publishDate:desc`);

export const getCourse = (courseId, oktaUserId) =>
  api.get(
    `${oktaUserId}/cms/courses/${courseId}?populate[0]=content&populate[1]=lessons`
  );

export const getLesson = (lessonId, oktaUserId) =>
  api.get(
    `${oktaUserId}/cms/lessons/${lessonId}?populate[0]=content.videoSrc.data.attributes.url&populate[1]=content.imageSrc.data.attributes.url`
  );
