import React, { useMemo } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
} from '@tanstack/react-table';

import PaginationWrapper from 'components/partials/table-component/pagination-wrapper/pagination-wrapper';
import { Table } from 'components/partials/table-component/table/table';

import classNames from 'classnames';

const TableComponent = ({
  columns,
  data,
  isLoading,
  pageIndex,
  pageSize,
  setPagination,
  setAmount,
  sortingParams,
  manualSorting = false,
  setSortingParams,
  isMultiSort = false,
  totalRecords,
  showingRecordsTextIsOn = true,
  viewByAmountSelectIsOn = true,
  pagination = true,
  customHeight = 'max-h-[62vh]',
}) => {
  //set total amount of pages
  const pagesAmount = useMemo(
    () => Math.ceil(totalRecords / pageSize),
    [pageSize, totalRecords]
  );

  const onPaginationChange = (updater) => {
    const { pageIndex: newPageIndex } = updater({ pageIndex, pageSize });

    setPagination(newPageIndex);
  };

  //set table props
  const table = useReactTable({
    data,
    columns,
    manualSorting,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    pageCount: pagesAmount,
    state: { sorting: sortingParams, pageIndex, pageSize },
    onPaginationChange: onPaginationChange,
    enableSortingRemoval: false,
    onSortingChange: setSortingParams,
    enableMultiSort: isMultiSort,
    isMultiSortEvent: () => true,
    maxMultiSortColCount: 2,
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    debugTable: true,
  });

  return (
    <div className="w-full flex-column">
      {!!pagination && (
        <div className={classNames('flex items-center mb-2 justify-end')}>
          <PaginationWrapper
            showingRecordsTextIsOn={showingRecordsTextIsOn}
            viewByAmountSelectIsOn={viewByAmountSelectIsOn}
            table={table}
            pageIndex={pageIndex}
            pageSize={pageSize}
            totalRecords={totalRecords}
            pagesAmount={pagesAmount}
            setAmount={setAmount}
          />
        </div>
      )}
      <Table
        {...{ table, sortingParams, isLoading, isMultiSort, customHeight }}
      />
    </div>
  );
};

export default TableComponent;
