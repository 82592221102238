import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

export const FILE_DETAILS_FORM_VALIDATION_SCHEMA = yupResolver(
  yup.object({
    fileName: yup
      .object()
      .nullable()
      .required('Please select an option from the list or use your own one'),
    file: yup
      .mixed()
      .required()
      .test('required', 'The file is required', (value) => {
        const isFile = value instanceof File || value.isFakeFile;

        return isFile;
      })
      .test(
        'size',
        'The file must be less than 50 MB',
        (value) => value && value?.size < 52428800
      )
      .test(
        'name',
        'The file must be either pdf, pptx, xlsx or docx',
        (value) => {
          const formatsRegexp = /(\.pdf|\.pptx|\.docx|\.xlsx)$/i;

          return value && !!formatsRegexp.exec(value?.name);
        }
      ),
  })
);

export const FILES_STATUSES = {
  ACTIVE: 'ACTIVE',
  HIDDEN: 'HIDDEN',
  ARCHIVED: 'ARCHIVED',
};

export const ALERTS = {
  SUCCESS: { heading: 'File was succesfully uploaded', variant: 'success' },
  ACTIVE: { heading: 'File was succesfully activated', variant: 'success' },
  UPDATED: { heading: 'File was succesfully updated', variant: 'success' },
  HIDDEN: { heading: 'File was hidden', variant: 'warning' },
  ARCHIVED: { heading: 'File was archived', variant: 'error' },
};
