import React from 'react';
import classNames from 'classnames';

import Icons from 'assets/icons';

const NotificationMessage = ({
  heading,
  message = '',
  variant,
  removeNotification,
  customClass,
  children,
}) => {
  const defaultIconProps = {
    width: '12px',
    height: '100%',
    customClass: 'mr-2 w-6 h-6',
  };

  const notificationParams = {
    info: {
      textColor: 'text-indigo-02',
      borderColor: 'border-indigo-02',
      icon: <Icons.Info alt="info-icon" {...defaultIconProps} />,
    },
    success: {
      textColor: 'text-green-02',
      borderColor: 'border-green-02',
      icon: <Icons.Success alt="success-icon" {...defaultIconProps} />,
    },
    error: {
      textColor: 'text-red-04',
      borderColor: 'border-red-04',
      icon: <Icons.Warning alt="error-icon" {...defaultIconProps} />,
    },
    warning: {
      textColor: 'text-amber-00',
      borderColor: 'border-amber-00',
      icon: <Icons.Warning alt="warning-icon" {...defaultIconProps} />,
    },
  };

  const { borderColor, textColor } = notificationParams[variant];

  return (
    <div
      className={`border rounded-md w-full mt-5 p-4 justify-between flex ${borderColor} ${customClass}`}
    >
      <div className="flex items-start">
        <span className={`${textColor}`}>
          {notificationParams[variant]?.icon}
        </span>
        <div className="flex flex-col ">
          <span className={`text-base font-semibold ${textColor}`}>
            {heading}
          </span>
          {message && <span className="text-sm mt-2">{message}</span>}
          {children}
        </div>
      </div>
      {removeNotification && (
        <Icons.Close
          className={classNames('cursor-pointer', textColor, {
            'mt-1': !message,
          })}
          onClick={removeNotification}
        />
      )}
    </div>
  );
};

export default NotificationMessage;
