import React, { useEffect, createContext, useState } from 'react';
import { Switch, Route } from 'react-router';
import { SecureRoute } from '@okta/okta-react';

import api from 'api';

import OktaLogin from 'containers/login/okta-widget/okta-login';
import MaintenancePage from 'containers/maintenance-page';
import AuthenticatedApp from 'containers/authenticated-app';
import EnrollAccount from 'containers/enroll/enroll-account';
import ResetPassword from 'containers/reset-password';
import RequestPassword from 'containers/request-password';

import ToastContainer from 'components/partials/toast/toast-container/toast-container';

import { useCurrentUser } from 'state/current-user/current-user.thunk';

const defaultFeatureFlags = {
  articles: false,
  webinars: true,
  downloads: true,
  guides: true,
  courses: true,
};

export const FeatureFlagsContext = createContext({
  featureFlags: defaultFeatureFlags,
});

const GlobalWrapper = ({ children }) => {
  const [featureFlags, setFeatureFlags] = useState(defaultFeatureFlags);

  const user = useCurrentUser();

  useEffect(() => {
    async function getAllFeatureFlags() {
      const { data: featureFlags } = await api
        .getFeatureFlagsSetting(user?.data?.oktaUserId)
        .then((response) => response)
        .catch((err) => {
          console.log('Feature Flags Setting ERROR:', err);
        });

      setFeatureFlags((prevState) => ({
        ...prevState,
        articles: featureFlags['cms-articles'],
        webinars: true,
        guides: true,
        downloads: true,
        courses: true,
        
      }));
    }
    !!user?.data && getAllFeatureFlags();
  }, [user.data]);

  return (
    <FeatureFlagsContext.Provider
      value={{
        featureFlags,
      }}
    >
      {children}
    </FeatureFlagsContext.Provider>
  );
};

function App() {
  return (
    <GlobalWrapper>
      <ToastContainer />
      <Switch>
        <Route path="/(login|implicit)">
          <OktaLogin />
        </Route>
        <Route path="/account/:oktaUserId/:activationKey">
          <EnrollAccount />
        </Route>
        <Route path="/account/reset">
          <ResetPassword />
        </Route>
        <Route path="/account/request-password-reset" exact>
          <RequestPassword />
        </Route>
        <Route path="/">
          <SecureRoute
            path="/"
            component={(props) =>
              props.location.pathname !== '/login' && (
                <MaintenancePage>
                  <AuthenticatedApp />
                </MaintenancePage>
              )
            }
          />
        </Route>
      </Switch>
      <div id="menu-portal"></div>
      <div id="page-overlay"></div>
      <div id="modal-overlay"></div>
    </GlobalWrapper>
  );
}

export default App;
