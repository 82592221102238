import React, { useState, useEffect } from 'react';
import api from 'api';
import Links from 'containers/user/learn/components/links/links';
import { useCurrentUser } from 'state/current-user/current-user.thunk';
import HeroContentType from 'containers/user/learn/components/hero-content-type/hero-content-type';
import NothingFound from 'containers/user/learn/components/empty-row/empty-row';
import Loader from 'components/partials/loader/loader';
import SeeMoreButton from 'containers/user/learn/components/see-more-btn/see-more-btn';

const Articles = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [displayAll, setDisplayAll] = useState(false);

  const user = useCurrentUser();

  useEffect(() => {
    setLoading(true);
    api
      .getArticles(user?.data?.oktaUserId)
      .then((res) => {
        setArticles(
          res?.data?.data?.map((el, ind) => ({
            name: el.attributes?.name,
            route: `/learn/content/articles/${el.id}`,
          }))
        );
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, []);

  const displayedArticles =
    displayAll || articles.length < 5 ? articles : articles.slice(0, 5);

  return (
    <div className="w-full">
      <Loader loading={loading} />
      <HeroContentType
        title="Articles"
        description="Explore the expansive universe of MARKETview articles. Elevate your understanding, stay informed with expert perspectives, and unravel the layers of knowledge within."
        breadcrumbs={[
          { label: 'Learn', href: '/learn' },
          { label: 'Articles', isActive: true },
        ]}
        customClass="pt-5"
      />
      <div className="flex flex-col items-center pt-5">
        {!displayedArticles.length && !loading ? (
          <NothingFound contentType="articles" />
        ) : (
          <Links items={displayedArticles} />
        )}

        {articles.length > 5 && (
          <SeeMoreButton
            onClick={() => setDisplayAll(!displayAll)}
            showAll={displayAll}
          />
        )}
      </div>
    </div>
  );
};

export default Articles;
