import React from 'react';
import Icons from 'assets/icons';

const CircularProgress = ({ progress = 0 }) => {
  const size = 22;
  const strokeWidth = 4;
  const radius = (size - strokeWidth) / 2;
  const center = size / 2;
  const circumference = 2 * Math.PI * radius;
  const rotationTransform = `rotate(-90 ${center} ${center})`;

  const isComplete = progress === 100;

  const strokeDashoffset = isComplete
    ? 0
    : circumference - (progress / 100) * circumference;

  return (
    <div className="w-6 h-6 relative flex justify-center items-center">
      <svg width={size} height={size} className="w-full h-full">
        {isComplete ? (
          <circle
            className="text-indigo-02"
            strokeWidth={strokeWidth}
            strokeDasharray="0"
            strokeDashoffset={strokeDashoffset}
            strokeLinecap="round"
            stroke="currentColor"
            fill="currentColor"
            r={radius}
            cx={center}
            cy={center}
          />
        ) : (
          <>
            <circle
              className="text-gray-05"
              strokeWidth={strokeWidth}
              stroke="currentColor"
              fill="transparent"
              r={radius}
              cx={center}
              cy={center}
              transform={rotationTransform}
            />
            <circle
              className="text-indigo-02"
              strokeWidth={strokeWidth}
              strokeDasharray={circumference}
              strokeDashoffset={strokeDashoffset}
              strokeLinecap="round"
              stroke="currentColor"
              fill="transparent"
              r={radius}
              cx={center}
              cy={center}
              transform={rotationTransform}
            />
          </>
        )}
      </svg>
      {isComplete && (
        <div className="absolute top-[5px]">
          <Icons.Check className="text-white h-3 w-3" />
        </div>
      )}
    </div>
  );
};

export default CircularProgress;
