import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';

import api from 'api';

import LoginLayout from 'containers/login/login-layout';
import Heading from 'components/partials/heading/heading';
import Loader from 'components/partials/loader/loader';
import CustomTextInput from 'components/partials/custom-text-input/custom-text-input';
import CustomButton from 'components/partials/custom-button/custom-button';
import toast from 'components/partials/toast/toast';

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(/[a-z]/, 'Password must contain a lowercase letter')
    .matches(/[A-Z]/, 'Password must contain an uppercase letter')
    .matches(/[0-9]/, 'Password must contain a number')
    .matches(/[#?!@$%^&*-]/, 'Password must contain a symbol'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Must match password')
    .required('Password confirmation is required'),
});

const EnrollAccount = () => {
  const [valid, setValid] = useState(null);
  const { activationKey, oktaUserId } = useParams();
  const history = useHistory();

  useEffect(() => {
    // Validate the activation key
    api
      .validateActivationKey(activationKey)
      .then(() => setValid(true))
      .catch((error) => {
        toast.error({
          title: 'Error activating user',
          message: `We couldn't find a user matching that activation key (${activationKey}). Please try again or contact support.`,
        });
        setValid(false);
      });
  }, [activationKey]);

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      password: '',
      passwordConfirmation: '',
    },
  });

  const onSubmit = async (values) => {
    try {
      await api.activateUserAccount({
        activationKey,
        oktaUserId,
        password: values.password,
      });

      toast.success({
        title: 'Success!',
        message: 'Your account has been activated. Please login to continue.',
      });

      // redirect to login after activation
      history.push('/login');
    } catch (error) {
      toast.error({
        title: 'Activation error',
        message: `There was an error when activating your account. Please try again or contact support.`,
      });
    }
  };

  return (
    <LoginLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        {valid !== false && (
          <>
            {valid === null ? (
              <Loader loading={true} />
            ) : (
              <>
                <Heading
                  variant="h2"
                  customClass="font-semibold mb-4 block text-center mobile:text-xl tablet:text-3xl"
                >
                  Choose a password
                </Heading>
                <Controller
                  name="password"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <CustomTextInput
                      {...field}
                      type="password"
                      label="Password"
                      placeholder="Enter your password"
                      errorsNames={error}
                      labelInTop={true}
                      helperText="At least 8 characters, with 1 number and 1 special character"
                    />
                  )}
                />
                <Controller
                  name="passwordConfirmation"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <CustomTextInput
                      {...field}
                      type="password"
                      label="Confirm Password"
                      placeholder="Confirm your password"
                      errorsNames={error}
                      labelInTop={true}
                    />
                  )}
                />
                <div className="flex justify-end mt-4">
                  <CustomButton
                    type="submit"
                    variant="primary"
                    disabled={isSubmitting}
                    customClass="w-full"
                  >
                    {isSubmitting ? 'Submitting...' : 'Continue'}
                  </CustomButton>
                </div>
              </>
            )}
          </>
        )}
      </form>
    </LoginLayout>
  );
};

export default EnrollAccount;
