'use strict';

import { renderConfirmationModal } from 'helpers/confirmation-render';

import Icons from 'assets/icons';

export const getFileDetailsActionMenuItems = ({
  fileName = '',
  clientName = '',
  onActionAplied,
  status,
}) =>
  [
    (status === 'HIDDEN' || status === 'ARCHIVED') && {
      label: `Show file`,
      icon: <Icons.Eye customClass="mr-2" />,
      onClick: () =>
        renderConfirmationModal({
          headerText: `Are you sure you want to show ${fileName} file?`,
          text: `All ${clientName}'s users will have access to this file`,
          onConfirmCallback: () => onActionAplied({ status: 'ACTIVE' }),
          onConfirmButtonLabel: 'Show file',
        }),
    },

    status !== 'HIDDEN' && {
      label: `Hide file`,
      icon: <Icons.Hidden customClass="mr-2" />,
      onClick: () =>
        renderConfirmationModal({
          headerText: `Are you sure you want to hide ${fileName} file?`,
          text: `All ${clientName}'s users won't have access to this file`,
          onConfirmCallback: () => onActionAplied({ status: 'HIDDEN' }),
          onConfirmButtonLabel: 'Hide file',
        }),
    },

    status !== 'ARCHIVED' && {
      label: `Archive file`,
      icon: <Icons.Archived customClass="mr-2" />,
      onClick: () =>
        renderConfirmationModal({
          headerText: `Are you sure you want to archive ${fileName} file?`,
          text: `All ${clientName}’s users won’t have access to this document and file will be hidden from the Files.`,
          onConfirmCallback: () => onActionAplied({ status: 'ARCHIVED' }),
          onConfirmButtonLabel: 'Archive file',
        }),
    },
  ].filter((item) => item);
