import React, { useState, useEffect } from 'react';

import { useCurrentUser } from 'state/current-user/current-user.thunk';

const NotificationPoint = ({ isNoContent = false }) => {
  const [newAssetsNumber, setNewAssetsNumber] = useState(0);

  const user = useCurrentUser();

  const baseStyles =
    'rounded-full bg-red-04 w-2 h-2 flex items-center justify-center';

  useEffect(() => {
    if (!isNoContent) {
      user.setNewFilesCount();
    }

    const currentNewAssetsNumber =
      user.filesCount < 10 ? user.filesCount : '9+';

    setNewAssetsNumber(currentNewAssetsNumber);
  }, [user.filesCount]);

  return isNoContent ? (
    <div
      className={`${baseStyles} absolute border-2 border-white p-1.5 left-[31%] md:left-[32%] lg:left-[34%] xl:left-[35%]`}
    ></div>
  ) : (
    !!newAssetsNumber && (
      <div
        className={`${baseStyles} absolute xs:right-[-14px] xs:-top-2.5 p-2.5`}
      >
        <span className="text-white text-xs md:text-sm mb-px">
          {newAssetsNumber}
        </span>
      </div>
    )
  );
};

export default NotificationPoint;
