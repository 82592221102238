import { toast as toastify } from 'react-toastify';

import Icons from 'assets/icons';

const toast = (props) => toastify(<Toast {...props} />, { ...props });
const iconClassName = 'w-6 h-6';

Object.keys(toastify).forEach((key) => {
  toast[key] = toastify[key];
});

toast.error = (props) =>
  toastify.error(<Toast {...props} />, {
    ...props,
    icon: <Icons.Warning className={iconClassName} />,
  });

toast.info = (props) =>
  toastify.info(<Toast {...props} />, {
    ...props,
    icon: <Icons.Info className={iconClassName} />,
  });

toast.success = (props) =>
  toastify.success(<Toast {...props} />, {
    ...props,
    icon: <Icons.Success className={iconClassName} />,
  });

toast.warning = (props) =>
  toastify.warning(<Toast {...props} />, {
    ...props,
    icon: <Icons.Warning className={iconClassName} />,
  });

toast.neutral = (props) =>
  toastify.info(<Toast {...props} />, {
    ...props,
    icon: <Icons.Info className={iconClassName} />,
  });

const Toast = ({ title, message, children }) => (
  <div className="space-y-4">
    <div className="text-default space-y-1 leading-6">
      {title && <p className="font-bold">{title}</p>}
      {message && <p className="text-gray-04">{message}</p>}
    </div>
    {children && <div>{children}</div>}
  </div>
);

export default toast;
