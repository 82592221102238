import React, { useState, useEffect, useLayoutEffect } from 'react';
import uniqid from 'uniqid';

import Loader from 'components/partials/loader/loader';
import Breadcrumbs from 'components/partials/beadcrumbs/breadcrumbs';
// import WasResourseHelpfulBanner from 'containers/user/learn/components/was-resource-helpful/was-resource-helpful';
import BasicInfo from 'containers/user/learn/components/basic-info/basic-info';
import RelatedContent from 'containers/user/learn/components/related-content/related-content';
import PageNav from 'containers/user/learn/components/page-nav/page-nav';
import GetStartedGuideBanner from 'containers/user/learn/components/get-started-guide-banner/get-started-guide-banner';

import api from 'api';

import { useCurrentUser } from 'state/current-user/current-user.thunk';

import { getBlocksFromContent } from 'containers/user/learn/heplers/get-blocks-from-content';
import {
  getIsRelatedContentShown,
  getRelatedContent,
} from 'containers/user/learn/heplers/content-builder-helpers';
import { onResize } from 'helpers/resize';
import ResponsiveWrapper from 'components/shared/others/responsive-wrapper/responsive-wrapper';
import Heading from 'components/partials/heading/heading';

const Article = ({ articleId }) => {
  const [article, setArticle] = useState({});
  const [loading, setLoading] = useState(false);
  const [isTabletView, setIsTabletView] = useState(false);

  const user = useCurrentUser();

  useEffect(() => {
    setLoading(true);
    api
      .getArticle(articleId, user?.data?.oktaUserId)
      .then((res) => {
        setArticle(res.data.data.attributes);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, []);

  useLayoutEffect(() => {
    onResize(setIsTabletView);
  }, []);

  return loading ? (
    <Loader {...{ loading }} />
  ) : (
    <ResponsiveWrapper md="1120" lg="1120" customClass="flex flex-col">
      <Breadcrumbs
        breadcrumbs={[
          { label: 'Learn', href: '/learn' },
          { label: 'Articles', href: '/learn/content/articles' },
        ]}
        customClass="!mt-5"
      />
      <Heading customClass="text-4xl font-semibold mobile:pt-4 xs:pt-6">
        {article.name}
      </Heading>
      <BasicInfo {...article} />
      {isTabletView && (
        <PageNav content={article.content} isTabletView={true} />
      )}
      <div className="flex mobile:flex-col md:flex-row order-1 justify-between">
        <ResponsiveWrapper md="780" lg="780" customClass="flex flex-col">
          {article.content?.map((component) =>
            getBlocksFromContent({ ...component, key: uniqid() })
          )}
          {/* <WasResourseHelpfulBanner relatedTags={article.relatedTags} /> */}
          {getIsRelatedContentShown(article, 'articleIsLinkedTo') && (
            <RelatedContent
              content={getRelatedContent(article, 'articleIsLinkedTo')}
            />
          )}
        </ResponsiveWrapper>
        <div className="flex flex-col">
          {!isTabletView && <PageNav content={article.content} />}
          <GetStartedGuideBanner
            isPopoverBanner={false}
            vertical={!isTabletView}
            customClass="md:max-w-[300px] p-6 mobile:mb-6 xs:mb-8 mobile:flex-col tablet:flex-row md:flex-col tablet:gap-6 md:gap-1"
            textFontSize="text-base"
            headerFontSize="text-xl"
          />
        </div>
      </div>
    </ResponsiveWrapper>
  );
};

export default Article;
