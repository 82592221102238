import { env } from 'env';

const oktaUrl = env.REACT_APP_OKTA_URL || 'https://verify.marketviewedu.dev';

export default {
  url: oktaUrl,
  issuer: `${oktaUrl}/oauth2/default`,
  redirectUri: `${window.location.origin}/implicit/callback`,
  clientId: env.REACT_APP_OKTA_CLIENT_ID || '0oaoudqosn0Jxya4Z0h7',
  pkce: true,
};
