import { getOktaUserId } from 'helpers/okta-session';

// Prepends the currentUserID to the passed path
export const pathWithCurrentUserId = (path) => {
  let currentUserId = '';
  const oktaUserId = getOktaUserId();

  if (oktaUserId) currentUserId = `/${oktaUserId}`;

  return `${currentUserId}${path}`;
};
