import { useState, useEffect } from 'react';

import api from 'api';

import { COURSE_STATUSES } from 'containers/user/learn/containers/content/cources/course.constants';

const useFetchCourses = (userId) => {
  const [loading, setLoading] = useState(false);
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const fetchCourses = async () => {
      setLoading(true);
      let coursesMetadata = [];

      try {
        const coursesRes = await api.getCourses(userId);

        try {
          const coursesMetadataRes = await api.getCoursesFullData({ userId });

          coursesMetadata = coursesMetadataRes.data;
        } catch (metadataError) {
          if (metadataError.response?.status === 404) {
            console.error('No courses metadata found');
            coursesMetadata = [];
          }
        }

        const updatedCourses = coursesRes?.data?.data?.map((course) => {
          if (!course.attributes.lessons?.data?.length) {
            return null;
          }

          course.attributes.lessons.data =
            course.attributes.lessons.data.filter(
              (lesson) => lesson.attributes.publishedAt
            ); // filter unpublished lessons

          const metadata = coursesMetadata.find(
            (meta) => meta.id === course.id
          );
          let averageProgress = 0;

          if (metadata && metadata.lessons.length > 0) {
            const courseLessonIds = new Set(
              course.attributes.lessons?.data?.map((lesson) => lesson.id)
            );
            const filteredLessons = metadata.lessons.filter((lesson) =>
              courseLessonIds.has(lesson.id)
            );

            if (filteredLessons.length > 0) {
              const totalProgress = filteredLessons.reduce(
                (acc, lesson) => acc + (lesson.progress || 0),
                0
              );

              averageProgress = Math.round(
                totalProgress / course.attributes.lessons?.data.length
              );
            }
          }
          const status =
            COURSE_STATUSES[metadata?.status] || COURSE_STATUSES.UNKNOWN;

          return {
            ...course,
            averageProgress,
            status: averageProgress === 100 ? COURSE_STATUSES.DONE : status,
            name: course.attributes?.name,
            route: `/learn/course/${course.id}`,
            coverDescription: course.attributes?.coverDescription,
            coverImage: course.attributes?.coverImage?.data?.attributes?.url,
            firstLessonId: course.attributes.lessons?.data[0]?.id,
          };
        });

        setCourses(updatedCourses.filter(Boolean));
      } catch (error) {
        console.error('Failed to fetch courses:', error);
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      fetchCourses();
    }
  }, [userId]);

  return { loading, courses };
};

export default useFetchCourses;
