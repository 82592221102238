import * as pbi from 'powerbi-client';

var models = pbi.models;
let powerbi = new pbi.service.Service(
  pbi.factories.hpmFactory,
  pbi.factories.wpmpFactory,
  pbi.factories.routerFactory
);

export { models as pbiModels };
export default powerbi;
