import React, { useContext, useState, useEffect } from 'react';
import classNames from 'classnames';

import Heading from 'components/partials/heading/heading';
import CustomButton from 'components/partials/custom-button/custom-button';
import NotificationMessage from 'components/partials/notification/notification';

import PersonalInformationForm from 'containers/admin/users/user/forms/personal-info-form/personal-info-form';
import UserDetailsForm from 'containers/admin/users/user/forms/user-details-form/user-details-form';
import { UserProfileContext } from 'containers/admin/users/user/user-profile/user-profile';

const UserForm = ({
  isMVUser,
  userRole,
  currentClient,
  isFormDisabled,
  isSelectDisabled,
  isNotNewUser,
  isCurrentUserProfile,
  onCancel,
  onSubmit,
  handleSubmit,
}) => {
  const { errors } = useContext(UserProfileContext);
  const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState(false);
  const [currentUserRole, setUserRole] = useState(userRole);

  const onEditModeStyles = classNames('flex', {
    'flex-col': isFormDisabled || isNotNewUser,
  });

  useEffect(() => {
    setIsSaveBtnDisabled(Object.keys(errors).length !== 0);
  }, [Object.keys(errors).length]);

  return (
    <div className="w-full">
      <div className="flex w-full">
        {!isCurrentUserProfile && (
          <Heading variant="h3" customClass="w-3/6 mr-16 mt-4">
            Personal information
          </Heading>
        )}
        {!isFormDisabled && !isNotNewUser && (
          <Heading variant="h3" customClass="w-3/6 mt-4">
            User details
          </Heading>
        )}
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-full">
        <div className={onEditModeStyles}>
          <PersonalInformationForm
            {...{
              isNotNewUser,
              isFormDisabled,
              isMVUser,
            }}
          />
          <UserDetailsForm
            {...{
              areGlobalRolesDisabled: isSelectDisabled,
              currentUserRole,
              currentClient,
              isNotNewUser,
              isFormDisabled,
              isSelectDisabled,
              isMVUser,
            }}
          />
          {!isFormDisabled && isMVUser && (
            <div className="flex">
              <NotificationMessage
                heading="Note"
                customClass="mb-5"
                variant="info"
                message="Locked items cannot be edited. Contact your institution administrator or MARKETview Consultant to make changes."
              />
            </div>
          )}
        </div>
        {!isFormDisabled && (
          <div className="flex justify-end mt-4">
            <CustomButton
              variant="text"
              size="md"
              onClick={(e) => {
                e.preventDefault();
                setUserRole(userRole);
                onCancel();
              }}
            >
              Cancel
            </CustomButton>
            <CustomButton
              disabled={isSaveBtnDisabled}
              variant="primary"
              size="md"
            >
              Save changes
            </CustomButton>
          </div>
        )}
      </form>
    </div>
  );
};

export default UserForm;
