import React from 'react';

import Tab from 'components/partials/tabs/tab/tab';
import classNames from 'classnames';

const Tabs = ({ items, activeTab, onSelect, customClass }) => (
  <div
    className={classNames(
      'text-base text-center text-blue-03 border-b w-full',
      customClass
    )}
  >
    <ul className="flex flex-wrap -mb-px gap-5">
      {items.map((item) => (
        <Tab
          key={item.value}
          label={item.label}
          isActive={activeTab === item.value}
          isDisabled={item.isDisabled}
          onClick={() => onSelect(item.value)}
        />
      ))}
    </ul>
  </div>
);

export default React.memo(Tabs);
