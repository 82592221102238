import React from 'react';
import classNames from 'classnames';

const Tab = ({ label, isActive, isDisabled, onClick }) => {
  const baseClasses = 'inline-block py-2 font-semibold';
  const activeClasses = 'text-indigo-02 border-b-2 border-yellow-03';
  const inactiveClasses = 'text-blue-03 hover:text-gray-600';
  const disabledClasses = 'text-gray-02 cursor-not-allowed';

  const className = classNames(baseClasses, {
    [disabledClasses]: isDisabled,
    [activeClasses]: isActive,
    [inactiveClasses]: !isActive,
  });

  return (
    <li className="me-2">
      <button onClick={onClick} className={className} disabled={isDisabled}>
        {label}
      </button>
    </li>
  );
};

export default Tab;
