import { createSlice } from '@reduxjs/toolkit';

import { actions } from 'state/current-user/current-user.slice';

const oktaSessionSlice = createSlice({
  name: 'session',
  initialState: { data: null, loading: false },
  reducers: {
    loadingSession: (state) => {
      state.loading = true;
    },
    setCurrentUserSession: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    },
    clearCurrentUserSession: (state) => {
      state.data = null;
      state.loading = false;
    },
  },
  extraReducers: {
    [actions.setCurrentUser]: (state) => {
      // clear session info when user changes
      state.data = null;
      state.loading = false;
    },
  },
});

export const {
  setCurrentUserSession,
  clearCurrentUserSession,
  loadingSession,
} = oktaSessionSlice.actions;

export default oktaSessionSlice.reducer;
