import React from 'react';
import CustomButton from 'components/partials/custom-button/custom-button';
import Icons from 'assets/icons';

const LessonNavigation = ({
  lessons,
  currentLessonId,
  courseId,
  handleLessonClick,
}) => {
  const currentIndex = lessons.findIndex(
    (id) => id == `lesson-${currentLessonId}`
  );

  const isFirstLesson = currentIndex === 0;
  const isLastLesson = currentIndex === lessons.length - 1;

  const navigateToLesson = (newIndex) => {
    const newLessonId = lessons[newIndex];

    const lessonNumber = parseInt(newLessonId.match(/\d+/)[0], 10);
    const to = `/learn/courses/${courseId}/lesson/${lessonNumber}`;

    handleLessonClick({
      to: to,
      id: lessonNumber,
    });
  };

  return (
    <div className="flex justify-between items-center">
      {isFirstLesson ? (
        <div className="flex-1"></div>
      ) : (
        <CustomButton
          variant="secondary"
          onClick={() => navigateToLesson(currentIndex - 1)}
          iconBefore={<Icons.DoubleArrow className="mr-1 w-2 h-2 rotate-180" />}
        >
          Previous Lesson
        </CustomButton>
      )}
      {!isLastLesson && (
        <CustomButton
          variant="secondary"
          onClick={() => navigateToLesson(currentIndex + 1)}
          iconAfter={<Icons.DoubleArrow className="ml-2 w-2 h-2" />}
        >
          Next Lesson
        </CustomButton>
      )}
    </div>
  );
};

export default LessonNavigation;
