import { createSlice } from '@reduxjs/toolkit';

export const IMPERSONATE_KEY = 'fd_okta-impersonating-id';

const impersonationSlice = createSlice({
  name: 'impersonation',
  initialState: {
    impersonator: null,
    impersonatingUser: null,
  },
  reducers: {
    impersonate: (state, { payload: { impersonator, impersonatingUser } }) => {
      state.impersonator = impersonator;
      state.impersonatingUser = impersonatingUser;
    },
    stopImpersonating: (state) => {
      state.impersonator = null;
      state.impersonatingUser = null;
    },
  },
});

export const { impersonate, stopImpersonating } = impersonationSlice.actions;

export default impersonationSlice.reducer;
