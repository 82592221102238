import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import MarkdownComponent from 'containers/user/learn/components/markdown/markdown';

import Icons from 'assets/icons';

const AccordionItem = ({ name, description, index }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    const contentElement = contentRef.current;
    let resizeObserver;

    const updateHeight = () => {
      if (contentElement) {
        contentElement.style.height = isOpen
          ? `${contentElement.scrollHeight}px`
          : '0px';
      }
    };

    if (contentElement) {
      resizeObserver = new ResizeObserver(updateHeight);
      resizeObserver.observe(contentElement);
    }

    const handleResize = () => {
      if (isOpen) {
        contentElement.style.height = 'auto';
        const newScrollHeight = contentElement.scrollHeight;

        contentElement.style.height = `${newScrollHeight}px`;
      }
    };

    window.addEventListener('resize', handleResize);

    updateHeight();

    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
      window.removeEventListener('resize', handleResize);
    };
  }, [isOpen]);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div>
      <button
        type="button"
        className={classNames(
          'flex items-center justify-between w-full py-3 px-4 rounded-md font-medium border border-gray-05 gap-3',
          { 'border-b-0 bg-gray-00 rounded-b-none': isOpen }
        )}
        onClick={toggleOpen}
        aria-expanded={isOpen}
        aria-controls={`accordion-content-${index}`}
      >
        <span className="flex items-center gap-2">
          <span
            className={classNames(
              'flex items-center justify-center w-5 h-5 bg-blue-04 rounded-full text-white text-sm shrink-0',
              { 'bg-indigo-02': isOpen }
            )}
          >
            {index + 1}
          </span>
          <span
            className={classNames(
              'text-gray-03 font-semibold text-left break-all',
              {
                'text-indigo-02': isOpen,
              }
            )}
            title={name}
          >
            {name}
          </span>
        </span>
        <Icons.Arrow
          className={classNames('rotate-90 transition shrink-0', {
            '!-rotate-90': isOpen,
          })}
        />
      </button>
      <div
        ref={contentRef}
        className={classNames('overflow-hidden transition-all', {
          'rounded-b-md': isOpen,
        })}
        role="region"
        aria-labelledby={`accordion-title-${index}`}
      >
        <div
          className={classNames({
            'p-5 border border-gray-05 border-t-0 rounded-b-md': isOpen,
            hidden: !isOpen,
          })}
          title={description}
        >
          <MarkdownComponent text={description} />
        </div>
      </div>
    </div>
  );
};

export default AccordionItem;
