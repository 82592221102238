import uniqid from 'uniqid';

export const PERSONAL_INFO_FORM_FIELDS = [
  {
    name: 'firstName',
    label: 'First name',
    key: uniqid(),
    mode: 'CREATE_AND_EDIT_MODE',
  },
  {
    name: 'lastName',
    label: 'Last name',
    key: uniqid(),
    mode: 'CREATE_AND_EDIT_MODE',
  },
  {
    name: 'jobTitle',
    label: 'Job title',
    key: uniqid(),
    mode: 'CREATE_AND_EDIT_MODE',
  },
];

export const PERSONAL_INFO_FORM_ALWAYS_EDITABLE_FIELDS = new Set([
  'firstName',
  'lastName',
]);
