import { useDispatch, useSelector } from 'react-redux';

import {
  impersonate,
  stopImpersonating,
} from 'state/impersonation/impersonation.slice';

import { history } from 'state/history';

import { IMPERSONATE_KEY } from 'state/impersonation/impersonation.slice';

export const useImpersonation = () => {
  const dispatch = useDispatch();

  const impersonation = useSelector((state) => state.impersonation);
  const currentUser = useSelector((state) => state.currentUser);

  return {
    isImpersonating: () => {
      const oktaImpersonateUserInfo =
        window.localStorage.getItem(IMPERSONATE_KEY);

      if (!oktaImpersonateUserInfo) return false;

      return JSON.parse(oktaImpersonateUserInfo);
    },

    impersonate: (user, redirectTo) => {
      if (!user.oktaUserId) return;

      // Put this user's Okta ID in session
      window.localStorage.setItem(
        IMPERSONATE_KEY,
        JSON.stringify({
          oktaUserId: user.oktaUserId,
          id: user.id,
          redirectTo: redirectTo,
        })
      );

      // redirect to home after impersonating
      if (window.location.pathname !== '/') history.push('/home');

      dispatch(
        impersonate({
          impersonator: currentUser.data,
          impersonatingUser: user,
        })
      );
    },

    stopImpersonating: (redirectTo) => {
      const impersonatingInfo = JSON.parse(
        window.localStorage.getItem(IMPERSONATE_KEY)
      );

      dispatch(stopImpersonating());

      // redirect to passed path OR root
      window.location = redirectTo || impersonatingInfo.redirectTo || '/';

      window.localStorage.removeItem(IMPERSONATE_KEY);
    },
    ...impersonation,
  };
};
