import api from 'api';
import { COURSE_STATUSES } from 'containers/user/learn/containers/content/cources/course.constants';

export const startCourse = async ({ userId, courseId }) => {
  await api
    .setCourseStatus({
      userId,
      courseId,
      status: COURSE_STATUSES.IN_PROGRESS,
    })
    .catch(console.error);
};

export const restartCourse = async ({ userId, courseId }) => {
  await api
    .clearCourseMetadata({
      userId,
      courseId,
    })
    .then(() => startCourse({ userId, courseId }))
    .catch(console.error);
};

export const getComponentHeights = () => {
  const globalHeaderHeight =
    document.querySelector('.global-header')?.clientHeight;
  const globalFooterHeight =
    document.querySelector('.global-footer')?.clientHeight;
  const screenHeaderHeight = document.querySelector(
    '.course-details-header'
  )?.clientHeight;

  return {
    globalFooterHeight,
    globalHeaderHeight,
    screenHeaderHeight,
  };
};

export const countAverageProgress = (lessonsProgress) => {
  const totalProgress = Object.values(lessonsProgress).reduce(
    (total, progress) => total + progress,
    0
  );

  return Math.round(totalProgress / Object.keys(lessonsProgress).length);
};
