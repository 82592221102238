import React from 'react';
import { ToastContainer as ToastifyContainer } from 'react-toastify';
import classNames from 'classnames';

import Icons from 'assets/icons';

const toastStyles = {
  container: 'sm:w-2/5 min-w-[356px] max-w-[500px]',
  component: 'rounded-lg p-4 border-t border-r border-navy-01',
  progressBar: 'rounded-bl-none',
  body: 'items-start pl-3 pr-0',
};

const toastTypeStyles = {
  error: {
    component: `text-red-02 border-l-8 border-l-red-02 ${toastStyles.component}`,
    closeButton: 'text-red-02',
    progressBar: `bg-red-02/50 ${toastStyles.progressBar}`,
  },
  info: {
    component: `text-indigo-02 border-l-8 border-l-indigo-02 ${toastStyles.component}`,
    closeButton: 'text-indigo-02',
    progressBar: `bg-indigo-02/50 ${toastStyles.progressBar}`,
  },
  success: {
    component: `text-green-02 border-l-8 border-l-green-02 ${toastStyles.component}`,
    closeButton: 'text-green-02',
    progressBar: `bg-green-02/50 ${toastStyles.progressBar}`,
  },
  warning: {
    component: `text-amber-00 border-l-8 border-l-amber-00 ${toastStyles.component}`,
    closeButton: 'text-amber-00',
    progressBar: `bg-amber-00/50 ${toastStyles.progressBar}`,
  },
  neutral: {
    component: `text-gray-03 border-l-8 border-l-gray-03 ${toastStyles.component}`,
    closeButton: 'text-gray-03',
    progressBar: `bg-gray-03/50 ${toastStyles.progressBar}`,
  },
};

const getToastTypeStyles = (defaultClassName = '', type = 'neutral', key) =>
  classNames(defaultClassName, toastTypeStyles[type][key]);

const CustomCloseButton = ({ closeToast, type }) => {
  const closeButtonClass = getToastTypeStyles('', type, 'closeButton');

  return (
    <button onClick={closeToast}>
      <Icons.Close className={closeButtonClass} />
    </button>
  );
};

const ToastContainer = () => (
  <ToastifyContainer
    style={{
      '--toastify-color-progress-bgo': '0',
    }}
    autoClose={2500}
    draggable
    position="top-right"
    hideProgressBar={false}
    pauseOnHover
    closeOnClick={false}
    className={toastStyles.container}
    toastClassName={(toast) =>
      getToastTypeStyles(toast?.defaultClassName, toast?.type, 'component')
    }
    bodyClassName={toastStyles.body}
    closeButton={(props) => <CustomCloseButton {...props} />}
    progressClassName={(toast) =>
      getToastTypeStyles(toast?.defaultClassName, toast?.type, 'progressBar')
    }
  />
);

export default ToastContainer;
