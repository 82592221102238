import React from 'react';
import uniqid from 'uniqid';

import ConsultantList from 'containers/user/profile/components/consultant-list';

import Tile from 'components/shared/others/tile/tile';

import { formatDate } from 'helpers/dates-formatter';

import { FAKE_DATA_TODO_WHITEPAPERS } from 'containers/user/profile/components/security-hub/security-hub.constants';

import Icons from 'assets/icons';

const SecurityHub = () => (
  <>
    <div className="flex items-center justify-between">
      <div className="font-black text-4xl text-indigo-02 mb-4 font-family">
        Security Hub
      </div>
    </div>
    <div className="flex flex-col justify-between w-full gap-8">
      <div className="flex justify-between w-full gap-8">
        <Tile customClass="w-2/3">
          <div className="flex justify-between">
            <div className="w-2/3 mb-10 mr-20">
              <div className="font-black text-[28px] text-indigo-02 font-family mb-4 leading-9">
                Data Security is Our Top Priority
              </div>

              <p className="font-text">
                MARKETview’s highest priority is ensuring the confidentiality,
                privacy and integrity of student record-level data provided by
                clients. We are{' '}
                <a
                  href="https://www.aicpa.org/interestareas/frc/assuranceadvisoryservices/aicpasoc2report.html"
                  target="_blank"
                  className="text-blue-02 underline decoration-1"
                >
                  SOC 2 TYPE 2 certified
                </a>{' '}
                and our report is available upon request.
              </p>
            </div>
            <Icons.Security
              customClass="mt-8 mr-3"
              alt="Security image"
              width={'35%'}
              height={'100%'}
            />
          </div>
        </Tile>
        <Tile customClass="w-1/3">
          <div className="mb-1 font-black text-2xl text-indigo-02 font-family">
            Security concerns?
            <br />
            Let us know.
          </div>
          <ConsultantList customClass="mt-6" />
        </Tile>
      </div>
      <Tile>
        <div className="mb-3 font-black text-2xl text-indigo-02 font-family">
          Documentation
        </div>
        <div className="flex justify-around gap-12">
          {FAKE_DATA_TODO_WHITEPAPERS.map((whitepaper, ix) => (
            <Whitepaper key={uniqid()} {...whitepaper} />
          ))}
        </div>
      </Tile>
    </div>
  </>
);

const Whitepaper = ({ title, description, pdfs, lastUpdated, className }) => (
  <div className={`w-1/3 flex flex-col ${className}`}>
    <div>
      <div className="font-black text-lg text-indigo-02 font-family">
        {title}
      </div>
      <p>{description}</p>
      <p className="mt-2 text-xs uppercase text-gray-02 tracking-widest font-text">
        Updated {formatDate(lastUpdated, 'MMMM D, YYYY')}
      </p>
    </div>
    {pdfs.map((pdf) => (
      <a
        key={uniqid()}
        href={pdf.pdfLink}
        target="_blank"
        className="mt-4 flex items-center text-sm font-medium text-blue-03"
      >
        <Icons.Download customClass="mr-3 flex-shrink-0" />
        {pdf.pdfTitle}
      </a>
    ))}
  </div>
);

export default SecurityHub;
