import React from 'react';
import uniqid from 'uniqid';

import Breadcrumb from './breadcrumb/breadcrumb';

const Breadcrumbs = ({ breadcrumbs, customClass = '' }) => (
  <div className={`${customClass} flex mt-4`}>
    {breadcrumbs.length &&
      breadcrumbs.map((breadcrumb, i) => (
        <Breadcrumb
          key={uniqid()}
          {...{
            isLast: i === breadcrumbs.length - 1,
            isFirst: i === 0,
            ...breadcrumb,
          }}
        />
      ))}
  </div>
);

export default Breadcrumbs;
