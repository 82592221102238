import React from 'react';
import uniqid from 'uniqid';

import Icons from 'assets/icons';

export const getSortIcon = (sortingParams, columnId, enableSorting) => {
  const defaultSortIcon = (
    <Icons.DefaultSort
      key={uniqid()}
      customClass="mt-[4px]"
      alt="header-icon"
      height="100%"
      width="13"
    />
  );

  if (enableSorting) {
    if (!!sortingParams.length) {
      const sortedItem = sortingParams.find((sort) => sort.id === columnId);

      if (!sortedItem) return defaultSortIcon;

      return sortedItem?.desc ? (
        <Icons.Sort
          key={uniqid()}
          alt="sort-up-icon"
          height="100%"
          width="14"
        />
      ) : (
        <Icons.Sort
          key={uniqid()}
          alt="sort-down-icon"
          height="100%"
          width="14"
          customClass="rotate-180 mt-0.5"
        />
      );
    }

    return defaultSortIcon;
  }
};
