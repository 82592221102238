import React from 'react';
import classNames from 'classnames';

const HeaderItem = (
  {
    isMobileView = false,
    onClick,
    iconBefore,
    iconAfter,
    isBurgerItem = false,
    rotateClass = '',
    children,
  },
  ref
) => (
  <div
    className={classNames(
      'grid xs:self-center xs:text-sm relative cursor-pointer hover:text-indigo-02 mobile:grid-cols-[0.08fr,1fr,0.15fr] tablet:grid-cols-[0.19fr,1fr,0.3fr] !xs:grid-cols-[0.3fr,2fr,0.3fr] xs:justify-center',
      {
        'mobile:text-indigo-02 !mobile:justify-self-end': isMobileView,
        '!grid-cols-5x95': !!isBurgerItem,
      }
    )}
    onClick={onClick}
  >
    {!!iconBefore && (
      <span className={`font-h-3 ${!isMobileView && 'mobile:pl-4 xs:pl-0'}`}>
        {iconBefore}
      </span>
    )}
    <span
      className={classNames(
        'mobile:justify-self-start mobile:self-start sx:justify-self-end mobile:pl-2 xs:pl-2 w-max font-semibold md:font-regular',
        {
          'mobile:pl-2': isMobileView,
        }
      )}
    >
      {children}
    </span>
    {!!iconAfter && (
      <span className={`mt-0.5 ml-1 font-large ${rotateClass}`}>
        {iconAfter}
      </span>
    )}
  </div>
);

export default HeaderItem;
