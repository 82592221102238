import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import classNames from 'classnames';

import CustomButton from 'components/partials/custom-button/custom-button';

import Icons from 'assets/icons';

export const GetStartedGuideBanner = ({
  disabled = false,
  isPopoverBanner = true,
  isImgHidden = false,
  customClass = '',
  textFontSize = 'text-sm',
  headerFontSize = 'font-regular',
}) => (
  <div
    className={classNames(`flex p-4 bg-gray-00 rounded-lg ${customClass}`, {
      'flex-col max-w-[290px] justify-self-center xs:mt-[-120px] xs:row-start-3 xs:col-start-3 md:mt-auto md:row-span-2 md:row-start-1 md:col-start-4 md:self-unset':
        isPopoverBanner,
      'opacity-[35%]': disabled,
    })}
  >
    <img
      src={require('assets/images/get-started.png')}
      alt="Banner img"
      className={classNames('flex h-full w-full mb-4', {
        '!hidden': isImgHidden,
        'mobile:hidden md:flex': isPopoverBanner,
      })}
    />
    <div>
      <div className={`text-indigo-02 ${headerFontSize}`}>
        Check out our
        <div className="font-semibold">Get Started Guide</div>
      </div>
      <div className={`text-gray-03 leading-{22px} py-2 ${textFontSize}`}>
        This comprehensive guide provides step-by-step instructions, practical
        tips, and valuable insights to help you kickstart your data analysis
        journey with confidence.
      </div>
      <RouterLink
        to="/learn/get-started-guide"
        className={classNames({
          'pointer-events-none': disabled,
        })}
      >
        <CustomButton
          variant="primary"
          size="sm"
          iconAfter={<Icons.Arrow />}
          customClass="mt-2"
        >
          Learn more
        </CustomButton>
      </RouterLink>
    </div>
  </div>
);

export default GetStartedGuideBanner;
