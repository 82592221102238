import React, { useState } from 'react';

import Loader from 'components/partials/loader/loader';
import HeroContentType from 'containers/user/learn/components/hero-content-type/hero-content-type';
import NothingFound from 'containers/user/learn/components/empty-row/empty-row';
import CoursesCards from 'containers/user/learn/components/courses-cards/courses-cards';
import SeeMoreButton from 'containers/user/learn/components/see-more-btn/see-more-btn';
import useFetchAndUpdateCourses from 'containers/user/learn/containers/content/cources/use-fetch-courses';

import { useCurrentUser } from 'state/current-user/current-user.thunk';

const Courses = () => {
  const [displayAll, setDisplayAll] = useState(false);

  const user = useCurrentUser();

  const { loading, courses } = useFetchAndUpdateCourses(user?.data?.oktaUserId);

  const displayedCourses = displayAll ? courses : courses.slice(0, 4);

  return (
    <div className="w-full">
      <Loader loading={loading} />
      <HeroContentType
        title="Courses"
        description="Explore the basics of MARKETview by watching our sequential user-training modules."
        breadcrumbs={[
          { label: 'Learn', href: '/learn' },
          { label: 'Courses', isActive: true },
        ]}
        customClass="pt-5"
      />
      <div className="flex flex-col items-center mt-10">
        {!displayedCourses.length && !loading ? (
          <NothingFound contentType="courses" />
        ) : (
          <CoursesCards
            items={displayedCourses}
            userId={user?.data?.oktaUserId}
          />
        )}

        {courses.length > 4 && (
          <SeeMoreButton
            onClick={() => setDisplayAll(!displayAll)}
            showAll={displayAll}
          />
        )}
      </div>
    </div>
  );
};

export default Courses;
