import React from 'react';

const Toggle = ({
  onToggleChange,
  isToggleOn,
  toggleText = 'Assign to all new users',
}) => (
  <div className="flex items-center">
    <span className="mr-2">{toggleText}</span>
    <label className="relative inline-flex items-center cursor-pointer">
      <input
        type="checkbox"
        checked={isToggleOn}
        onChange={(e) => {
          onToggleChange(e.target.checked);
        }}
        className="sr-only peer"
      />
      <div
        className="peer
        w-11 h-6 
        rounded-full 
        bg-gray-07 
        dark:bg-gray-07
        peer-checked:after:translate-x-full 
        peer-checked:bg-yellow-03
        after:absolute 
        after:top-[1.9px] 
        after:left-[2px] 
        after:bg-white 
        after:rounded-full 
        after:h-5 after:w-5 
        after:transition-all
        peer-focus-visible:outline"
      ></div>
    </label>
  </div>
);

export default Toggle;
