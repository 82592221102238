import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Breadcrumbs from 'components/partials/beadcrumbs/breadcrumbs';
import ResponsiveWrapper from 'components/shared/others/responsive-wrapper/responsive-wrapper';

const Content = () => (
  <ResponsiveWrapper md="1120" lg="1120" customClass="flex flex-col">
    <Breadcrumbs
      breadcrumbs={[
        { label: 'Learn', href: '/learn' },
        { label: 'Content', isActive: true },
      ]}
    />
    <RouterLink to="/learn/content/articles">
      Check out Articles page
    </RouterLink>
    <RouterLink to="/learn/content/guides">Check out Guides page</RouterLink>
    <RouterLink to="/learn/content/downloads">
      Check out Downloads page
    </RouterLink>
    <RouterLink to="/learn/content/webinars">
      Check out Webinars page
    </RouterLink>
  </ResponsiveWrapper>
);

export default Content;
