import client_data_pdf from 'assets/data/client_data_security_10_23.pdf';
import ferpa_pdf from 'assets/data/ferpa_compliance_10_23.pdf';
import gdpr_pdf from 'assets/data/gdpr_and_ccpa_compliance_10_23.pdf';
import hea_pdf from 'assets/data/hea_compliance_10_23.pdf';

export const FAKE_DATA_TODO_WHITEPAPERS = [
  {
    title: 'We Fully Support and Comply with FERPA and HEA',
    description:
      'This paper outlines the fundamental principles for the protection of information assets and the proper controls in place to ensure compliance with internal policies and external regulations.',
    pdfs: [
      {
        pdfLink: ferpa_pdf,
        pdfTitle: 'Download FERPA Compliance Detail',
      },
      {
        pdfLink: hea_pdf,
        pdfTitle: 'Download HEA Compliance Detail',
      },
    ],
    lastUpdated: 'October 16, 2023',
  },
  {
    title:
      'We Are Using Leading Practices to Stay Current with GDPR and CCPA Compliance',
    description:
      'This paper outlines how these new external regulations relate to MARKETview and our internal practices for remaining in compliance.',
    pdfs: [
      {
        pdfLink: gdpr_pdf,
        pdfTitle: 'Download GDPR and CCPA Compliance Detail',
      },
    ],
    lastUpdated: 'October 16, 2023',
  },
  {
    title: 'Our Security and Privacy Tenets are Built on Leading Practices',
    description:
      'This paper provides an overview of the security and privacy philosophy, approach, and posture for MARKETview.',
    pdfs: [
      {
        pdfLink: client_data_pdf,
        pdfTitle: 'Download Client Data Security',
      },
    ],
    lastUpdated: 'October 16, 2023',
  },
];
