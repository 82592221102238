import Notification from 'components/partials/notification/notification.helper';
import { transformUserFormData } from 'helpers/forms';

export const onUserFormSubmit = ({
  formData,
  history,
  redirectPath,
  users,
  user,
  currentUser,
  isCurrentUserProfile,
  reset,
}) => {
  const dataToSend = transformUserFormData(formData, currentUser);

  if (isCurrentUserProfile) dataToSend.authority = user.authority;

  return users
    .createOrUpdate(dataToSend)
    .then(() => {
      history.push(redirectPath, {
        notificationParams: {
          heading: `User ${formData?.id ? 'updated' : 'created'}`,
          variant: 'success',
          message: formData?.id
            ? `You've successfully updated ${formData?.firstName} ${formData?.lastName}`
            : `You've successfully added ${formData?.firstName} ${formData?.lastName} as a user`,
        },
      });
      reset();
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

export const getBreadcrumbsForUserPage = (isUserPage, currentClient, user) => {
  const baseBreadcrumbs = isUserPage
    ? [{ label: 'Users', href: '/admin/users' }]
    : [
        { label: 'Clients', href: '/admin/clients' },
        {
          label: currentClient?.name,
          href: `/admin/clients/${currentClient?.id}/users`,
        },
        {
          label: 'Users',
          href: `/admin/clients/${currentClient?.id}/users`,
        },
      ];

  baseBreadcrumbs.push({
    label: user?.name || 'New user',
    isActive: true,
  });

  return baseBreadcrumbs;
};

export const getRedirectPathForUser = ({
  setIsFormActive,
  user,
  currentClient,
}) => {
  const isUserPage = location.pathname.includes('/admin/users');

  const newUserRedirectPath = isUserPage
    ? '/admin/users'
    : `/admin/clients/${currentClient?.id}/users`;

  const editUserRedirectPath = isUserPage
    ? `/admin/users/${user?.id}/profile`
    : `/admin/clients/${user?.client?.id}/users/${user?.id}/profile`;

  return !!setIsFormActive && !!user
    ? editUserRedirectPath
    : newUserRedirectPath;
};
