import { history } from 'state/history';

import { clearOktaSessionInfo, getOktaBearerToken } from 'helpers/okta-session';

import { UNAUTHORIZED, NOT_FOUND } from 'constants/error-types';

import Notification from 'components/partials/notification/notification.helper';

const sessionExpiredHandler = (
  title = 'Session Expired',
  message = 'Your session has expired, please log in again.',
  type = 'info',
  id = 'sessionExpired'
) => {
  clearOktaSessionInfo();
  Notification.flash(title, type, message);

  history.push('/login');
};

export const errorInterceptors = (error = {}) => {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  const { status, statusText, data } = error.response;

  if (!getOktaBearerToken()) {
    sessionExpiredHandler();

    return Promise.reject({
      status: status,
      statusText: statusText,
      data: data,
    });
  }

  if (error.response.status === UNAUTHORIZED) {
    sessionExpiredHandler();
  } else if (
    error.response.status === NOT_FOUND &&
    error.response.data.detail === 'Subscription not active'
  ) {
    sessionExpiredHandler(
      'Error logging in',
      `Your school's subscription is locked.`,
      'error'
    );
  } else {
    console.error('API response error', error.response);
  }

  return Promise.reject({
    status: status,
    statusText: statusText,
    data: data,
  });
};
