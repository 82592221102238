import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import UserProfile from 'containers/admin/users/user/user-profile/user-profile';
import { getUserActionMenuItems } from 'containers/admin/users/user/user-action-menu-items';

import { useClients } from 'state/clients/clients.thunk';
import { useCurrentUser } from 'state/current-user/current-user.thunk';
import { useUsers } from 'state/users/users.thunk';
import { useImpersonation } from 'state/impersonation/impersonation.thunk';

import Notification from 'components/partials/notification/notification.helper';
import { transformUserToFormObject } from 'helpers/forms';

const UserPage = ({
  clientId,
  isCurrentUserProfile = false,
  match,
  notificationParams,
}) => {
  const [loading, setLoading] = useState(false);
  const [currentClient, setCurrentClient] = useState(null);
  const [user, setUser] = useState(null);
  const [isFormDisabled, setIsFormActive] = useState(false);

  const currentUser = useCurrentUser();
  const users = useUsers();
  const clients = useClients();
  const impersonation = useImpersonation();

  const userId = match.params.id;

  const loadUser = () => {
    setLoading(true);

    users.find(userId).then(({ data }) => {
      if (!!data?.client) {
        clients.find(data.client.id).then((client) => {
          setCurrentClient(client.data);
        });
      }
      setUser(data);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (userId && userId !== 'new') {
      loadUser();
    }
  }, [userId]);

  useEffect(() => {
    isCurrentUserProfile && setUser(currentUser.data);
  }, [isCurrentUserProfile]);

  useEffect(() => {
    setLoading(true);
    if (clientId) {
      clients
        .find(clientId)
        .then((client) => {
          setCurrentClient(client.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error during fetching client.NewUserPage', error);
          setLoading(false);
        });
    }
  }, [clientId]);

  useEffect(() => {
    !!notificationParams &&
      Notification.flash(
        notificationParams.heading,
        notificationParams.variant,
        notificationParams.message
      );
  }, []);

  return (
    (currentClient || user) && (
      <UserProfile
        {...{
          pageName: user?.name,
          currentClient,
          isImpersonatingUser: !!impersonation.impersonatingUser,
          user,
          isCurrentUserProfile,
          isFormDisabled,
          setIsFormActive,
          loading,
          setLoading,
          actionMenuItems:
            userId !== 'new' &&
            !isCurrentUserProfile &&
            getUserActionMenuItems({
              user,
              currentClient,
              reloadUser: loadUser,
              currentUser,
              impersonation,
              users,
            }),
          defaultValues: transformUserToFormObject(user, currentClient),
        }}
      />
    )
  );
};

export default withRouter(UserPage);
