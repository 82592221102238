import React from 'react';
import { Route } from 'react-router';

const CustomTabs = ({ options }) =>
  options.map(({ link, component, key }) => (
    <Route key={key} path={link}>
      {component}
    </Route>
  ));

export default CustomTabs;
