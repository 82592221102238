import { useSelector } from 'react-redux';

import { useGenericCollection } from 'helpers/state/use-generic-collection';

import Notification from 'components/partials/notification/notification.helper';

export const useUsers = () => {
  const collectionMethods = useGenericCollection('user');
  const clientCollectionMethods = useGenericCollection('client');

  const users = useSelector((state) => state.users);

  return {
    ...collectionMethods,
    ...users,

    findAndAddClientData: (userId) =>
      collectionMethods.find(userId).then((user) => {
        if (user.data.client) {
          return clientCollectionMethods
            .find(user.data.client.id)
            .then((client) => ({
              ...user,
              data: {
                ...user.data,
                client: {
                  ...client.data,
                },
              },
            }))
            .catch(() => ({
              ...user,
            }));
        } else {
          return {
            ...user,
          };
        }
      }),

    lockAccount: (userId) => {
      const user = users.data.find((c) => c.id === userId);
      const isLocked = user.status === 'LOCKED';

      return collectionMethods
        .update(userId, {
          ...user, // have to pass in the whole object every time
          status: isLocked ? 'ACTIVE' : 'LOCKED',
        })
        .then((record) => {
          const changeMsg =
            record.data.status === 'LOCKED' ? 'locked' : 'unlocked';

          const changeColor =
            record.data.status === 'LOCKED' ? 'info' : 'success';

          Notification.flash(
            `User ${changeMsg}`,
            changeColor,
            `You've successfully ${changeMsg} this user`
          );

          return record;
        });
    },
  };
};
