import { useEffect, useState } from 'react';
import classNames from 'classnames';
import api from 'api';

import Loader from 'components/partials/loader/loader';
import { renderGoal } from 'containers/admin/clients/client/tabs-components/goals-tab/goals-matrix/goals-matrix-cell/goals-matrix-cell';
import { formatDate } from 'helpers/dates-formatter';

const ActionTypes = {
  INSERT: 'Goal Created',
  UPDATE: 'Goal Updated',
};

/**
 * Component to display the goal history with animated appearance.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.data - The goal data.
 * @param {string} props.data.id - The goal ID.
 * @param {string} props.data.category - The goal category.
 * @param {string} props.userId - The user ID.
 * @param {string} props.clientId - The client ID.
 */
const History = ({ data: { id, category }, userId, clientId }) => {
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visibleItems, setVisibleItems] = useState(0);

  useEffect(() => {
    const timers = [];

    const fetchGoalHistory = async () => {
      try {
        const response = await api.getGoalHistory({
          userId,
          clientId,
          id,
        });

        const historyData = response.data;

        setHistory(historyData);

        // Display history items with animation
        historyData.forEach((_, index) => {
          const timer = setTimeout(() => {
            setVisibleItems((prev) => prev + 1);
          }, index * 300);

          timers.push(timer);
        });
      } catch (error) {
        console.error('Error while fetching goal history', error);
      } finally {
        setLoading(false);
      }
    };

    fetchGoalHistory();

    return () => {
      timers.forEach(clearTimeout); // Cleanup timers
    };
  }, [userId, clientId, id]);

  /**
   * Renders metadata for each history revision.
   *
   * @param {string} createdBy - The user who created the revision.
   * @param {string} createdDate - The date the revision was created.
   * @returns {JSX.Element} - JSX for revision metadata.
   */
  const renderRevisionMetadata = (createdBy, createdDate) => (
    <div className="text-blue-03 text-xs">
      <p>{createdDate}</p>
      <p>{createdBy}</p>
    </div>
  );

  /**
   * Renders the goal revision content.
   *
   * @param {Object} revision - The revision data.
   * @returns {JSX.Element} - JSX for revision content.
   */
  const renderContent = (revision) => {
    const renderOtherGoalContent = (label, value) => (
      <div className="flex gap-1 items-baseline">
        <span className="text-gray-09 shrink-0">{label}</span>
        <span className="text-gray-04 font-normal"> {value}</span>
      </div>
    );

    if (category === 'Other') {
      return (
        <div className="font-semibold font-sm">
          {renderOtherGoalContent('Title:', revision.name)}
          {renderOtherGoalContent('Description:', revision.description)}
        </div>
      );
    }

    return renderGoal(revision);
  };

  /**
   * Renders the type of action (Insert or Update).
   *
   * @param {string} type - The action type ('INSERT', 'UPDATE').
   * @returns {JSX.Element} - JSX for the action type.
   */
  const renderAction = (type) => (
    <p className="font-bold text-indigo-02">{ActionTypes[type]}</p>
  );

  /**
   * Renders the goal version (Initial, New or Prior).
   *
   * @param {string} type - The version type ('Initial', 'New' or 'Prior').
   * @param {Object} item - The goal revision data.
   * @returns {JSX.Element} - JSX for the goal version.
   */
  const renderGoalVersion = (type, item) => (
    <div
      className={classNames('flex gap-1 items-baseline text-gray-04', {
        'flex-col gap-0': category === 'Other',
      })}
    >
      <p className="text-gray-03 shrink-0 font-sm">{`${type} Version:`}</p>
      {renderContent(item)}
    </div>
  );

  /**
   * Renders a single history item.
   *
   * @param {Object} item - The history item data.
   * @param {number} index - The index of the history item.
   * @returns {JSX.Element} - JSX for the history item.
   */
  const renderHistoryItem = (item, index) => {
    const { createdBy, createdDate, type } = item.metadata;
    const formattedDate = formatDate(createdDate, 'MMM D, YY h:mm A');

    return (
      <>
        {renderAction(type)}
        {renderRevisionMetadata(createdBy, formattedDate)}
        <div className="mt-2 flex font-semibold gap-1 flex-col">
          {type === 'UPDATE' &&
            renderGoalVersion('Prior', history[index + 1]?.revision)}
          {renderGoalVersion(
            type === 'INSERT' ? 'Initial' : 'New',
            item.revision
          )}
        </div>
      </>
    );
  };

  if (loading) {
    return (
      <Loader
        loading={true}
        overlay="white"
        customClass="opacity-75"
        fullScreen={false}
      />
    );
  }

  return (
    <div className="relative flex flex-col items-start h-full">
      {history.map((item, index) => (
        <div
          key={item.metadata.createdDate}
          className={classNames(
            'relative flex items-baseline space-x-4 transition-opacity duration-500 ease-out transform-gpu',
            {
              'opacity-100 translate-y-0': visibleItems > index,
              'opacity-0 translate-y-4': visibleItems <= index,
            }
          )}
          style={{ transitionDelay: `${index * 300}ms` }}
        >
          {history.length > 1 && (
            <div className="flex flex-col items-center h-full">
              <div className="w-3 h-3 bg-indigo-02 border-[3px] border-gray-05 rounded-full shrink-0"></div>
              {index < history.length - 1 && (
                <div
                  className={classNames(
                    'w-0.5 bg-gray-05 transition-all duration-500 ease-out',
                    {
                      'h-full': visibleItems > index,
                      'h-0': visibleItems <= index,
                    }
                  )}
                  style={{
                    transitionDelay: `${index * 300 + 200}ms`,
                  }}
                ></div>
              )}
            </div>
          )}
          <div className="w-full mb-2.5">{renderHistoryItem(item, index)}</div>
        </div>
      ))}
    </div>
  );
};

export default History;
