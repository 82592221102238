import React from 'react';
import classNames from 'classnames';

import CircularProgress from 'components/partials/circular-progress/circular-progress';

const LessonsSidebarItem = React.memo(
  ({ index, id, name, onClick, courseId, currentLessonId, progress }) => {
    const isActive = currentLessonId == id; //Might be number and string

    const lessonLink = `/learn/courses/${courseId}/lesson/${id}`;

    return (
      <li
        className={classNames('p-2 transition rounded-md', {
          'bg-gray-00': isActive,
        })}
      >
        <a
          href={lessonLink}
          onClick={(e) => onClick({ event: e, to: lessonLink, id })}
          className={classNames(
            'block text-sm bg-transparent hover:text-indigo-02 font-semibold text-gray-04 flex items-center justify-between no-underline',
            {
              'text-indigo-02 hover:text-indigo-02': isActive,
            }
          )}
        >
          <div className="max-w-[200px] break-words">
            <span>{`${index + 1}. `}</span>
            {name}
          </div>

          <CircularProgress progress={progress} />
        </a>
      </li>
    );
  }
);

export default LessonsSidebarItem;
