import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

export const USER_PROFILE_FORM_VALIDATION_SCHEMA = yupResolver(
  yup.object({
    firstName: yup
      .string()
      .trim()
      .required('This field is required. Please enter First Name'),
    lastName: yup
      .string()
      .trim()
      .required('This field is required. Please enter Last Name'),
    jobTitle: yup
      .string()
      .trim()
      .required('This field is required. Please enter Job Title'),
    email: yup
      .string()
      .email('Please enter a valid email address with the "@" symbol')
      .required(
        'Please make sure your email address belongs to client authorized domains'
      ),
    confirmEmail: yup
      .string()
      .oneOf([yup.ref('email'), null], 'Please ensure the emails match')
      .when('email', ([email]) =>
        email === ''
          ? yup
              .string()
              .email('Please enter a valid email address with the "@" symbol')
              .required('Please ensure the emails match')
          : undefined
      ),
    role: yup.string().required('Please select Role from the list'),
    client: yup
      .object()
      .nullable()
      .when('role', ([role], schema) => {
        const roleObject =
          role === 'user'
            ? schema
                .shape({
                  value: yup.number(),
                })
                .required('Please select Client from the list')
            : undefined;

        return roleObject;
      }),
  })
);

export const USERS_PROFILE_STATUSES = {
  ACTIVE: 'Active',
  LOCKED: 'Locked',
};
