export const buildQueryParams = ({
  pageSize,
  pageIndex = 0,
  sortBy,
  searchTerms,
  forceSearch,
  filters,
}) => {
  const query = new URLSearchParams();

  if (pageSize) query.append('size', pageSize);
  query.append('page', pageIndex);

  if (!!sortBy.length) {
    sortBy.map((sortParams) => {
      const sortDir = sortParams.desc ? 'desc' : 'asc';

      query.append('sort', `${sortParams.id},${sortDir}`);
    });
  }

  // TODO: Currently can't combine search and filters; figure this out...
  if (searchTerms || forceSearch) query.q = searchTerms || '';

  // Filters
  if (filters) {
    Object.keys(filters).forEach((field) => {
      const filterValue = filters[field];

      if (Array.isArray(filterValue) && filterValue.length > 0) {
        query.append(`${field}.in`, filterValue.join(','));
      } else if (typeof filterValue === 'object' && field !== 'search') {
        const key = field;

        Object.keys(filterValue).forEach((innerField) => {
          query.append(`${key}.${innerField}`, filterValue[innerField]);
        });
      } else {
        if (!!filterValue && field !== 'search') {
          query.append(`${field}.equals`, filterValue);
        }

        if (field === 'search') {
          query.append('q', filterValue.value);
        }
      }
    });
  }

  return query;
};
