import React, { useContext, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import uniqid from 'uniqid';

import { getLearnPopoverMenuItems } from './learn-popover.constants';
import { FeatureFlagsContext } from 'app';

import Icons from 'assets/icons';

const LearnPopoverPartial = () => {
  const [menuItems, setMenuItems] = useState([]);
  const { featureFlags } = useContext(FeatureFlagsContext);

  useEffect(() => {
    const { articles, downloads, guides, webinars, courses } = featureFlags;

    setMenuItems(
      getLearnPopoverMenuItems({
        articles,
        downloads,
        guides,
        webinars,
        courses,
      })
    );
  }, [featureFlags]);

  const renderMenuItem = ({ name, description, icon, route }) => (
    <RouterLink
      to={route}
      className="flex text-blue-02 items-start gap-1 transition hover:text-indigo-02"
    >
      <div className="text-inherit hover:text-inherit">{icon}</div>
      <div>
        <div className="xs:text-sm md:text-base font-semibold text-inherit hover:text-inherit">
          {name}
        </div>
        <div className="xs:text-xs md:text-sm text-gray-04">{description}</div>
      </div>
    </RouterLink>
  );

  return (
    <>
      <div className="absolute mobile:hidden xs:flex flex-col gap-4 font-regular text-gray-03 top-14 right-0 -left-[14px] mx-auto z-[700] xs:p-5 rounded-bl-lg rounded-br-lg bg-white xs:w-[285px] h-fit overflow-auto">
        <div className="text-blue-02 hover:text-indigo-02">
          <RouterLink to="/learn" className="flex items-start gap-1">
            <Icons.DataVisualization customClass="w-6 h-6 mr-0.5 shrink-0" />
            <div>
              <div className="flex items-center">
                <div className="xs:text-sm md:text-base mr-2 font-semibold">
                  Learning Hub
                </div>
              </div>
              <div className="text-gray-04 leading-{22px} xs:text-xs md:text-sm">
                Explore All Resources
              </div>
            </div>
          </RouterLink>
        </div>

        {menuItems
          .filter((el) => !el.disabled)
          .map(({ options }) => (
            <React.Fragment key={uniqid()}>
              {options
                .filter((item) => !item.disabled)
                .map(({ name, description, icon, route }, index) => (
                  <React.Fragment key={uniqid()}>
                    {index === 0 && <hr />}
                    {renderMenuItem({ name, description, icon, route })}
                  </React.Fragment>
                ))}
            </React.Fragment>
          ))}
      </div>
    </>
  );
};

export default LearnPopoverPartial;
