export const parseFormattedNumber = (value) => {
  if (value === '') return '';

  const cleanValue = value.toString().replace(/,/g, '');
  const regex = /^(\d+(\.\d+)?)([KMB])?$/i;
  const match = cleanValue.match(regex);

  if (!match) return NaN;

  const number = parseFloat(match[1]);
  const suffix = match[3]?.toUpperCase();

  switch (suffix) {
    case 'B':
      return number * 1_000_000_000;
    case 'M':
      return number * 1_000_000;
    case 'K':
      return number * 1_000;
    default:
      return number;
  }
};

export const formatWithThousandSeparator = (value) =>
  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const formatWithSuffix = (value, divisor, suffix) => {
  const divided = value / divisor;
  const decimalPart = divided.toString().split('.')[1];

  return decimalPart && decimalPart[0] !== '0'
    ? `${divided.toFixed(1)}${suffix}`
    : `${divided.toFixed(0)}${suffix}`;
};

export const formatNumberWithSuffix = (value) => {
  if (value >= 1_000_000_000) {
    return formatWithSuffix(value, 1_000_000_000, 'B');
  } else if (value >= 1_000_000) {
    return formatWithSuffix(value, 1_000_000, 'M');
  } else if (value >= 1_000) {
    return formatWithSuffix(value, 1_000, 'K');
  }

  return formatWithThousandSeparator(value);
};
