import React, { useEffect, useState } from 'react';
import Icons from 'assets/icons';
import Loader from 'components/partials/loader/loader';

const extractIframeSrc = (htmlString) => {
  const regex = /<iframe[^>]+src="([^"]+)"[^>]*><\/iframe>/;

  const match = regex.exec(htmlString);

  return match ? match[1] : null;
};

const VimeoComponent = ({ vimeoLink = '' }) => {
  const [finalLink, setFinalLink] = useState('');
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('Vimeo video');

  useEffect(() => {
    if (!vimeoLink) {
      return;
    }

    fetchVimeoOEmbedData(vimeoLink)
      .then((oEmbedData) => {
        if (oEmbedData?.html) {
          const iframeSrc = extractIframeSrc(oEmbedData.html);

          iframeSrc && setFinalLink(iframeSrc);
        }
        if (oEmbedData.title) {
          setTitle(oEmbedData.title);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [vimeoLink]);

  const fetchVimeoOEmbedData = async (videoUrl) => {
    const oEmbedEndpoint = 'https://vimeo.com/api/oembed.json';
    const requestUrl = `${oEmbedEndpoint}?url=${encodeURIComponent(videoUrl)}`;

    setLoading(true);
    try {
      const response = await fetch(requestUrl);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();

      return data;
    } catch (error) {
      console.error('Error fetching Vimeo oEmbed data:', error);
    } finally {
      setLoading(false);
    }
  };
  const containerStyles =
    'w-full h-auto mobile:h-[194px] mobile:w-[345px] tablet:h-[394px] tablet:w-[700px] xs:h-[440px] xs:w-[780px]';

  return (
    <div className="w-full mobile:mb-6 xs:mb-8">
      {finalLink ? (
        <iframe
          className={containerStyles}
          src={finalLink}
          allow="autoplay; fullscreen"
          allowFullScreen
          title={title}
        ></iframe>
      ) : (
        !finalLink && (
          <div className={`${containerStyles} bg-gray-00 relative`}>
            <div className="absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 w-full">
              <Loader loading={loading} fullScreen={false} overlay="gray-00" />

              <div className="flex flex-col items-center gap-5 w-full">
                <Icons.BrokenLink className="mobile:w-16 mobile:h-16 xs:w-20 xs:h-20" />
                <span className="text-gray-04 mobile:text-sm md:text-lg font-semibold">
                  Looks like the vimeo video link is broken.
                </span>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default VimeoComponent;
