import React, { useState, useEffect } from 'react';
import uniqid from 'uniqid';
import classNames from 'classnames';

import Icons from 'assets/icons';

const PageNav = ({
  content = [],
  isTabletView = false,
  navTitle = 'In this article',
}) => {
  const [activeItem, setActiveItem] = useState({});
  const [filteredContent, setFilteredContent] = useState([]);
  const [isNavBarOpen, setIsNavBarOpen] = useState(false);

  const handleItemClick = ({ navigationTitle, id }) => {
    setActiveItem({ navigationTitle, id });

    const element = document.getElementById(`block_${id}`);
    const elPosition = element.getBoundingClientRect();

    window.scrollTo(window.scrollX, elPosition.top - 112 + window.scrollY);

    setIsNavBarOpen(false);
  };

  useEffect(() => {
    const filteredParagraphs = content?.filter(
      (el) =>
        el.type === 'paragraph' &&
        el.navigationTitle &&
        !Object.is(el.navigationTitle, null)
    );

    setFilteredContent(filteredParagraphs);
    setActiveItem(filteredParagraphs[0]);
  }, []);

  return (
    !!filteredContent.length &&
    (!isTabletView ? (
      <div className="mobile:mb-6 xs:mb-8">
        <div className="py-2 text-blue-03 font-semibold">{navTitle}</div>
        {filteredContent.map(({ navigationTitle, id }) => {
          const activeStyles =
            activeItem.id === id && 'bg-gray-00 font-semibold';

          return (
            <div
              key={uniqid()}
              className={`py-2 px-3 my-0.5 cursor-pointer rounded-sm ${activeStyles}`}
              onClick={() => handleItemClick({ navigationTitle, id })}
            >
              {navigationTitle}
            </div>
          );
        })}
      </div>
    ) : (
      <div className="relative mobile:mb-6 xs:mb-8">
        <div
          className={`flex items-center justify-between py-2 px-3 text-blue-03 ${
            !isNavBarOpen
              ? 'rounded-md'
              : 'rounded-tr-md rounded-tl-md border-b-0'
          } border border-gray-05 font-semibold`}
          onClick={() => {
            setIsNavBarOpen(!isNavBarOpen);
          }}
        >
          <div>{navTitle}</div>
          <Icons.Arrow
            customClass={classNames('rotate-90', {
              '!rotate-[270deg]': isNavBarOpen,
            })}
          />
        </div>
        {isNavBarOpen && (
          <div className="absolute w-full bg-white border border-gray-05 cursor-pointer rounded-br-md rounded-bl-md">
            {filteredContent.map(({ navigationTitle, id }, ind) => {
              const activeStyles =
                activeItem.id === id && 'bg-gray-00 font-semibold';

              const lastItemStyles =
                filteredContent.length === ind + 1 &&
                'rounded-br-md rounded-bl-md';

              return (
                <div
                  key={uniqid()}
                  className={`py-2 px-3 cursor-pointer ${activeStyles} ${lastItemStyles}`}
                  onClick={() => handleItemClick({ navigationTitle, id })}
                >
                  {navigationTitle}
                </div>
              );
            })}
          </div>
        )}
      </div>
    ))
  );
};

export default PageNav;
