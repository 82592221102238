import Icons from 'assets/icons';

export const CONTENT_TYPES = [
  {
    icon: <Icons.Articles customClass="w-4" />,
    type: 'Article',
  },
  {
    icon: <Icons.Guides customClass="w-4" />,
    type: 'Guide',
  },
  {
    icon: <Icons.Downloads customClass="w-4" />,
    type: 'Download',
  },
  {
    icon: <Icons.Webinars customClass="w-4" />,
    type: 'Webinar',
  },
  {
    icon: <Icons.Glossary customClass="w-4" />,
    type: 'Glossary',
  },
];
