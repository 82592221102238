import React from 'react';
import uniqid from 'uniqid';

import SwitcherItem from './switcher-item/switcher-item';

const Switcher = ({ tabs }) => (
  <div className="inline-flex rounded-lg bg-green-05 p-1">
    {tabs.map((tab) => (
      <SwitcherItem key={uniqid()} {...tab} />
    ))}
  </div>
);

export default Switcher;
