import {
  loadingReports,
  setReports,
  loadingReportsFailed,
} from 'state/reports/reports.slice';

import api from 'api';

export const loadReports = () => (dispatch) => {
  dispatch(loadingReports());

  return api
    .getReports()
    .then((report) => {
      dispatch(setReports(report.data));

      return report;
    })
    .catch((e) => {
      dispatch(loadingReportsFailed(e));
    });
};
