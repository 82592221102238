import React, { forwardRef, useState, useEffect } from 'react';
import classNames from 'classnames';

import Icons from 'assets/icons';

const CustomTextInput = forwardRef(
  (
    {
      customClass = '',
      label = '',
      labelFor = '',
      errorsNames = {},
      helperText = '',
      domainText = '',
      type = 'text',
      placeholder = 'Type...',
      disabled,
      disabledClassName = 'bg-gray-06',
      inputCustomClass = '',
      inputWrapperCustomClass = '',
      labelInTop = false,
      required,
      maxLength,
      onChange,
      'data-testid': dataTestId = 'input-field',
      ...rest
    },
    ref
  ) => {
    const [charCount, setCharCount] = useState(0);
    const isValid = Object.keys(errorsNames).length === 0;

    useEffect(() => {
      if (!maxLength) return;

      if (!rest.value) {
        setCharCount(0);
      } else {
        setCharCount(rest.value.length);
      }
    }, [rest.value, maxLength]);

    const inputStyles = classNames(
      'border border-solid rounded-md p-2.5 focus:outline-none hover:border-blue-03 focus:border-blue-06 transition',
      {
        'border-red-01 focus:border-red-01 hover:border-red-01': !isValid,
        'border-gray-05': isValid,
      },
      inputCustomClass
    );

    const labelStyles = classNames('font-family font-semibold', customClass, {
      'w-32 mr-14 mb-5': !labelInTop,
      'flex mb-[0.25rem] mt-3': labelInTop,
    });

    const wrapperClass = classNames(inputWrapperCustomClass, {
      [disabledClassName]: disabled,
    });

    const handleInputChange = (e) => {
      if (!rest.value && maxLength && !disabled) {
        setCharCount(e.target.value.length); // manually count characters if value is not passed
      }

      if (onChange) {
        onChange(e);
      }
    };

    return (
      <div className="flex flex-col w-full">
        <div
          className={classNames('flex', {
            'flex-col items-start': labelInTop,
            'items-center': !labelInTop,
          })}
        >
          {label && (
            <label htmlFor={labelFor} className={labelStyles}>
              {label}
              {required ? (
                <Icons.RequiredIndicator
                  customClass="w-[7px] h-[7px] ml-1 mt-1"
                  data-testid={`${dataTestId}-required-indicator`}
                />
              ) : null}
            </label>
          )}
          <div className={`flex flex-col w-full ${wrapperClass}`}>
            <input
              id={labelFor}
              className={inputStyles}
              ref={ref}
              type={type}
              placeholder={placeholder}
              disabled={disabled}
              maxLength={maxLength}
              data-testid={dataTestId}
              onChange={handleInputChange}
              {...rest}
            />
            {helperText && !disabled && isValid && (
              <span
                className="font-sm pt-1.5 bg-white text-gray-03"
                data-testid={`${dataTestId}-helper-text`}
              >
                {helperText} {domainText}
              </span>
            )}
            {!isValid && !disabled && (
              <span
                className="font-sm pt-1 text-red-00 bg-white"
                data-testid={`${dataTestId}-error-message`}
              >
                {errorsNames.message}
              </span>
            )}
            {!disabled && maxLength && (
              <span
                className="mt-1 text-sm"
                data-testid={`${dataTestId}-character-count`}
              >
                {charCount} of {maxLength} characters used
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default CustomTextInput;
