import { useSelector } from 'react-redux';

import { useGenericCollection } from 'helpers/state/use-generic-collection';

import Notification from 'components/partials/notification/notification.helper';

export const useClients = () => {
  const collectionMethods = useGenericCollection('client');

  const clients = useSelector((state) => state?.clients);

  return {
    ...collectionMethods,
    ...clients,

    lockAccount: (clientId) => {
      const client = clients.data.find(
        (c) => c.id.toString() === clientId.toString()
      );
      const isLocked = client.subscriptionStatus === 'LOCKED';

      return collectionMethods
        .update(clientId, {
          ...client, // have to pass in the whole object every time
          subscriptionStatus: isLocked ? 'ACTIVE' : 'LOCKED',
        })
        .then((record) => {
          const changeMsg =
            record.data.subscriptionStatus === 'LOCKED' ? 'locked' : 'unlocked';

          const changeColor =
            record.data.subscriptionStatus === 'LOCKED' ? 'info' : 'success';

          Notification.flash(
            `Client ${changeMsg}`,
            changeColor,
            `You've successfully ${changeMsg} ${record.data.name}`
          );

          return record;
        });
    },
  };
};
