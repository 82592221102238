import { useMemo } from 'react';
import { useCurrentUser } from 'state/current-user/current-user.thunk';
import { ROLES } from 'constants/roles';

export const useReadOnly = () => {
  const {
    data: { authority },
  } = useCurrentUser();

  return useMemo(() => authority !== ROLES.SUPER_ADMIN, [authority]);
};
