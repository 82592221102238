import React from 'react';
import Breadcrumbs from 'components/partials/beadcrumbs/breadcrumbs';
import ResponsiveWrapper from 'components/shared/others/responsive-wrapper/responsive-wrapper';

const HeroContentType = ({
  title = '',
  description = '',
  breadcrumbs = [],
  customClass = '',
}) => (
  <div
    className={`bg-gray-00 flex flex-col w-full items-center pb-16 ${customClass}`}
  >
    <ResponsiveWrapper md="1120" lg="1120" customClass="flex flex-col">
      <div className="tablet:w-[600px]">
        <Breadcrumbs breadcrumbs={breadcrumbs} customClass="!mt-0 mb-9" />
        <div>
          <div className="text-5xl font-semibold text-indigo-02 mb-4">
            {title}
          </div>
          <div className="mobile:text-lg tablet:text-xl font-normal text-indigo-02 mb-4">
            {description}
          </div>
        </div>
      </div>
    </ResponsiveWrapper>
  </div>
);

export default HeroContentType;
