import React from 'react';
import uniqid from 'uniqid';

import Divider from 'components/shared/others/divider/divider';

import { useCurrentUser } from 'state/current-user/current-user.thunk';

import { formatValue } from 'helpers/format-value';

const ConsultantList = ({ customClass }) => {
  const user = useCurrentUser();

  const principal =
    user &&
    user.data &&
    user.data.client &&
    user.data.client.consultants &&
    user.data.client.consultants.find(
      (consultant) => consultant.consultantType === 'PRINCIPAL'
    );

  const contact =
    user &&
    user.data &&
    user.data.client &&
    user.data.client.consultants &&
    user.data.client.consultants.find(
      (consultant) => consultant.consultantType === 'CONTACT'
    );

  return (
    <div className={customClass}>
      {contact || principal ? (
        [principal, contact]
          .filter((c) => c)
          .map((consultant, i) => (
            <div key={uniqid()}>
              <div
                key={consultant.id}
                className="flex items-baselibe flex-wrap gap-3"
              >
                <div>
                  <div className="text-lg font-family font-black text-indigo-02 mb-2">
                    {consultant.user.firstName} {consultant.user.lastName}
                  </div>

                  <div className="mb-2">
                    <p className="text-sm font-medium">
                      {consultant.user.officePhone &&
                        formatValue(consultant.user.officePhone, 'phone')}
                    </p>
                    <p className="text-sm font-medium">
                      {consultant.user.email}
                    </p>
                  </div>
                  <button
                    className="transition-all text-gray-04 border border-gray-08 outline-none px-4 py-2 bg-gradient-to-t from-light-00 to-white rounded-lg leading-tight shadow-default hover:from-white hover:to-white active:bg-gradient-to-t active:from-white active:to-light-00 text-base font-normal"
                    onClick={() => {
                      location.href = `mailto:${consultant.user.email}`;
                    }}
                  >
                    Send an email
                  </button>
                </div>
              </div>

              {i === 0 && <Divider customClass="!my-6" />}
            </div>
          ))
      ) : (
        <>No consultants assigned yet.</>
      )}
    </div>
  );
};

export default ConsultantList;
