import React from 'react';
import classNames from 'classnames';

import CustomButton from 'components/partials/custom-button/custom-button';

const ActionMenuItem = ({ label, icon, isDelete, onClick }) => (
  <CustomButton
    customClass={classNames(
      'action-menu-item text-gray-04 font-normal hover:text-gray-04 hover:bg-gray-00 transition duration-500 pr-0 pl-2 !justify-start w-full',
      {
        '!text-red-04 hover:text-red-04': isDelete,
      }
    )}
    variant="text"
    iconBefore={icon}
    onClick={onClick}
  >
    {label}
  </CustomButton>
);

export default ActionMenuItem;
