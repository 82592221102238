'use strict';
import api from 'api';

import { history } from 'state/history';

import { getRedirectPathForUser } from 'containers/admin/users/user/user-profile/user-profile.helpers';

import Notification from 'components/partials/notification/notification.helper';

import { LOCK_TEXT } from 'constants/texts';

import { renderConfirmationModal } from 'helpers/confirmation-render';

import Icons from 'assets/icons';

export const getUserActionMenuItems = ({
  user,
  currentClient,
  reloadUser,
  currentUser,
  impersonation,
  users,
}) => {
  const userProfileActionMenuData = [
    currentUser?.data?.sendPasswordReset && {
      label: 'Send reset password link',
      icon: <Icons.Link customClass="mr-2" />,
      onClick: () => {
        api
          .requestPasswordReset(user.email)
          .then(() => {
            Notification.flash(
              'Sent password reset email',
              'success',
              'Check your email for a password reset link'
            );
          })
          .catch((error) => {
            Notification.flash(
              'Error sending password reset email',
              'success',
              (error && error.errorSummary) ||
                (error.data && error.data.detail) ||
                ''
            );
          });
      },
    },
    !!currentClient &&
      currentClient.status !== 'Expired' &&
      user?.status !== 'LOCKED' &&
      user?.viewPortalAsUser && {
        label: 'View portal as user',
        icon: <Icons.Eye customClass="mr-2" />,
        onClick: () =>
          impersonation.impersonate(user, window.location.pathname),
      },
    (currentUser?.data?.lockAndUnlockAllUsers ||
      (currentUser?.data?.lockAndUnlockClientUsers && !user?.fdUser)) && {
      label: `${user?.status === 'LOCKED' ? 'Unlock' : 'Lock'} account`,
      icon: <Icons.Lock customClass="mr-2" />,
      onClick: () => {
        const isStatusLocked = user?.status === 'LOCKED';

        const lockedText = isStatusLocked ? 'unlock' : 'lock';

        const headerText = `Are you sure you want to ${lockedText} this user account?`;

        const text = isStatusLocked
          ? LOCK_TEXT.CONFIRM_UNLOCK_USER
          : LOCK_TEXT.CONFIRM_LOCK_USER;

        const notificationMode = isStatusLocked ? 'success' : 'warning';

        renderConfirmationModal({
          headerText,
          text,
          onConfirmCallback: () =>
            users.lockAccount(user?.id).then(() => {
              reloadUser();
              Notification.flash(
                `User ${lockedText}ed`,
                notificationMode,
                `User ${user?.firstName} ${user?.lastName} was successfully ${lockedText}ed`
              );
            }),
          onConfirmButtonLabel: `Yes, ${lockedText} account`,
        });
      },
    },
    currentUser?.data?.addAndDeleteUsers && {
      label: 'Delete user',
      icon: <Icons.Delete customClass="text-red-04 mr-2" />,
      isDelete: true,
      onClick: () =>
        renderConfirmationModal({
          headerText: `Are you sure you want to delete this user account?`,
          onConfirmCallback: () => {
            const redirectPath = getRedirectPathForUser({
              user,
              currentClient,
            });

            users.deleteRecord(user.id).then(() => {
              history.push(redirectPath, {
                notificationParams: {
                  heading: 'User deleted',
                  variant: 'info',
                  message: `You've successfully deleted ${user.name} user`,
                },
              });
            });
          },
          onConfirmButtonLabel: 'Yes, delete account',
        }),
    },
  ].filter((item) => item);

  return userProfileActionMenuData;
};
