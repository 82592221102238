import React, { useState, useEffect, useMemo, useRef } from 'react';
import uniqid from 'uniqid';

import Loader from 'components/partials/loader/loader';
import { getBlocksFromContent } from 'containers/user/learn/heplers/get-blocks-from-content';
import ResponsiveWrapper from 'components/shared/others/responsive-wrapper/responsive-wrapper';
import LessonIndicator from 'containers/user/learn/containers/content/lesson/lesson-indicator/lesson-indicator';
import LessonNavigation from 'containers/user/learn/containers/content/lesson/lesson-navigation/lesson-navigation';

import api from 'api';
import { useCurrentUser } from 'state/current-user/current-user.thunk';

const Lesson = ({
  lessonId,
  onProgressUpdate,
  handleLessonClick,
  courseId,
  lessonsIds,
  progress = 0,
  isImpersonatingUser,
}) => {
  const [lesson, setLesson] = useState({});
  const [loading, setLoading] = useState(false);
  const lessonRef = useRef(null);

  const prevScrollTopRef = useRef(0);
  const savedProgressRef = useRef(0);

  const user = useCurrentUser();

  const memoizedContent = useMemo(
    () =>
      lesson.content?.map((component) =>
        getBlocksFromContent({ ...component, key: uniqid() })
      ),
    [lesson.content]
  );

  const calculateScrollDistance = () => {
    const scrollTop = window.scrollY || document.documentElement.scrollTop || 0;

    const footerHeight =
      document.querySelector('.global-footer')?.offsetHeight || 0;
    // Subtract footer height from the document height
    const docHeight = document.documentElement.scrollHeight - footerHeight;

    if (
      lessonRef.current &&
      lessonRef.current.clientHeight > 100 &&
      docHeight <= window.innerHeight
    ) {
      savedProgressRef.current = 100;
      onProgressUpdate({ lessonId, progress: 100 });
    } else if (scrollTop > prevScrollTopRef.current) {
      const scrollPosition =
        docHeight > 0
          ? Math.min(
              Math.floor((scrollTop / (docHeight - window.innerHeight)) * 100),
              100
            )
          : 100;

      savedProgressRef.current = Math.max(
        Math.ceil(scrollPosition),
        savedProgressRef.current
      );

      onProgressUpdate({ lessonId, progress: savedProgressRef.current });
    }

    prevScrollTopRef.current = scrollTop;
  };

  useEffect(() => {
    if (isImpersonatingUser) {
      return;
    }

    window.addEventListener('scroll', calculateScrollDistance);

    return () => {
      window.removeEventListener('scroll', calculateScrollDistance);
    };
  }, [lessonId, isImpersonatingUser]);

  useEffect(() => {
    setLoading(true);
    api
      .getLesson(lessonId, user?.data?.oktaUserId)
      .then((res) => {
        setLesson(res.data.data.attributes);
      })
      .catch(console.error)
      .finally(() => {
        setLoading(false);
      });
  }, [lessonId]);

  useEffect(() => {
    savedProgressRef.current = progress;
  }, [lessonId]);

  useEffect(() => {
    if (isImpersonatingUser) {
      return;
    }

    if (!loading) {
      calculateScrollDistance();
    }
  }, [lesson.content, loading, isImpersonatingUser]);

  return (
    <ResponsiveWrapper md="780" lg="780" ref={lessonRef}>
      {loading ? (
        <Loader {...{ loading }} fullScreen={false} overlay="transparent" />
      ) : (
        <>
          <LessonIndicator
            currentLessonNumber={lessonsIds.findIndex(
              (id) => id == `lesson-${lessonId}`
            )}
            totalLessons={lessonsIds.length}
          />
          <div className="text-dark-01 font-semibold text-2xl mb-6">
            {lesson.name}
          </div>
          {memoizedContent}
          <LessonNavigation
            {...{
              currentLessonId: lessonId,
              handleLessonClick,
              courseId,
              lessons: lessonsIds,
            }}
          />
        </>
      )}
    </ResponsiveWrapper>
  );
};

export default Lesson;
