import React, { useState, useEffect } from 'react';
import { saveAs } from 'file-saver';
import uniqid from 'uniqid';
import classNames from 'classnames';

import Loader from 'components/partials/loader/loader';
import Breadcrumbs from 'components/partials/beadcrumbs/breadcrumbs';
import CustomButton from 'components/partials/custom-button/custom-button';
import RelatedContent from 'containers/user/learn/components/related-content/related-content';

import api from 'api';

import { useCurrentUser } from 'state/current-user/current-user.thunk';

import {
  getIsRelatedContentShown,
  getRelatedContent,
} from 'containers/user/learn/heplers/content-builder-helpers';
import MarkdownComponent from 'containers/user/learn/components/markdown/markdown';
import { getBlocksFromContent } from 'containers/user/learn/heplers/get-blocks-from-content';

import ResponsiveWrapper from 'components/shared/others/responsive-wrapper/responsive-wrapper';
import Heading from 'components/partials/heading/heading';

import { formatDate } from 'helpers/dates-formatter';
import Icons from 'assets/icons';

const Download = ({ downloadId }) => {
  const [download, setDownload] = useState({});
  const [loading, setLoading] = useState(false);

  const user = useCurrentUser();

  const dowloadFile = () => {
    setLoading(true);
    const fileName = download?.downloadFile?.data[0]?.attributes?.name;
    const fileUrl = download?.downloadFile?.data[0]?.attributes?.url;

    saveAs(fileUrl, fileName);

    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    api
      .getDownload(downloadId, user?.data?.oktaUserId)
      .then((res) => {
        setDownload(res.data.data.attributes);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, []);

  const isDatePresented = !!download?.assetCreationDate;

  return !!loading ? (
    <Loader {...{ loading }} />
  ) : (
    <div className="flex flex-col justify-center w-full">
      <div className="flex justify-center w-full h-auto">
        <ResponsiveWrapper
          md="1120"
          lg="1120"
          customClass="flex flex-col justify-center !w-auto"
        >
          <Breadcrumbs
            breadcrumbs={[
              { label: 'Learn', href: '/learn' },
              { label: 'Downloads', href: '/learn/content/downloads' },
            ]}
            customClass="!mt-5"
          />
          <div className="flex items-start mobile:flex-col tablet:flex-row gap-6 mobile:py-4 tablet:py-10">
            <div className="flex flex-col">
              <Heading
                customClass={classNames('text-4xl font-semibold mb-2', {
                  'mobile:mb-6 xs:mb-8': !isDatePresented,
                })}
              >
                {download?.name}
              </Heading>

              {isDatePresented && (
                <div className="flex items-center text-blue-03 mobile:mb-6 xs:mb-8">
                  <Icons.Calendar className="h-4 w-4 mr-1" />
                  <div className="text-sm">
                    {formatDate(download.assetCreationDate, 'MMM D, YYYY')}
                  </div>
                </div>
              )}

              <MarkdownComponent text={download?.heroDescription} />

              <span className="font-large font-semibold mobile:mt-6 md:mt-10">
                {download?.downloadText}
              </span>
              <CustomButton
                variant="primary"
                size="sm"
                iconAfter={<Icons.Download customClass="ml-2" />}
                customClass="mt-3 w-fit"
                onClick={() => dowloadFile()}
              >
                {download?.downloadBtnText}
              </CustomButton>
            </div>
            {download?.heroImage && (
              <img
                src={download?.heroImage?.data?.attributes?.url}
                alt="Hero Img"
                className="flex h-auto tablet:w-1/2 xs:w-1/3 justify-center"
              />
            )}
          </div>
        </ResponsiveWrapper>
      </div>
      {!!download.content?.length && (
        <div className="flex justify-center w-full">
          <ResponsiveWrapper
            md="1120"
            lg="1120"
            customClass="flex flex-col justify-center my-3"
          >
            {download.content.map((component) =>
              getBlocksFromContent({ ...component, key: uniqid() })
            )}
            {getIsRelatedContentShown(download, 'downloadIsLinkedTo') && (
              <RelatedContent
                content={getRelatedContent(download, 'downloadIsLinkedTo')}
              />
            )}
          </ResponsiveWrapper>
        </div>
      )}
    </div>
  );
};

export default Download;
