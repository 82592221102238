import { renderConfirmationModal } from 'helpers/confirmation-render';
import Icons from 'assets/icons';

export const getActionMenuItems = ({ handleDelete, goalId }) => [
  {
    label: 'Delete goal',
    icon: <Icons.Delete customClass="text-red-04 mr-2" />,
    isDelete: true,
    onClick: () =>
      renderConfirmationModal({
        headerText: `Are you sure you want to delete this goal?`,
        onConfirmCallback: () => {
          handleDelete(goalId);
        },
        onConfirmButtonLabel: 'Delete the goal',
      }),
  },
];
