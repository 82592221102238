import React, { useEffect } from 'react';

import Divider from 'components/shared/others/divider/divider';

import CurrentUserProfile from 'containers/admin/profile/profile';
import SecurityHub from 'containers/user/profile/components/security-hub/security-hub';

import api from 'api';

import { useCurrentUser } from 'state/current-user/current-user.thunk';
import ResponsiveWrapper from 'components/shared/others/responsive-wrapper/responsive-wrapper';

const Profile = () => {
  const currentUser = useCurrentUser();

  useEffect(() => {
    // Used to check if session has timed out for this component
    api.getCurrentUser();
  }, []);

  return (
    <ResponsiveWrapper>
      <CurrentUserProfile />
      {!currentUser.data.superAdmin && (
        <>
          <Divider />
          <SecurityHub />
        </>
      )}
    </ResponsiveWrapper>
  );
};

export default Profile;
