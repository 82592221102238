import React from 'react';

import GoalsSidebarItem from 'containers/admin/clients/client/tabs-components/goals-tab/goals-manager/goals-sidebar/goals-sidebar-item/goals-sidebar-item';

const GoalsSidebar = ({
  goals = [],
  handleGoalSelect,
  selectedGoal,
  isNew,
  isEditMode,
}) => (
  <div className="w-[300px] pr-4 pt-4 border-r">
    {isNew && <GoalsSidebarItem {...{ isNew }} />}
    {goals.map((goal) => (
      <GoalsSidebarItem
        key={goal?.id}
        {...{
          goal,
          handleGoalSelect,
          selectedGoal,
          disabled: isNew || isEditMode,
        }}
      />
    ))}
  </div>
);

export default GoalsSidebar;
