const existingCategories = [
  {
    name: 'Topline',
    details: {
      required: true,
      associatedPropertyName: 'focusArea',
      displayName: 'Focus Area',
      options: [
        {
          value: 'Enrollment',
          label: 'Enrollment',
        },
      ],
    },
  },
  {
    name: 'Demographic',
    details: {
      required: true,
      associatedPropertyName: 'focusArea',
      displayName: 'Focus Area',
      options: [
        {
          value: 'Inquiry Source',
          label: 'Inquiry Source',
          nested: {
            required: true,
            associatedPropertyName: 'options',
            displayName: 'Option',
            options: [
              { value: 'Application', label: 'Application' },
              {
                value: 'Application Materials',
                label: 'Application Materials',
              },
              { value: 'Athletics', label: 'Athletics' },
              { value: 'Camp', label: 'Camp' },
              { value: 'Purchase', label: 'Purchase' },
              { value: 'Referral', label: 'Referral' },
              { value: 'Search', label: 'Search' },
              { value: 'Self-Initiated', label: 'Self-Initiated' },
              { value: 'Test Scores', label: 'Test Scores' },
              { value: 'Travel', label: 'Travel' },
              { value: 'Virtual', label: 'Virtual' },
              { value: 'Visit', label: 'Visit' },
              { value: 'Web/Digital', label: 'Web/Digital' },
              { value: 'Unknown', label: 'Unknown' },
            ],
          },
        },
        {
          value: 'Inquiry Class Year',
          label: 'Inquiry Class Year',
          nested: {
            required: true,
            associatedPropertyName: 'options',
            displayName: 'Option',
            multiple: true,
            options: [
              { value: 'Before', label: 'Before' },
              { value: 'Sophomore', label: 'Sophomore' },
              { value: 'Junior', label: 'Junior' },
              { value: 'Senior', label: 'Senior' },
              { value: 'Unknown', label: 'Unknown' },
            ],
          },
        },
        {
          value: 'Application Type',
          label: 'Application Type',
          nested: {
            required: true,
            associatedPropertyName: 'options',
            displayName: 'Option',
            options: [
              { value: 'Coalition', label: 'Coalition' },
              { value: 'Common', label: 'Common' },
              { value: 'Institutional', label: 'Institutional' },
              { value: 'Other', label: 'Other' },
              { value: 'Unknown', label: 'Unknown' },
            ],
          },
        },
        {
          value: 'Decision Type',
          label: 'Decision Type',
          nested: {
            required: true,
            associatedPropertyName: 'options',
            displayName: 'Option',
            options: [
              { value: 'Early Action', label: 'Early Action' },
              { value: 'Early Decision', label: 'Early Decision' },
              { value: 'Other', label: 'Other' },
              { value: 'Regular Decision', label: 'Regular Decision' },
              { value: 'Rolling', label: 'Rolling' },
              { value: 'Unknown', label: 'Unknown' },
            ],
          },
        },
        {
          value: 'Religion',
          label: 'Religion',
          nested: {
            required: true,
            associatedPropertyName: 'options',
            displayName: 'Option',
            options: [
              { value: 'Catholic', label: 'Catholic' },
              { value: 'Christian', label: 'Christian' },
              { value: 'Jewish', label: 'Jewish' },
              { value: 'Muslim', label: 'Muslim' },
              { value: 'None/No Pref', label: 'None/No Pref' },
              { value: 'Other', label: 'Other' },
              { value: 'Unknown', label: 'Unknown' },
            ],
          },
        },
        {
          value: 'Sex',
          label: 'Sex',
          nested: {
            required: true,
            associatedPropertyName: 'options',
            displayName: 'Option',
            options: [
              { value: 'Female', label: 'Female' },
              { value: 'Male', label: 'Male' },
              { value: 'No Selection', label: 'No Selection' },
              { value: 'Other', label: 'Other' },
              { value: 'Unknown', label: 'Unknown' },
            ],
          },
        },
        {
          value: 'Ethnicity',
          label: 'Ethnicity',
          nested: {
            required: true,
            associatedPropertyName: 'subArea',
            displayName: 'Sub Area',
            options: [
              {
                value: 'Athletic Recruitment',
                label: 'Athletic Recruitment',
                nested: {
                  required: true,
                  associatedPropertyName: 'options',
                  displayName: 'Option',
                  options: [
                    { value: 'Athlete', label: 'Athlete' },
                    { value: 'Non-Athlete', label: 'Non-Athlete' },
                  ],
                },
              },
              {
                value: 'International',
                label: 'International',
                nested: {
                  required: true,
                  associatedPropertyName: 'options',
                  displayName: 'Option',
                  options: [
                    { value: 'International', label: 'International' },
                    { value: 'Domestic', label: 'Domestic' },
                  ],
                },
              },
              {
                value: 'Of Color',
                label: 'Of Color',
                nested: {
                  required: false,
                  associatedPropertyName: 'options',
                  displayName: 'Option',
                  options: [
                    {
                      value: 'American Indian/Alaskan Native',
                      label: 'American Indian/Alaskan Native',
                    },
                    { value: 'Asian', label: 'Asian' },
                    {
                      value: 'Black/African American',
                      label: 'Black/African American',
                    },
                    { value: 'Hispanic', label: 'Hispanic' },
                    {
                      value: 'Native Hawaiian/Other Pacific Islander',
                      label: 'Native Hawaiian/Other Pacific Islander',
                    },
                    {
                      value: 'Two or More Races',
                      label: 'Two or More Races',
                    },
                  ],
                },
              },
              {
                value: 'White',
                label: 'White',
                nested: {
                  required: false,
                  associatedPropertyName: 'options',
                  displayName: 'Option',
                  options: [{ value: 'White', label: 'White' }],
                },
              },
              {
                value: 'Unknown',
                label: 'Unknown',
                nested: {
                  required: false,
                  associatedPropertyName: 'options',
                  displayName: 'Option',
                  options: [
                    {
                      value: 'Non-resident Alien',
                      label: 'Non-resident Alien',
                    },
                    { value: 'Unknown', label: 'Unknown' },
                  ],
                },
              },
            ],
          },
        },
        {
          value: 'International',
          label: 'International',
          nested: {
            required: true,
            associatedPropertyName: 'options',
            displayName: 'Option',
            options: [
              { value: 'International', label: 'International' },
              { value: 'Domestic', label: 'Domestic' },
            ],
          },
        },
        {
          value: 'Athletic Recruitment',
          label: 'Athletic Recruitment',
          nested: {
            required: true,
            associatedPropertyName: 'options',
            displayName: 'Option',
            options: [
              { value: 'Athlete', label: 'Athlete' },
              { value: 'Non-Athlete', label: 'Non-Athlete' },
            ],
          },
        },
      ],
    },
  },
  {
    name: 'Geographic',
    details: {
      required: true,
      associatedPropertyName: 'focusArea',
      displayName: 'Focus Area',
      options: [
        {
          value: 'Region',
          label: 'Region',
          nested: {
            required: true,
            associatedPropertyName: 'subArea',
            displayName: 'Sub Area',
            options: [
              {
                value: 'Western',
                label: 'Western',
                nested: {
                  required: false,
                  associatedPropertyName: 'options',
                  displayName: 'Option',
                  options: [
                    { value: 'Arizona', label: 'Arizona' },
                    { value: 'Hawaii', label: 'Hawaii' },
                    { value: 'Alaska', label: 'Alaska' },
                    { value: 'Washington', label: 'Washington' },
                    { value: 'Montana', label: 'Montana' },
                    { value: 'Colorado', label: 'Colorado' },
                    { value: 'California', label: 'California' },
                    { value: 'Nevada', label: 'Nevada' },
                    { value: 'Utah', label: 'Utah' },
                    { value: 'Wyoming', label: 'Wyoming' },
                    { value: 'Oregon', label: 'Oregon' },
                    { value: 'Idaho', label: 'Idaho' },
                  ],
                },
              },
              {
                value: 'Middle States',
                label: 'Middle States',
                nested: {
                  required: false,
                  associatedPropertyName: 'options',
                  displayName: 'Option',
                  options: [
                    { value: 'Pennsylvania', label: 'Pennsylvania' },
                    { value: 'New York', label: 'New York' },
                    { value: 'New Jersey', label: 'New Jersey' },
                    { value: 'Delaware', label: 'Delaware' },
                    {
                      value: 'District of Columbia',
                      label: 'District of Columbia',
                    },
                    { value: 'Maryland', label: 'Maryland' },
                  ],
                },
              },
              {
                value: 'South',
                label: 'South',
                nested: {
                  required: false,
                  associatedPropertyName: 'options',
                  displayName: 'Option',
                  options: [
                    { value: 'Virgin Islands', label: 'Virgin Islands' },
                    { value: 'Kentucky', label: 'Kentucky' },
                    { value: 'Georgia', label: 'Georgia' },
                    { value: 'Tennessee', label: 'Tennessee' },
                    { value: 'Puerto Rico', label: 'Puerto Rico' },
                    { value: 'Louisiana', label: 'Louisiana' },
                    { value: 'Virginia', label: 'Virginia' },
                    { value: 'Florida', label: 'Florida' },
                    { value: 'South Carolina', label: 'South Carolina' },
                    { value: 'Alabama', label: 'Alabama' },
                    { value: 'Mississippi', label: 'Mississippi' },
                    { value: 'North Carolina', label: 'North Carolina' },
                  ],
                },
              },
              {
                value: 'Southwestern',
                label: 'Southwestern',
                nested: {
                  required: false,
                  associatedPropertyName: 'options',
                  displayName: 'Option',
                  options: [
                    { value: 'Arkansas', label: 'Arkansas' },
                    { value: 'Texas', label: 'Texas' },
                    { value: 'Oklahoma', label: 'Oklahoma' },
                    { value: 'New Mexico', label: 'New Mexico' },
                  ],
                },
              },
              {
                value: 'Midwestern',
                label: 'Midwestern',
                nested: {
                  required: false,
                  associatedPropertyName: 'options',
                  displayName: 'Option',
                  options: [
                    { value: 'South Dakota', label: 'South Dakota' },
                    { value: 'Iowa', label: 'Iowa' },
                    { value: 'Kansas', label: 'Kansas' },
                    { value: 'Missouri', label: 'Missouri' },
                    { value: 'Wisconsin', label: 'Wisconsin' },
                    { value: 'Ohio', label: 'Ohio' },
                    { value: 'Minnesota', label: 'Minnesota' },
                    { value: 'West Virginia', label: 'West Virginia' },
                    { value: 'Indiana', label: 'Indiana' },
                    { value: 'North Dakota', label: 'North Dakota' },
                    { value: 'Illinois', label: 'Illinois' },
                    { value: 'Nebraska', label: 'Nebraska' },
                    { value: 'Michigan', label: 'Michigan' },
                  ],
                },
              },
              { value: 'New England', label: 'New England' },
            ],
          },
        },
        {
          value: 'In-State',
          label: 'In-State',
          nested: {
            required: true,
            associatedPropertyName: 'options',
            displayName: 'Option',
            options: [
              { value: 'In-State', label: 'In-State' },
              { value: 'Out-of-State', label: 'Out-of-State' },
            ],
          },
        },
        {
          value: 'Distance Band',
          label: 'Distance Band',
          nested: {
            required: true,
            associatedPropertyName: 'options',
            displayName: 'Option',
            multiple: true,
            options: [
              { value: '0-49', label: '0-49' },
              { value: '50-99', label: '50-99' },
              { value: '100-199', label: '100-199' },
              { value: '200-299', label: '200-299' },
              { value: '300-399', label: '300-399' },
              { value: '400-499', label: '400-499' },
              { value: '500-599', label: '500-599' },
              { value: '1,000+', label: '1,000+' },
              { value: 'Unknown', label: 'Unknown' },
            ],
          },
        },
      ],
    },
  },
  {
    name: 'Academic Program',
    details: {
      required: true,
      associatedPropertyName: 'focusArea',
      displayName: 'Focus Area',
      options: [
        {
          value: 'Academic Interest',
          label: 'Academic Interest',
          nested: {
            required: true,
            associatedPropertyName: 'options',
            displayName: 'Option',
            options: [
              { value: 'Biology Sci.', label: 'Biology Sci.' },
              { value: 'Business', label: 'Business' },
              { value: 'Computer Sci.', label: 'Computer Sci.' },
              { value: 'Crim. Justice', label: 'Crim. Justice' },
              { value: 'Education', label: 'Education' },
              { value: 'Engineering', label: 'Engineering' },
              { value: 'Health Fields', label: 'Health Fields' },
              { value: 'Interdisc.', label: 'Interdisc.' },
              { value: 'Law', label: 'Law' },
              { value: 'Liberal Arts', label: 'Liberal Arts' },
              { value: 'Misc/Other', label: 'Misc/Other' },
              { value: 'Multiple Majors', label: 'Multiple Majors' },
              { value: 'Nursing', label: 'Nursing' },
              { value: 'Physical Sciences', label: 'Physical Sciences' },
              { value: 'Psychology', label: 'Psychology' },
              { value: 'Social Sci.', label: 'Social Sci.' },
              { value: 'Undecided', label: 'Undecided' },
            ],
          },
        },
      ],
    },
  },
  {
    name: 'Financial Profile',
    details: {
      required: true,
      associatedPropertyName: 'focusArea',
      displayName: 'Focus Area',
      options: [
        {
          value: 'Consumer Income',
          label: 'Consumer Income',
          nested: {
            required: true,
            associatedPropertyName: 'options',
            displayName: 'Option',
            multiple: true,
            options: [
              { value: '<$50K', label: '<$50K' },
              { value: '$50K-$100K', label: '$50K-$100K' },
              { value: '$100K-$150K', label: '$100K-$150K' },
              { value: '$150K-$200K', label: '$150K-$200K' },
              { value: '$200K-$250K', label: '$200K-$250K' },
              { value: '$250K>', label: '$250K>' },
              { value: 'Unknown', label: 'Unknown' },
            ],
          },
        },
        {
          value: 'Consumer Rating',
          label: 'Consumer Rating',
          nested: {
            required: true,
            associatedPropertyName: 'options',
            displayName: 'Option',
            options: [
              { value: 'Rating 1', label: 'Rating 1' },
              { value: 'Rating 2', label: 'Rating 2' },
              { value: 'Rating 3', label: 'Rating 3' },
              { value: 'Rating 4', label: 'Rating 4' },
              { value: 'Rating 5', label: 'Rating 5' },
              { value: 'Rating 6', label: 'Rating 6' },
              { value: 'Rating 7', label: 'Rating 7' },
              { value: 'Rating 8', label: 'Rating 8' },
              { value: 'Rating 9', label: 'Rating 9' },
              { value: 'Rating 10', label: 'Rating 10' },
              { value: 'Rating 11', label: 'Rating 11' },
              { value: 'Rating 12', label: 'Rating 12' },
              { value: 'Rating 13', label: 'Rating 13' },
              { value: 'Rating 14', label: 'Rating 14' },
            ],
          },
        },
        {
          value: 'Pell',
          label: 'Pell',
          nested: {
            required: true,
            associatedPropertyName: 'options',
            displayName: 'Option',
            options: [
              { value: 'Pell', label: 'Pell' },
              { value: 'Non-Pell', label: 'Non-Pell' },
            ],
          },
        },
      ],
    },
  },
];

/**
 * Builds nested options recursively from a category's details.
 *
 * @param {Object} details - The details object containing options to build nested options from.
 * @param {Array<Object>} details.options - Array of options for the category.
 * @returns {Array<Object>} The mapped array of options, each with its value, label, and nested options if applicable.
 */

const buildNestedOptions = (details) =>
  details.options.map((option) => {
    const nestedOptions = option.nested
      ? buildNestedOptions(option.nested)
      : null;

    return {
      value: option.value,
      label: option.label,
      required: option.required ?? false,
      displayName: option.displayName || option.label,
      nested: nestedOptions
        ? { ...option.nested, options: nestedOptions }
        : null,
    };
  });

/**
 * Builds options for the combined category schema by iterating over the provided categories.
 * Filters out categories with the name 'Topline'.
 *
 * @param {Array<Object>} categories - An array of category objects to build options from.
 * @returns {Array<Object>} An array of category objects with options for the combined category.
 */

const buildOptionsForCombined = (categories) =>
  categories
    .map((category) => {
      const { name, details } = category;

      if (name === 'Topline') {
        return;
      }

      return {
        value: name,
        label: name,
        required: details.required ?? false,
        displayName: details.displayName || name,
        nested: details
          ? {
              required: details.required ?? false,
              displayName: details.displayName || name,
              associatedPropertyName: details.associatedPropertyName,
              options: buildNestedOptions(details),
            }
          : null,
      };
    })
    .filter((item) => item);

/**
 * The combined category schema which merges the existing categories into one.
 *
 * @type {Object}
 * @property {string} name - Name of the category.
 * @property {Object} details - Details object that contains required, associatedPropertyName, and displayName properties.
 * @property {Array<Object>} details.options - The combined category options built from existing categories.
 */

// Build Combined category schema
const combinedCategory = {
  name: 'Combined',
  details: {
    required: true,
    associatedPropertyName: 'category',
    displayName: 'Category',
    options: buildOptionsForCombined(existingCategories),
  },
};

/**
 * The array of all categories, including the combined category schema.
 *
 * @type {Array<Object>}
 */

export const categories = [...existingCategories, combinedCategory];
