import React from 'react';
import { withRouter } from 'react-router-dom';

import Heading from 'components/partials/heading/heading';
import CustomButton from 'components/partials/custom-button/custom-button';

import UserPage from 'containers/admin/users/user/user-page/user-page';

import { useCurrentUser } from 'state/current-user/current-user.thunk';

import Icons from 'assets/icons';
import ResponsiveWrapper from 'components/shared/others/responsive-wrapper/responsive-wrapper';

const Profile = () => {
  const currentUser = useCurrentUser();

  return (
    <ResponsiveWrapper>
      <div className="flex items-center justify-between">
        <Heading variant="h1" customClass="mt-4">
          Your Profile Details
        </Heading>
        <CustomButton
          variant="text"
          size="lg"
          iconBefore={
            <Icons.LogOut
              customClass="mt-1"
              alt="logoutIcon"
              height="100%"
              width="18"
            />
          }
          customClass="mt-4 pr-0"
          onClick={() => currentUser.signOut()}
        >
          Log Out
        </CustomButton>
      </div>

      <UserPage isCurrentUserProfile={true} />
    </ResponsiveWrapper>
  );
};

export default withRouter(Profile);
