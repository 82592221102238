import { useDispatch, useSelector } from 'react-redux';

import { loadReports } from 'state/reports/actions/load-reports.action';

export const useReports = () => {
  const dispatch = useDispatch();

  const reports = useSelector((state) => state.reports);

  return {
    load: () => dispatch(loadReports()),
    loadIfMissing: () => {
      if (reports.data || reports.loading || reports.error) return;

      return dispatch(loadReports());
    },

    ...reports,
  };
};
