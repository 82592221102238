import React, { useState, useEffect } from 'react';

import { getApiHealth } from 'api/api.config';

import oldLogo from 'assets/images/old-marketview-logo.svg';
import maintenanceImage from 'assets/images/maintenance.svg';

const MaintenancePage = ({ children }) => {
  const [health, setHealth] = useState(true);

  const getHealth = () => {
    // Don't check api health if user is offline
    if (navigator.onLine) {
      getApiHealth()
        .then((resp) => {
          if (resp && resp.data && resp.data.status === 'UP') {
            setHealth(true);
            // Check to make sure internet is connected again
          } else if (navigator.onLine) {
            setHealth(false);
          }
        })
        .catch((err) => {
          console.log('Api Error', err);
          setHealth(false);
        });
    }
  };

  const MaintenancePoll = () => {
    setTimeout(() => {
      getHealth();
      MaintenancePoll();
    }, 300000);
  };

  useEffect(() => {
    getHealth();
    MaintenancePoll();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {health ? (
        <>{children}</>
      ) : (
        <div className="flex min-h-screen justify-center">
          <div className="flex w-full sm:w-1/2 px-8 md:px-32 py-4 md:py-16">
            <div className="w-full flex flex-col justify-center items-center">
              <img src={oldLogo} className="mb-8" />

              <p className="text-xl font-light leading-normal">
                MARKETview is temporarily off-line for updates. We remain
                focused on improving data privacy and security and the
                experience that gives you complete vision into the market and
                your place within it. To reach MARKETview, please email{' '}
                <a
                  href="mailto:support@marketviewedu.com"
                  className="text-blue-02 font-light decoration-2 underline"
                >
                  support@marketviewedu.com
                </a>{' '}
                or visit our{' '}
                <a
                  href="https://marketviewedu.com/contact-us/"
                  target="_blank"
                  className="text-blue-02 font-light decoration-2 underline"
                >
                  web site contact page
                </a>
                .
              </p>
            </div>
          </div>
          <div
            className="items-center hidden sm:flex w-1/2 p-8"
            style={{ background: 'linear-gradient(#45B8E6, #007AAB)' }}
          >
            <img src={maintenanceImage} className="mx-auto" />
          </div>
        </div>
      )}
    </>
  );
};

export default MaintenancePage;
