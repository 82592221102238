import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { Security } from '@okta/okta-react';

import oktaConfig from 'utils/okta/okta.config';
import store from 'state/store';
import { history } from 'state/history';
import { getOktaUserId } from 'helpers/okta-session';

import App from 'app';
import * as serviceWorker from 'service-worker';
import * as FullStory from '@fullstory/browser';

import 'helpers/polyfill';

import powerbi from 'utils/powerbi';

import 'styles/index.css';

function onAuthRequired({ history }) {
  history.push('/login');
}

powerbi.preload({
  type: 'report',
  embedUrl: 'https://app.powerbi.com/reportEmbed',
});

// Use for section 508 compliance a11y testing. Commenting out just in case NODE_ENV isn't set
// var config = {
//   rules: [
//     { tags: ["section508"] }
//   ]
// };
//
//
// if (env.NODE_ENV !== 'production') {
//   var axe = require('react-axe');
//   axe(React, ReactDOM, 5000, config);
// }

const oktaUserId = getOktaUserId();

FullStory.init({ orgId: 'o-1CBCVA-na1' });
oktaUserId && FullStory.identify(oktaUserId);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <Router history={history}>
      <Security
        issuer={oktaConfig.issuer}
        clientId={oktaConfig.clientId}
        redirectUri={oktaConfig.redirectUri}
        pkce={oktaConfig.pkce}
        onAuthRequired={onAuthRequired}
      >
        <App />
      </Security>
    </Router>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
