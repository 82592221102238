const NOTIFICATION_STORAGE_KEY = '_flash_notification';

class Notification {
  static get notification() {
    const _notification = sessionStorage.getItem(NOTIFICATION_STORAGE_KEY);

    if (!_notification) return {};

    return JSON.parse(_notification);
  }

  static set notification(notificationParams) {
    sessionStorage.setItem(
      NOTIFICATION_STORAGE_KEY,
      JSON.stringify(notificationParams)
    );
  }

  static flash(heading, variant = 'info', message) {
    this.notification = { heading, variant, message };
  }

  static clear() {
    sessionStorage.removeItem(NOTIFICATION_STORAGE_KEY);
  }
}

export default Notification;
