import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import uniqid from 'uniqid';

import CustomButton from 'components/partials/custom-button/custom-button';

import ActionMenuItem from './action-menu-btn/action-menu-btn';

import Icons from 'assets/icons';

const ActionMenu = ({
  buttonName = 'Edit profile',
  options = [],
  onEditClick,
  isActionMenuDisabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef();
  const actionMenuBtn = useRef();

  const handleClickOutside = (e) => {
    if (
      !actionMenuBtn.current?.contains(e.target) &&
      !dropdownRef.current?.contains(e.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="h-[44px] relative">
      <div
        className={classNames('flex h-full', {
          'justify-end': !options.length,
        })}
      >
        <CustomButton
          customClass={classNames(
            'action-button font-semibold text-indigo-02 rounded-r-none !px-10',
            {
              '!rounded-r-md': !options.length,
            }
          )}
          onClick={onEditClick}
          disabled={isActionMenuDisabled}
          iconBefore={<Icons.Edit customClass="mr-2" />}
          variant="secondary"
        >
          {buttonName}
        </CustomButton>
        {!!options.length && (
          <CustomButton
            ref={actionMenuBtn}
            customClass={classNames(
              'action-menu-item w-[44px] ml-1.5 rounded-l-none',
              {
                'bg-gray-00': isOpen,
              }
            )}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            variant="secondary"
            disabled={isActionMenuDisabled}
          >
            <Icons.Arrow
              customClass={classNames(
                'arrow-button transition shrink-0 rotate-90',
                {
                  '!-rotate-90': isOpen,
                }
              )}
            />
          </CustomButton>
        )}
      </div>
      {isOpen && (
        <div
          className="dropdown-menu w-full absolute z-[700] p-2 border border-blue-04 bg-white rounded-lg mt-1 shadow-lg text-left"
          ref={dropdownRef}
        >
          {options.map((option) => (
            <ActionMenuItem
              key={uniqid()}
              hideDropdownCallback={() => setIsOpen(false)}
              {...option}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ActionMenu;
