import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  forwardRef,
} from 'react';
import classNames from 'classnames';

import CustomButton from 'components/partials/custom-button/custom-button';

import { FileContext } from 'containers/admin/clients/client/tabs-components/files-tab/file/file';

import { downloadFile } from 'helpers/download-file';
import { formatDate } from 'helpers/dates-formatter';

import {
  getIconByFileType,
  readebleFileSize,
  getFileFormat,
} from './file-uploader.helper';

import Icons from 'assets/icons';

const FileUploader = forwardRef(
  (
    {
      label,
      acceptableFiles,
      helpText,
      error = {},
      disabled,
      onChange,
      value,
      required,
    },
    ref
  ) => {
    const { currentUser, clientId, fileId, setLoading } =
      useContext(FileContext);

    const [file, setFile] = useState(value || {});

    const [fileFormat, setFileFormat] = useState('pdf');
    const errorText = error.message;

    const fileInputRef = useRef(ref);

    const onFileChange = (e) => {
      if (!e.target.files[0]) {
        return;
      }

      setFile(e.target.files[0]);

      if (onChange) {
        onChange(e.target.files[0]);
      }
    };

    let handleFileUploadClick = (e) => {
      e.preventDefault();
      fileInputRef.current.click();
    };

    useEffect(() => {
      setFile(value);
    }, [value]);

    useEffect(() => {
      if (file?.name) {
        setFileFormat(getFileFormat(file.name));
      }
    }, [file]);

    const icon = getIconByFileType(fileFormat);

    return (
      <div className="flex flex-col">
        {label && (
          <label className="flex font-semibold mt-2">
            {label}
            {required ? (
              <Icons.RequiredIndicator customClass="w-[7px] h-[7px] ml-1 mt-1" />
            ) : null}
          </label>
        )}
        <input
          ref={fileInputRef}
          type="file"
          className="hidden"
          onChange={onFileChange}
          accept={acceptableFiles}
        />
        {!file?.name ? (
          <>
            <div
              className={classNames(
                'cursor-pointer bg-gray-00 h-24 flex flex-col items-center justify-center rounded-md border border-dashed',
                {
                  'border-red-04 !border-solid': errorText,
                  'border-gray-05': !errorText,
                }
              )}
              onDrop={(e) => {
                e.preventDefault();
                onChange(e.dataTransfer.files[0]);
              }}
              onDragOver={(e) => e.preventDefault()}
              onClick={handleFileUploadClick}
            >
              <Icons.DefaultFile customClass="w-7 h-9" />
              <span>
                <b>Click to upload </b>or drag and drop the file.
              </span>
            </div>
            {helpText && !errorText && (
              <span className="mt-1 text-sm">{helpText}</span>
            )}
            {errorText && (
              <span className="mt-1 text-sm text-red-04">{errorText}</span>
            )}
          </>
        ) : (
          <>
            <div
              className={classNames('rounded-md border ', {
                'border-gray-05': !errorText,
                'border-red-04': errorText,
              })}
            >
              <div className="py-3 px-2 flex items-center justify-between relative">
                <div className="flex items-center">
                  <div className="mr-1">{icon}</div>
                  <div className="flex flex-col">
                    <span className="font-semibold">{file.name}</span>
                    <div className="flex items-center text-xs">
                      <span>{readebleFileSize(file.size)}</span>

                      {file?.uploadDate && (
                        <>
                          <span className="mx-2">•</span>
                          <span>
                            {formatDate(file?.uploadDate, 'MMM D, YYYY')}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex pr-3 ml-1">
                  {disabled ? (
                    <CustomButton
                      onClick={() =>
                        downloadFile({
                          fileName: file?.name,
                          setLoading,
                          currentUser,
                          clientId,
                          fileId,
                        })
                      }
                      size="sm"
                      variant="secondary"
                      customClass={classNames({ 'mr-3': !disabled })}
                      type="button"
                    >
                      Download
                    </CustomButton>
                  ) : (
                    <CustomButton
                      onClick={handleFileUploadClick}
                      size="sm"
                      variant="secondary"
                    >
                      Replace file
                    </CustomButton>
                  )}
                </div>
              </div>
            </div>
            {!!errorText && (
              <span className="mt-1 text-sm text-red-04">{errorText}</span>
            )}
          </>
        )}
      </div>
    );
  }
);

export default FileUploader;
