import { parseFormattedNumber } from 'components/partials/formatted-input/formatted-input.helpers';

export const getValueLabel = (valueType) => {
  const TYPES = {
    PERCENT: '%',
    DOLLAR: '$',
  };

  return `Value ${TYPES[valueType] ? `, ${TYPES[valueType]}` : ''}`;
};

export const validateValue = (value, valueType) => {
  if (value === '') {
    return 'This field is required';
  }
  const parsedValue = parseFormattedNumber(value);

  if (isNaN(parsedValue)) {
    return 'Invalid value format';
  }

  if (valueType === 'PERCENT' && (parsedValue <= 0 || parsedValue > 100)) {
    return `Value must be greater than 0 and less than or equal to 100`;
  }

  if (valueType?.startsWith('RANGE')) {
    const [min, max] = valueType.split('_').slice(-2).map(Number);

    if (parsedValue <= min || parsedValue > max) {
      return `Value must be greater than ${min} and less than or equal to ${max}`;
    }
  } else {
    if (parsedValue <= 0) {
      return 'Value must be greater than 0';
    }
  }

  return true;
};
