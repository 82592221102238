import CustomButton from 'components/partials/custom-button/custom-button';
import NotificationPoint from 'components/partials/notification-point/notification-point';

import { getIconByFileType } from 'components/partials/file-uploader/file-uploader.helper';
import { formatDate } from 'helpers/dates-formatter';
import { downloadFile } from 'helpers/download-file';

import Icons from 'assets/icons';

const commonStyles = 'border-r-2 border-white font-semibold';

export const getFilesTableSettings = (currentUser, setLoading) =>
  [
    {
      id: 'asset.fileExt',
      accessorKey: 'asset.fileExt',
      header: () => <span>FILE TYPE</span>,
      cell: ({
        row: {
          original: { asset, viewed },
        },
      }) => {
        const icon = getIconByFileType(asset.fileExt);

        return (
          <div className="relative w-100">
            {!viewed && <NotificationPoint isNoContent={true} />}
            {icon}
          </div>
        );
      },
      headerClassName: `${commonStyles} rounded-tl-md rounded-bl-md cursor-context-menu`,
      className: 'xs:w-[10%] sm:w-[9%] md:w-[7%] xl:w-[6%]',
      isHeaderCentered: true,
      enableSorting: false,
    },
    {
      id: 'asset.name',
      accessorKey: 'asset.name',
      header: () => <span>FILE NAME</span>,
      cell: (info) => (
        <div className="w-full pr-2 font-semibold">{info.renderValue()}</div>
      ),
      headerClassName: `${commonStyles} pl-4 cursor-pointer`,
      className: 'pl-4 xs:w-[19%] sm:w-[16%] md:w-[17%]',
      enableSorting: true,
      sortingFn: 'alphanumeric',
    },
    {
      id: 'asset.description',
      accessorKey: 'asset.description',
      header: () => <span>DESCRIPTION</span>,
      cell: (info) => <span>{info.renderValue()}</span>,
      headerClassName: `${commonStyles} pl-4 cursor-context-menu`,
      className: 'pl-4 xs:w-[21%] sm:w-[20%] lg:w-[20%]',
      enableSorting: false,
    },
    {
      id: 'asset.createdDate',
      accessorKey: 'asset.createdDate',
      header: () => <span>UPLOAD DATE</span>,
      cell: (info) =>
        formatDate(info.row.original.asset.createdDate, 'MMM D, YYYY h:mm A'),
      headerClassName: `${commonStyles} pl-4 cursor-pointer`,
      className: 'pl-4 xs:w-[15%]',
      enableSorting: true,
      sortingFn: 'alphanumeric',
    },
    {
      id: 'button-cell',
      cell: ({
        row: {
          original: { id, asset },
        },
      }) => (
        <CustomButton
          onClick={() =>
            downloadFile({
              setLoading,
              currentUser,
              fileId: id,
              fileName: `${asset.fileName}.${asset.fileExt}`,
            })
          }
          variant="primary"
          size="sm"
          iconBefore={
            <Icons.Download alt="DownloadIcon" height="100%" width="14" />
          }
          customClass="mx-auto text-xs md:text-sm"
        >
          Download
        </CustomButton>
      ),
      headerClassName: 'pl-4 h-12 rounded-tr-md rounded-br-md pl-4',
      className:
        'text-center xs:w-[16%] sm:w-[15%] md:w-[12%] lg:w-[10%] xl:w-[9%]',
    },
  ].filter((item) => item);
