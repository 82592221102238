import React from 'react';

const Divider = ({ customClass = '' }) => (
  <div
    role="divider"
    className={`w-full my-12 border-b-2 border-solid border-t-gray ${customClass}`}
  />
);

export default Divider;
