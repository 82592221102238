import { conformToMask } from 'react-text-mask';

import * as masks from 'constants/forms-validaition';

export const formatValue = (value, format) => {
  switch (format) {
    case 'phone':
      return conformToMask(value.toString(), masks.phone, { guide: false })
        .conformedValue;
    case 'date':
      return conformToMask(value.toString(), masks.mmddyyyy).conformedValue;
    default:
      return value;
  }
};
