import { formatDate } from 'helpers/dates-formatter';

import { ROLES } from 'constants/roles';
import { STATES } from 'constants/states';

// Utility functions
export const mapUserToSelectOption = (user) =>
  user && {
    label: user.name,
    value: user.id,
  };

export const mapUsersToSelectOptions = (users) =>
  users && users.map((user) => mapUserToSelectOption(user));

export const mapClientToSelectOption = (client) =>
  client && {
    label: client.name,
    value: client.id,
  };

export const mapClientsToSelectOptions = (clients) =>
  clients && clients.map((client) => mapClientToSelectOption(client));

export const findUser = (userId, users) => users.find((u) => u.id === userId);

export const isPrimitive = (value) => typeof value !== 'object';

export const mapRoleToSelectOptions = (role) =>
  role && {
    label: role.label,
    value: role.radioValue,
  };

export const mapRolesToSelectOptions = (roles) =>
  roles && roles.map((role) => mapRoleToSelectOptions(role));

export const mapDomainsToSelectOptions = (domainsString) =>
  domainsString &&
  domainsString.split(',').map((domain) => ({ label: domain, value: domain }));

export const withoutNulls = (obj) => {
  const objWithoutNulls = { ...obj };

  Object.keys(objWithoutNulls).forEach((key) => {
    if (objWithoutNulls[key] == null) delete objWithoutNulls[key];
  });

  return objWithoutNulls;
};

// can use this to undo the masking below (before form submission, etc.)
export const toNumber = (value) => value.toString().replace(/\D/g, '');

export const flattenObject = (obj, prefix = '') =>
  Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? prefix + '.' : '';

    if (typeof obj[k] === 'object')
      Object.assign(acc, flattenObject(obj[k], pre + k));
    else acc[pre + k] = obj[k];

    return acc;
  }, {});

export const consultantNameByRole = (client, consultantRole) => {
  const consultant = client.consultants.find(
    (c) => c.consultantType === consultantRole
  );

  if (!consultant) return '';

  return consultant?.user?.name || '';
};

const createConsultantData = (type, formConsultant, clientId = '') => {
  if (!formConsultant) return;

  const consultantData = {
    user: { id: formConsultant },
    clientId: clientId,
    consultantType: type.toUpperCase(),
  };

  if (clientId) consultantData.clientId = clientId;

  return consultantData;
};

//client form
export const transformProfileFormData = (formValues) => {
  const values = {};

  if (formValues.id) values.id = formValues.id;

  values.authorizedDomains = formValues.authorizedDomains
    .map((domain) => domain.value)
    .join(',');

  values.consultants = [
    createConsultantData(
      'principal',
      formValues.principalConsultant?.value,
      formValues?.id
    ),
    createConsultantData(
      'contact',
      formValues.contactConsultant?.value,
      formValues?.id
    ),
    createConsultantData(
      'analyst',
      formValues.analystConsultant?.value,
      formValues?.id
    ),
  ].filter((c) => c);

  const expireDate =
    formValues.expireDate && formValues.expireDate !== 'expireDate'
      ? formValues.expireDate
      : '01/01/2100';

  values.expireDate = formatDate(expireDate, 'YYYY-MM-DD');

  if (formValues.status) values.status = formValues.status;

  values.externalId = formValues.externalId;
  values.name = formValues.name;
  values.state = formValues.state.value;

  return values;
};

export const transformClientToFormObject = (client) => {
  /**
  We get consultants like: [{id: 2, user: {id: 1}, clientId: 54, consultantType: "PRINCIPAL"}]
  and we want to transform them into objects that match our form:
    {
      principal: {label: 'first last', value: userId}
    }
  */
  const transformedConsultants = client.consultants.reduce(
    (formObj, consultant) => {
      const consultantUser = consultant.user;
      const asSelectOptions = mapUserToSelectOption(consultantUser);

      if (asSelectOptions) {
        asSelectOptions.consultantId = consultant.id;

        formObj[`${consultant.consultantType.toLowerCase()}Consultant`] =
          asSelectOptions; //consultantUser.id;
      }

      return formObj;
    },
    {}
  );

  const transformed = {
    id: client.id,
    externalId: client.externalId,
    name: client.name,
    state: STATES.find((state) => state.value === client.state),
    authorizedDomains: mapDomainsToSelectOptions(client?.authorizedDomains),
    expireDate: formatDate(client.expireDate, 'MM/DD/YYYY'),
    ...transformedConsultants,
  };

  return transformed;
};

//user form
export const transformUserFormData = (formValues, currentUser) => {
  const { firstName, lastName, jobTitle, email, role } = formValues;

  const userObject = {
    firstName,
    lastName,
    jobTitle,
    email,
    client: null,
  };

  if (formValues?.id) userObject.id = formValues.id;

  if (formValues?.client?.value) {
    userObject.client = { id: formValues.client.value };
  }

  // Don't let the current user update their role
  if (currentUser?.id !== formValues.id) {
    switch (role) {
      case 'superAdmin':
      case 'Super Admin':
        userObject.authority = ROLES.SUPER_ADMIN;
        break;
      case 'fdPrincipal':
      case 'MV Principal':
        userObject.authority = ROLES.FD_PRINCIPAL;
        break;
      case 'fdClientService':
      case 'MV Client Service':
        userObject.authority = ROLES.FD_CLIENT_SERVICE;
        break;
      case 'fdAnalyst':
      case 'MV Analyst':
        userObject.authority = ROLES.FD_ANALYST;
        break;
      case 'admin':
        userObject.authority = ROLES.CLIENT_ADMIN;
        break;
      default:
        userObject.authority = ROLES.USER;
        break;
    }
  }

  return userObject;
};

export const getRoleNameFromAuthorities = (authority) => {
  if (authority === ROLES.SUPER_ADMIN) {
    return 'Super Admin';
  }
  if (authority === ROLES.FD_PRINCIPAL) {
    return 'MV Principal';
  }
  if (authority === ROLES.FD_CLIENT_SERVICE) {
    return 'MV Client Service';
  }
  if (authority === ROLES.FD_ANALYST) {
    return 'MV Analyst';
  }

  return 'user';
};

export const transformUserToFormObject = (user, client = null) => {
  let defaultUserData = {
    firstName: '',
    lastName: '',
    jobTitle: '',
    email: '',
    confirmEmail: '',
    role: getRoleNameFromAuthorities(user?.authority),
    client: client && mapClientToSelectOption(client),
  };

  if (!!user)
    defaultUserData = {
      id: user?.id,
      firstName: user?.firstName,
      lastName: user?.lastName,
      jobTitle: user?.jobTitle,
      email: user?.email,
      role: getRoleNameFromAuthorities(user?.authority),
      client: mapClientToSelectOption(user.client),
    };

  return defaultUserData;
};

export const transformFileToFormObject = ({
  name,
  fileName,
  fileSize,
  fileExt,
  uploadDate,
  description,
}) => ({
  fileName: { value: name, label: name },
  description,
  file: {
    name: `${fileName}.${fileExt}`,
    size: fileSize,
    uploadDate,
    isFakeFile: true,
  },
});
