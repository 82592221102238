import React, { useState, useEffect } from 'react';
import uniqid from 'uniqid';

import Loader from 'components/partials/loader/loader';
import Breadcrumbs from 'components/partials/beadcrumbs/breadcrumbs';
import RelatedContent from 'containers/user/learn/components/related-content/related-content';

import api from 'api';

import { useCurrentUser } from 'state/current-user/current-user.thunk';

import { getBlocksFromContent } from 'containers/user/learn/heplers/get-blocks-from-content';
import {
  getIsRelatedContentShown,
  getRelatedContent,
} from 'containers/user/learn/heplers/content-builder-helpers';
import ResponsiveWrapper from 'components/shared/others/responsive-wrapper/responsive-wrapper';
import Heading from 'components/partials/heading/heading';
import Author from 'containers/user/learn/components/author/author';
import { formatDate } from 'helpers/dates-formatter';
import Icons from 'assets/icons';

const Webinar = ({ webinarId }) => {
  const [webinar, setWebinar] = useState({});
  const [loading, setLoading] = useState(false);
  const [presenters, setPresenters] = useState([]);

  const user = useCurrentUser();

  useEffect(() => {
    setLoading(true);
    api
      .getWebinar(webinarId, user?.data?.oktaUserId)
      .then((res) => {
        setWebinar(res.data.data.attributes);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (!!webinar?.presenters?.length) {
      setPresenters(webinar.presenters);
    }
  }, [webinar]);

  return loading ? (
    <Loader {...{ loading }} />
  ) : (
    <ResponsiveWrapper md="1120" lg="1120" customClass="flex flex-col">
      <Breadcrumbs
        breadcrumbs={[
          { label: 'Learn', href: '/learn' },
          { label: 'Webinars', href: '/learn/content/webinars' },
        ]}
        customClass="!mt-5"
      />
      <Heading customClass="text-4xl font-semibold mobile:pt-4 xs:pt-6 mb-2">
        {webinar.name}
      </Heading>

      <div className="flex items-center text-blue-03 mb-4">
        {webinar.webinarDate && (
          <>
            <Icons.Calendar className="h-4 w-4 mr-1" />
            <div className="text-sm">
              {formatDate(webinar.webinarDate, 'MMM D, YYYY')}
            </div>
          </>
        )}
      </div>

      {!!presenters?.length && (
        <>
          <div className="text-dark-01 font-semibold mb-2">Presenters:</div>
          <div className="flex mobile:gap-5 flex-wrap mb-10">
            {presenters.map(
              ({ presenterName, presenterPosition, presenterAvatar }) => (
                <Author
                  {...{
                    key: uniqid(),
                    name: presenterName,
                    position: presenterPosition,
                    avatar: presenterAvatar?.data?.attributes.url,
                  }}
                />
              )
            )}
          </div>
        </>
      )}

      {webinar.content?.map((component) =>
        getBlocksFromContent({ ...component, key: uniqid() })
      )}

      {/* <WasResourseHelpfulBanner relatedTags={webinar.relatedTags} /> */}

      {getIsRelatedContentShown(webinar, 'webinarIsLinkedTo') && (
        <RelatedContent
          content={getRelatedContent(webinar, 'webinarIsLinkedTo')}
        />
      )}
    </ResponsiveWrapper>
  );
};

export default Webinar;
