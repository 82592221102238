import React, { useEffect, useState } from 'react';
import { withAuth } from '@okta/okta-react';
import { useHistory } from 'react-router-dom';

/**
 * A component that handles the callback from Okta after authentication.
 * It processes the authentication response and redirects the user to the
 * original path they were attempting to access before being redirected to login.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.auth - The auth object provided by Okta for handling authentication.
 * @param {Function} [props.afterLogin] - Optional callback function to be executed after successful login.
 * @returns {React.Element|null} Renders an error message if authentication fails, otherwise returns null.
 */
const ImplicitCallback = ({ auth, afterLogin }) => {
  const [error, setError] = useState(null);
  const history = useHistory();

  /**
   * Key used to store the referrer path in localStorage.
   * @type {string}
   */
  const referrerKey = 'secureRouterReferrerPath';

  /**
   * Retrieve the location the user was trying to access before authentication.
   * Defaults to the root path ("/") if no path is stored.
   * @type {Object}
   */
  const location = JSON.parse(
    localStorage.getItem(referrerKey) || '{ "pathname": "/" }'
  );

  localStorage.removeItem(referrerKey);

  useEffect(() => {
    /**
     * Handles the authentication process using Okta's auth object.
     * On success, executes the afterLogin callback (if provided) and redirects
     * the user to the intended path. If an error occurs, updates the error state.
     */
    const handleAuth = async () => {
      try {
        await auth.handleAuthentication();
        if (afterLogin) {
          afterLogin();
        }
        history.push(location.pathname);
      } catch (err) {
        setError(err.toString());
      }
    };

    handleAuth();
  }, [auth, afterLogin, history]);

  if (error) {
    return <p>{error}</p>;
  }

  // Returns null while handling authentication, as the component does not need to render anything.
  return null;
};

export default withAuth(ImplicitCallback);
