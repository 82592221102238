import React from 'react';
import Overlay from 'components/shared/others/overlay/overlay';

const Tile = ({ children, customClass, onClick }) => (
  <div
    className={`rounded-lg shadow bg-white p-6 border border-gray-00 ${customClass}`}
    onClick={onClick}
  >
    {children}
  </div>
);

export default Tile;
