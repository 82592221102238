import React from 'react';
import classNames from 'classnames';

import Toggle from 'components/partials/toggle/toggle';
import { CustomSelect as Select } from 'components/partials/custom-select/custom-select';

const generateYearOptions = () => {
  const currentYear = new Date().getFullYear();
  const range = {
    start: currentYear + 3,
    end: currentYear - 2,
  };

  const totalYears = range.start - range.end + 1;

  return Array.from({ length: totalYears }, (_, index) => {
    const year = range.start - index;

    return {
      value: year,
      label: year,
    };
  });
};

const GoalsFilters = ({
  showOnlyDefined,
  setShowOnlyDefined,
  selectedYear,
  setSelectedYear,
  customClass = '',
}) => {
  const years = generateYearOptions();

  return (
    <div className={classNames('flex items-center mb-4', customClass)}>
      <Select
        options={years}
        value={years.find((year) => year.value === selectedYear)}
        onChange={(option) => setSelectedYear(option.value)}
        customSelectWrapperClass="!w-28 mr-4"
        extraBasicStyles={{ padding: 0, height: '2' }}
      />
      <Toggle
        onToggleChange={setShowOnlyDefined}
        isToggleOn={showOnlyDefined}
        toggleText="Show Only Defined Goals"
      />
    </div>
  );
};

export default GoalsFilters;
