import { actions } from 'state/current-user/current-user.slice';

import api from 'api';

import Notification from 'components/partials/notification/notification.helper';

const { changingPassword, changingPasswordFailed, changingPasswordSucceeded } =
  actions;

export const changePasswordAction =
  ({ newPassword, oldPassword }) =>
  (dispatch) => {
    dispatch(changingPassword());

    return api
      .changePassword({
        newPassword,
        oldPassword,
      })
      .then((_) => {
        dispatch(changingPasswordSucceeded());

        Notification.flash(
          `Password changed`,
          'success',
          `You've successfully updated your password`
        );

        return _;
      })
      .catch((error) => {
        dispatch(changingPasswordFailed());

        let addtlDetails = '';

        if (error.data && error.data.detail) {
          addtlDetails = ': ' + error.data.detail;
        }

        if (error && error.status !== 401) {
          Notification.flash(
            `Password change failed`,
            'error',
            `An error occurred when changing your password${addtlDetails}`
          );
        }

        return Promise.reject(error);
      });
  };
