import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import { genericCollectionSlice } from 'helpers/state/generic-collection.slice';

export const useGenericCollection = (singularSliceName) => {
  const dispatch = useDispatch();
  const { api } = genericCollectionSlice(singularSliceName);

  const boundActions = bindActionCreators(
    {
      load: api.load,
      loadIfMissing: api.loadIfMissing,
      createOrUpdate: api.createOrUpdate,
      find: api.find,
      deleteRecord: api.deleteRecord,
    },
    dispatch
  );

  return {
    load: (args) => boundActions.load(args),
    loadIfMissing: (args) => boundActions.loadIfMissing(args),
    createOrUpdate: (data) => boundActions.createOrUpdate(data),
    update: (id, data) => boundActions.createOrUpdate({ id, ...data }),
    find: (id) => boundActions.find(id),
    deleteRecord: (data) => boundActions.deleteRecord(data),
  };
};
