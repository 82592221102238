import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import uniqid from 'uniqid';

import { CONTENT_TYPES } from './content-types.constants';

const RelatedContent = ({ content = [] }) => {
  const getIcon = (contentType) => {
    const item = CONTENT_TYPES.find(({ type }) => type === contentType);

    return item?.icon;
  };

  return (
    <div className="mobile:mb-6 xs:mb-8">
      <div className="font-semibold text-xl">Related content</div>
      {content.map(({ id, attributes = {} }) => {
        const { name, contentIs } = attributes;

        return (
          <div key={uniqid()} className="mt-3">
            <RouterLink
              to={`/learn/content/${contentIs.toLowerCase()}s/${id}`}
              className="text-blue-02 hover:text-indigo-02 font-medium text-lg transition"
            >
              {name}
            </RouterLink>
            <div className="flex items-center text-sm text-gray-09">
              <div className="flex items-center">{getIcon(contentIs)}</div>
              <div className="ml-2 text-sm">{contentIs}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default RelatedContent;
