import { createSlice } from '@reduxjs/toolkit';

import { transformRecordFromServer } from 'helpers/server-data-transformers';

const reportsSlice = createSlice({
  name: 'reports',
  initialState: { data: null, loading: false, error: false },
  reducers: {
    loadingReports: (state) => {
      state.loading = true;
      state.error = false;
    },
    loadingReportsFailed: (state, { payload: error }) => {
      state.loading = false;
      state.error = error;
    },
    setReports: (state, { payload }) => {
      state.id = payload.id;

      state.data = transformRecordFromServer('report', payload);
      state.loading = false;
      state.error = false;
    },
  },
});

export const { setReports, loadingReportsFailed, loadingReports } =
  reportsSlice.actions;

export default reportsSlice.reducer;
