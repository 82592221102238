const NothingFound = ({ contentType = '', customClass = '', customTitle }) => {
  const NothingFoundMessage = customTitle || `There are no ${contentType} yet`;

  return (
    <div className={`text-3xl font-semibold ${customClass}`}>
      {NothingFoundMessage}
    </div>
  );
};

export default NothingFound;
