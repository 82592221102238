import uniqid from 'uniqid';

export const CLIENTS_STATUSES_FORM_FIELDS = [
  {
    label: 'All',
    labelFor: 'all',
    radioValue: 'all',
    valueForEdit: 'All',
    key: uniqid(),
  },
  {
    label: 'Active',
    labelFor: 'active',
    radioValue: 'active',
    valueForEdit: 'Active',
    key: uniqid(),
  },
  {
    label: 'Expiring soon',
    labelFor: 'expiring-soon',
    radioValue: 'expireSoon',
    valueForEdit: 'Expiring soon',
    key: uniqid(),
  },
  {
    label: 'Expired',
    labelFor: 'expired',
    radioValue: 'expired',
    valueForEdit: 'Expired',
    key: uniqid(),
  },
];
