import React, { createContext, useContext, useMemo } from 'react';
import { useForm } from 'react-hook-form';

const GoalFormContext = createContext();

export const useGoalForm = () => useContext(GoalFormContext);

export const GoalFormProvider = ({ children, defaultValues, loading }) => {
  const methods = useForm({
    defaultValues,
  });

  // Memoize the context value to prevent infinite loops
  const contextValue = useMemo(
    () => ({ ...methods, loading }),
    [methods, loading]
  );

  return (
    <GoalFormContext.Provider value={contextValue}>
      {children}
    </GoalFormContext.Provider>
  );
};
