import React, { useContext, useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useReadOnly } from 'hooks/read-only';

import CustomInput from 'components/partials/custom-text-input/custom-text-input';

import { UserProfileContext } from 'containers/admin/users/user/user-profile/user-profile';
import {
  PERSONAL_INFO_FORM_FIELDS,
  PERSONAL_INFO_FORM_ALWAYS_EDITABLE_FIELDS,
} from 'containers/admin/users/user/forms/personal-info-form/personal-info-form-fields.constants';

import classNames from 'classnames';

const PersonalInformationForm = ({
  isNotNewUser,
  isFormDisabled,
  isMVUser,
}) => {
  const { control, errors } = useContext(UserProfileContext);

  const [personalInfoFields, setPersonalInfoFields] = useState(
    PERSONAL_INFO_FORM_FIELDS
  );

  const readOnly = useReadOnly();

  const baseStyles = classNames('flex flex-col', {
    'w-full': isFormDisabled,
    'w-3/6 mr-16': !isFormDisabled && !isNotNewUser,
  });

  const filterFormFieldsByMode = (filterKey) =>
    PERSONAL_INFO_FORM_FIELDS.filter((field) => field.mode === filterKey);

  const calculateFormFieldDisability = (fields) =>
    fields.map((field) => {
      const disabled =
        !PERSONAL_INFO_FORM_ALWAYS_EDITABLE_FIELDS.has(field.name) && readOnly;

      return { ...field, disabled };
    });

  useEffect(() => {
    if (!isNotNewUser) {
      return;
    }

    const fields = calculateFormFieldDisability(
      filterFormFieldsByMode('CREATE_AND_EDIT_MODE')
    );

    setPersonalInfoFields(fields);
  }, [isNotNewUser]);

  return (
    <div className={baseStyles}>
      {personalInfoFields.map(
        ({ label, name, key, renderComponentProps, disabled }) => (
          <Controller
            key={key}
            name={name}
            control={control}
            render={({ field }) => (
              <CustomInput
                customClass="w-36"
                errorsNames={errors[name]}
                label={label}
                labelFor={name}
                labelInTop
                inputWrapperCustomClass={'bg-gray-06'}
                disabled={isFormDisabled || disabled}
                disabledClassName={(isFormDisabled || readOnly) && 'bg-gray-06'}
                required={
                  isMVUser ? !isFormDisabled && !disabled : !isFormDisabled
                }
                {...field}
                {...renderComponentProps}
              />
            )}
          />
        )
      )}
    </div>
  );
};

export default PersonalInformationForm;
