import React from 'react';
import uniqid from 'uniqid';

import AccordionItem from 'components/partials/accordion/accordion-item/accordion-item';

const Accordion = ({ items, customClass = '' }) => (
  <div role="list" className={`flex flex-col gap-2 ${customClass}`}>
    {items?.map((item, index) => (
      <AccordionItem
        key={uniqid()}
        index={index}
        name={item.name}
        description={item.description}
        role="list-item"
      />
    ))}
  </div>
);

export default Accordion;
