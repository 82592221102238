import {
  PAGE_CHANGE,
  SEARCH_CHANGE,
  FILTER_CHANGE,
} from 'containers/admin/actions';

export const usersReducer = (state, action) => {
  switch (action.type) {
    case PAGE_CHANGE:
      return {
        ...state,
        pageIndex: action.payload,
      };

    case SEARCH_CHANGE:
      return {
        ...state,
        search: action.payload,
        pageIndex: 0,
      };

    case FILTER_CHANGE:
      return {
        ...state,
        pageIndex: 0,
      };

    default:
      return state;
  }
};
