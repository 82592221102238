import React from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

import { renderGoal } from 'containers/admin/clients/client/tabs-components/goals-tab/goals-matrix/goals-matrix-cell/goals-matrix-cell';

const GoalsSidebarItem = ({
  goal,
  handleGoalSelect,
  selectedGoal,
  isNew,
  disabled,
}) => {
  const { clientId, goalType, goalCategory, year } = useParams();
  const goalLink =
    goal &&
    `/admin/clients/${clientId}/goals/${year}/${goalType}/${goalCategory}/${goal.id}`;

  const baseClassName = `
    flex items-center text-left flex-wrap break-words w-full p-2.5 font-normal 
    text-gray-04 group
    hover:text-blue-03 hover:font-medium 
    aria-selected:text-indigo-02 aria-selected:bg-gray-00 aria-selected:font-semibold 
    aria-selected:border-l-4 aria-selected:border-yellow-03
  `;

  const newGoalClassName = `${baseClassName} cursor-default`;

  const existingGoalClassName = `
    ${baseClassName} 
    disabled:cursor-not-allowed disabled:opacity-50 
    disabled:hover:text-gray-04 disabled:hover:font-normal
  `;

  return isNew ? (
    <button className={newGoalClassName} aria-selected={true}>
      New...
    </button>
  ) : (
    <button
      className={existingGoalClassName}
      aria-selected={selectedGoal === goal.id}
      onClick={(e) => handleGoalSelect({ event: e, to: goalLink, id: goal.id })}
      disabled={disabled}
    >
      {renderGoal(goal)}
    </button>
  );
};

export default GoalsSidebarItem;
