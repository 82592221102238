import React, { forwardRef } from 'react';
import classNames from 'classnames';

const CustomRadioInput = forwardRef(
  (
    {
      labelFor,
      label,
      radioValue,
      valueForEdit,
      setAction,
      onChange,
      customClass,
      labelClass,
      ...rest
    },
    ref
  ) => {
    const labelClasses = classNames(
      'flex items-center mb-3 mr-2 md:mr-4 text-gray-04 cursor-pointer',
      customClass
    );

    const inputClasses = classNames(
      'peer w-2/3 md:w-4 h-4 mr-2 accent-indigo-02 cursor-pointer'
    );

    const labelTextClasses = classNames(
      'peer-checked:font-semibold text-gray-03 text-sm md:text-base',
      labelClass
    );

    const handleChange = () => {
      setAction(radioValue);
      onChange(radioValue);
    };

    return (
      <label htmlFor={labelFor} className={labelClasses}>
        <input
          className={inputClasses}
          onChange={handleChange}
          type="radio"
          value={radioValue}
          id={labelFor}
          checked={radioValue === rest.value || valueForEdit === rest.value}
          ref={ref}
          {...rest}
        />
        <span className={labelTextClasses}>{label}</span>
      </label>
    );
  }
);

export default CustomRadioInput;
