import { createSlice } from '@reduxjs/toolkit';

import api from 'api';

import { transformRecordFromServer } from 'helpers/server-data-transformers';

import Notification from 'components/partials/notification/notification.helper';

export const genericCollectionSlice = (
  singularSliceName,
  extraReducers = {}
) => {
  const singularSliceTitle = `${singularSliceName[0].toUpperCase()}${singularSliceName.substring(
    1
  )}`;

  let pluralSliceName = singularSliceName + 's';
  const pluralSliceTitle = singularSliceTitle + 's';
  const reducers = {};

  const actionTypes = {
    loading: `loading${pluralSliceTitle}`,
    saving: `saving${singularSliceTitle}`,
    finding: `finding${singularSliceTitle}`,
    deleting: `deleting${singularSliceTitle}`,
  };

  /* Err format from server:
    {
        "status": 400,
        "type": "https://powerfields.io/problem/problem-with-message",
        "path": "/api/form-sections",
        "message": "client group 123 does not exist or you do not have permissions to view it"
    }
  */

  /** 
    Add a loading and an error state for every actionType.

    E.g if slice is 'clients', we'll add these actions:
      loadingClients, loadingClientsFailed, 
      savingClient, savingClientFailed, 
      findingClient, findingClientFailed, 
      deletingClient, deletingClientFailed
  */
  Object.values(actionTypes).forEach((actionInProgress) => {
    const actionFailure = `${actionInProgress}Error`; // e.g. loadingClientsError

    reducers[actionInProgress] = (state) => {
      state[actionInProgress] = true;
      state[actionFailure] = false;
    };

    reducers[actionFailure] = (state, { payload: error }) => {
      state[actionInProgress] = false;
      state[actionFailure] = error;
    };
  });

  // Set the whole collection
  reducers[`set${pluralSliceTitle}`] = (
    state,
    { payload: { data, pagination } }
  ) => {
    state.data = data.map((record) =>
      transformRecordFromServer(singularSliceName, record)
    );

    state.pageCount = pagination.pageCount;
    state.totalRecords = pagination.totalRecords;

    state[actionTypes.loading] = false;
    state[`${actionTypes.loading}Error`] = false;
  };

  // Add or update a record in the collection
  reducers[`add${singularSliceTitle}`] = (state, { payload: newRecord }) => {
    if (newRecord) {
      const existingRecordIx = state.data.findIndex(
        (record) => record.id.toString() === newRecord.id.toString()
      );

      newRecord = transformRecordFromServer(singularSliceName, newRecord);

      if (existingRecordIx >= 0) {
        // update the state
        state.data[existingRecordIx] = newRecord;
      } else {
        state.data.push(newRecord);
      }
    }

    state[actionTypes.finding] = false;
    state[actionTypes.saving] = false;
    state[`${actionTypes.finding}Error`] = false;
    state[`${actionTypes.saving}Error`] = false;
  };
  // initializes all the loading/failure states for the CRUD actions to false
  const initialActionState = Object.values(actionTypes).reduce(
    (initState, loadingAction) => {
      initState[loadingAction] = false;
      initState[`${loadingAction}Error`] = false;

      return initState;
    },
    {}
  );

  const slice = createSlice({
    name: pluralSliceName,
    initialState: {
      data: [],
      pageCount: 0,
      ...initialActionState,
    },
    reducers: reducers,
    extraReducers: extraReducers,
  });

  const {
    [`set${pluralSliceTitle}`]: set,
    [`add${singularSliceTitle}`]: add,

    // Error and Loading states for the actions: load all, find, save, delete
    [`loading${pluralSliceTitle}`]: loading,
    [`loading${pluralSliceTitle}Error`]: loadingError,

    [`finding${singularSliceTitle}`]: finding,
    [`finding${singularSliceTitle}Error`]: findingError,

    [`saving${singularSliceTitle}`]: saving,
    [`saving${singularSliceTitle}Error`]: savingError,

    [`deleting${singularSliceTitle}`]: deleting,
    [`deleting${singularSliceTitle}Error`]: deletingError,
  } = slice.actions;

  const collection = {};

  collection.api = {
    createOrUpdate: (data) => (dispatch, getState) => {
      dispatch(saving());

      return api
        .createOrUpdate(pluralSliceName, data)
        .then((response) => {
          dispatch(add(response.data));

          return {
            data: transformRecordFromServer(singularSliceName, response.data),
          };
        })
        .catch((error) => {
          dispatch(savingError(error));

          if (error && error.status !== 401) {
            Notification.flash(
              `Error saving ${singularSliceName}`,
              'error',
              `An error occurred when saving this ${singularSliceName}. (${
                error.status
              } - ${error.data && (error.data.detail || error.data.title)})`
            );
          }

          return Promise.reject(error);
        });
    },

    deleteRecord: (data) => (dispatch, getState) => {
      dispatch(deleting());

      return api
        .deleteRecord(pluralSliceName, data)
        .then((response) => {
          Notification.flash(
            `${singularSliceTitle} deleted`,
            'info',
            `You've successfully deleted this ${singularSliceName}`
          );
        })
        .catch((error) => {
          dispatch(deletingError(error));
          if (error && error.status === 405) {
            Notification.flash(
              `Client not deleted`,
              'error',
              `Cannot delete a client with users`
            );
          } else {
            Notification.flash(
              `Error deleting ${singularSliceName}`,
              'error',
              `An error occurred when deleting this ${singularSliceName}. (${
                error.status
              } - ${error.data && (error.data.detail || error.data.title)})`
            );
          }

          return Promise.reject(error);
        });
    },

    loadIfMissing: (props) => (dispatch, getState) => {
      const state = getState();
      const stateSlice = state[pluralSliceName];

      if (
        stateSlice[actionTypes.loading] ||
        (stateSlice.data && stateSlice.data.length > 0)
      ) {
        return Promise.resolve(stateSlice);
      } else {
        return dispatch(collection.api.load(props));
      }
    },

    load:
      ({
        pageSize = 10,
        pageIndex = 0,
        sortBy = [],
        searchTerms = '',
        filters = {},
        headers = {},
        forceSearch = false,
      } = {}) =>
      (dispatch, getState) => {
        const state = getState();
        const stateSlice = state[pluralSliceName];

        if (stateSlice[actionTypes.loading]) return Promise.resolve(stateSlice);

        dispatch(loading());

        return api
          .getCollection(pluralSliceName, {
            pageSize,
            pageIndex,
            sortBy,
            filters,
            searchTerms,
            headers,
            forceSearch,
          })
          .then(({ data, pagination }) => {
            // Added this to bypass the processing when we get an unmappable response back
            if (!Array.isArray(data)) return data;

            data = data.map((record) =>
              transformRecordFromServer(singularSliceName, record)
            );

            dispatch(set({ data, pagination }));

            return { data, pagination };
          })
          .catch((error) => {
            dispatch(loadingError({ error }));

            // if (error && error.status !== 401) {
            //   Notification.flash(
            //     `Error loading ${pluralSliceName}`,'error',
            //     `An error occurred when loading ${pluralSliceName}. (${
            //       error && error.status
            //     } - ${error.data && error.data.title})`,
            //   );
            // }

            return Promise.reject(error);
          });
      },

    find: (id) => (dispatch, getState) => {
      dispatch(finding());

      // If we already have this record, just return it
      const state = getState();
      const stateSlice = state[pluralSliceName];

      if (stateSlice && stateSlice.data) {
        let alreadyLoadedRecord = stateSlice.data.find(
          (record) => record.id.toString() === id.toString()
        );

        if (alreadyLoadedRecord) {
          dispatch(add(alreadyLoadedRecord));

          return Promise.resolve({ data: alreadyLoadedRecord });
        }
      }

      return api
        .find(pluralSliceName, id)
        .then((response) => {
          const data = transformRecordFromServer(
            singularSliceName,
            response.data
          );

          dispatch(add(data));

          return { data: data };
        })
        .catch((error) => {
          dispatch(findingError(error));

          if (error && error.status !== 401) {
            Notification.flash(
              `Error loading ${singularSliceName}`,
              'error',
              `An error occurred when loading this ${singularSliceName}. (${
                error.status
              } - ${error.data && error.data.title})`
            );
          }

          return Promise.reject(error);
        });
    },
  };

  collection.slice = slice;

  return collection;
};
