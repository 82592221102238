import React, { useEffect, useState } from 'react';

import NotificationMessage from 'components/partials/notification/notification';

import Notification from 'components/partials/notification/notification.helper';

import useTimeout from 'hooks/use-timeout';

const FlashNotification = ({ customClass = '', children }) => {
  const [{ heading, message, variant }, setNotification] = useState(
    Notification.notification
  );

  const removeNotification = () => {
    setNotification({});
    Notification.clear();
  };
  const checkForNewMessages = () => {
    setNotification(Notification.notification);
    reset();
  };
  const { reset } = useTimeout(checkForNewMessages, 100);

  useEffect(() => {
    if (!!heading) {
      setTimeout(() => removeNotification(), 5000);
    }
  }, [heading]);

  useEffect(
    () => () => {
      Notification.clear();
    },
    []
  );

  return heading ? (
    <NotificationMessage
      {...{
        heading,
        message,
        variant,
        removeNotification,
        customClass,
        children,
      }}
    />
  ) : null;
};

export default FlashNotification;
