import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';

import Icons from 'assets/icons';

const Dropdown = ({ children, title }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const dropdownRef = useRef(null);
  const toggleButtonRef = useRef(null);

  const handleClickOutside = (e) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target) &&
      toggleButtonRef.current !== e.target
    ) {
      setIsOpen(false);
    }
  };

  const handleDropdownItemClick = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="relative w-full">
      <button
        ref={toggleButtonRef}
        onClick={toggleDropdown}
        className="px-4 py-2 rounded-md border border-gray-05 w-full flex justify-between items-center transition"
      >
        <span className="text-blue-03 font-semibold">{title}</span>
        <Icons.Arrow
          className={classNames('rotate-90 transition shrink-0 text-blue-03', {
            '!-rotate-90': isOpen,
          })}
        />
      </button>
      {isOpen && (
        <div
          ref={dropdownRef}
          onClick={handleDropdownItemClick}
          className="absolute left-0 py-2 bg-white border rounded-md shadow !w-full z-20 max-h-[200px] overflow-y-scroll custom-scroll"
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
