import React, { useContext } from 'react';
import { HeaderContext } from 'components/shared/others/header/header';

import Icons from 'assets/icons';

export const WelcomeUser = ({ isAdmin, user, menuItemsLength }) => {
  const { learnSectionItemsCount, isMobileMenu } = useContext(HeaderContext);

  const menuItemHeight = 50;
  const learnSectionItemHeight = 32;

  const calculatedHeight =
    menuItemsLength * menuItemHeight +
    learnSectionItemHeight * learnSectionItemsCount +
    menuItemHeight;

  const getAdditionalStyles = () => {
    // styles for Admin menu
    if (isAdmin) {
      return 'mobile:top-[195.4px] tablet:top-[202.4px]';
    }
  };

  const basicStyles = `grid mobile:absolute ${getAdditionalStyles()} tablet:col-start-2 mobile:self-start mobile:pl-5 mobile:pt-3 xs:relative xs:top-0 xs:pt-0 xs:border-none xs:self-center xs:justify-self-end xs:col-start-3 mr-10`;

  return (
    <div
      className={basicStyles}
      style={{ top: isMobileMenu ? calculatedHeight : '' }}
    >
      <span className="mobile:justify-self-start xs:justify-self-end text-indigo-02 font-family font-regular font-black text-end">
        {isAdmin ? 'System Administration' : user?.data?.client?.name}
      </span>
      <div className="grid grid-cols-5x95 mobile:self-center xs:justify-self-end">
        <Icons.Profile customClass="mr-2 h-6 w-6 text-gray-06" />
        <span className="self-center text-gray-02 font-xs uppercase tracking-[1.2px]">
          Welcome, {user?.data?.firstName}
        </span>
      </div>
    </div>
  );
};

export default WelcomeUser;
