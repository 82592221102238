import React from 'react';
import classNames from 'classnames';

import Icons from 'assets/icons';

import { schema } from 'containers/admin/clients/client/tabs-components/goals-tab/goals-configuration/goals-configuration';
import {
  formatNumberWithSuffix,
  formatWithThousandSeparator,
} from 'components/partials/formatted-input/formatted-input.helpers';

import { TYPES_WITHOUT_SUFFIXES } from 'containers/admin/clients/client/tabs-components/goals-tab/goals-tab.constants';

const relativeToLastYearSymbols = {
  Increase: <Icons.ArrowUp />,
  Decrease: <Icons.ArrowDown />,
  Maintain: <Icons.Maintain />,
};

const relativeToLastYearClasses = {
  Increase: 'text-success-06',
  Decrease: 'text-red-04',
  Maintain: 'text-gray-04',
};

const subFieldsClasses =
  'bg-gray-06 group-aria-selected:bg-navy-01 py-1 px-1.5 rounded';

const getOptionsText = (options) => options.join(', ');

const renderIcon = (goal) => (
  <span
    aria-label={goal.relativeToLastYear}
    className={relativeToLastYearClasses[goal.relativeToLastYear]}
  >
    {relativeToLastYearSymbols[goal.relativeToLastYear]}
  </span>
);

const renderSubType = (goal) => {
  if (!goal.subType) return null;

  return <span className={subFieldsClasses}>{goal.subType}</span>;
};

const renderSubArea = (goalDetails) => {
  if (!goalDetails.subArea) return null;

  return <span className={subFieldsClasses}>{goalDetails.subArea}</span>;
};

const getRuleContent = (rule, goal) => {
  const categoryContentMap = {
    Topline: rule.focusArea,
    Other: goal.name,
  };

  const key = goal.category === 'Combined' ? rule.category : goal.category;

  return categoryContentMap[key] || getOptionsText(rule.options);
};

const renderDetails = (rule, goal) => (
  <span className="break-words">{getRuleContent(rule, goal)}</span>
);

const renderValue = ({ value, valueType, goalType }) => {
  const TYPES = {
    PERCENT: { prefix: null, suffix: '%' },
    DOLLAR: { prefix: '$', suffix: null },
    NUMBER: { prefix: null, suffix: null },
  };

  const valuePrefix = TYPES[valueType]?.prefix || '';
  const valueSuffix = TYPES[valueType]?.suffix || '';

  const parsedValue = TYPES_WITHOUT_SUFFIXES.includes(goalType)
    ? formatWithThousandSeparator(value)
    : formatNumberWithSuffix(value);

  return `${valuePrefix}${parsedValue}${valueSuffix}`;
};

export const renderGoal = (goal) => {
  const valueType =
    schema[goal.type]?.subType?.find((sub) => sub.value === goal.subType)
      ?.valueType || schema[goal.type]?.valueType;

  return (
    <div className="flex flex-wrap">
      <div className="flex flex-wrap items-center gap-1 break-words">
        {goal.category !== 'Other' && (
          <>
            <span
              className={relativeToLastYearClasses[goal.relativeToLastYear]}
            >
              {renderValue({
                value: goal.value,
                valueType,
                goalType: goal.type,
              })}
            </span>
            {renderIcon(goal)}
            {renderSubType(goal)}
          </>
        )}

        {goal.details.map((item, detailIndex) => (
          <React.Fragment key={item.id}>
            {renderSubArea(item)}
            <div className="flex flex-wrap items-center">
              {renderDetails(item, goal)}
            </div>
            {detailIndex !== goal.details.length - 1 && (
              <Icons.PlusInCircle
                className="h-4 w-4 text-gray-04"
                aria-hidden="true"
              />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

const renderCellData = (goal) => (
  <div
    key={goal.id}
    className="flex flex-wrap items-start font-semibold break-words text-gray-04"
  >
    <div className="flex items-baseline">
      {goal.category === 'Other' && <span className="mr-2">&bull;</span>}
      {renderGoal(goal)}
    </div>

    <Icons.EditPencil
      className="absolute top-2 right-3 opacity-0 group-hover:opacity-100 group-focus-visible:opacity-100 text-yellow-04 transition-all h-5 w-5"
      aria-label="Edit"
    />
  </div>
);

const GridMatrixCell = ({ cellData, onClick, row, header }) => (
  <button
    className={classNames(
      'relative flex flex-col group p-2 pr-8 space-y-1 w-full h-full focus-visible:outline text-left',
      { 'justify-start': cellData.length, 'justify-center': !cellData.length }
    )}
    onClick={() =>
      cellData.length
        ? onClick(cellData[0])
        : onClick({ category: row, type: header })
    }
  >
    {cellData.length ? (
      cellData.map((goal) => renderCellData(goal))
    ) : (
      <div className="opacity-0 group-hover:opacity-100 group-focus-visible:opacity-100 mx-auto transition-all sm:text-xs md:text-sm bg-yellow-03 px-3 py-1.5  rounded-md flex items-center justify-center">
        <Icons.Plus
          className="mr-1 text-indigo-02 h-3 w-3"
          aria-hidden="true"
        />
        <span className="text-sm font-semibold text-indigo-02">Add a goal</span>
      </div>
    )}
  </button>
);

export default GridMatrixCell;
