import { useForm, Controller } from 'react-hook-form';
import CustomRadioInput from 'components/partials/custom-radio-input/custom-radio-input';
import { CLIENTS_STATUSES_FORM_FIELDS as statuses } from 'containers/admin/clients/client/forms/filter-status-form/filter-status-form.constants';

const FilterStatusForm = ({ status, onClick }) => {
  const { control } = useForm({
    mode: 'onChange',
  });

  return (
    <div className="absolute flex items-center py-1.5">
      <label
        htmlFor="clients-statuses"
        className="w-28 md:w-32 mr-2 md:mr-4 font-semibold text-sm md:text-base"
      >
        Filter by Status
      </label>

      <div className="flex items-center">
        <Controller
          id="clients-statuses"
          name="status"
          control={control}
          render={({ field }) =>
            statuses.map(
              ({ label, labelFor, valueForEdit, key, radioValue }) => (
                <CustomRadioInput
                  label={label}
                  labelFor={labelFor}
                  radioValue={radioValue}
                  valueForEdit={valueForEdit}
                  setAction={onClick}
                  key={key}
                  customClass="!mb-0"
                  {...field}
                  value={status}
                />
              )
            )
          }
        />
      </div>
    </div>
  );
};

export default FilterStatusForm;
