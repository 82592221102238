export const getBreadcrumbsForFile = (currentClient, fileData) => {
  const baseBreadcrumbs = [
    { label: 'Clients', href: '/admin/clients' },
    {
      label: currentClient?.name,
      href: `/admin/clients/${currentClient?.id}`,
    },
    {
      label: 'Files',
      href: `/admin/clients/${currentClient?.id}/files`,
    },
  ];

  baseBreadcrumbs.push({
    label: fileData?.name || 'New file',
    isActive: true,
  });

  return baseBreadcrumbs;
};
