import React from 'react';
import uniqid from 'uniqid';
import { Link as RouterLink } from 'react-router-dom';
import classNames from 'classnames';

import ResponsiveWrapper from 'components/shared/others/responsive-wrapper/responsive-wrapper';
import SeeAllButton from 'containers/user/learn/components/see-all-button/see-all-button';
import HorizontalProgressBar from 'containers/user/learn/components/horizontal-progress-bar/horizontal-progress-bar';
import CourseControlButtons from 'containers/user/learn/containers/content/cources/course-controls/course-controls';
import Progress from 'containers/user/learn/components/progress/progress';

import Icons from 'assets/icons';

import { COURSE_STATUSES } from 'containers/user/learn/containers/content/cources/course.constants';

const CoursesCards = ({
  userId,
  items = [],
  title = '',
  subtitle = '',
  showSeeAllButton = false,
}) => (
  <ResponsiveWrapper
    md="1120"
    lg="1120"
    customClass={classNames({ 'mobile:mt-12 xs:mt-20': showSeeAllButton })}
  >
    <div className="text-indigo-02 mb-6">
      <div className="text-4xl font-semibold mb-1">{title}</div>
      <div className="font-semibold text-lg">{subtitle}</div>
    </div>
    <div className="grid mobile:grid-cols-1 tablet:grid-cols-2 xs:grid-cols-3 md:grid-cols-3 gap-8">
      {items.map((item) => {
        const {
          name,
          coverDescription,
          route,
          coverImage,
          averageProgress,
          status,
        } = item;
        const isNew = status === COURSE_STATUSES.UNKNOWN;

        return (
          <div
            key={uniqid()}
            className="border border-gray-05 rounded mobile:w-[335px] tablet:w-[338px] xs:w-[276px] md:w-[357px]"
          >
            {coverImage ? (
              <img
                src={coverImage}
                alt={name}
                className="mobile:w-full mobile:h-[240px] xs:h-[200px] rounded-tl rounded-tr"
              />
            ) : (
              <div className="mobile:w-full mobile:h-[240px] xs:h-[200px] tablet:bg-bottom categories-bg rounded-tl rounded-tr"></div>
            )}

            <div className="p-5 flex flex-col justify-between">
              <div className="mb-5">
                <div
                  className="text-neutral-900 text-xl font-semibold py-2 whitespace-nowrap overflow-ellipsis overflow-hidden"
                  title={name}
                >
                  {name}
                </div>
                <div className="text-neutral-900 text-base mb-4">
                  {coverDescription}
                </div>
                <RouterLink
                  to={route}
                  className="flex font-semibold items-center gap-1 hover:gap-2 text-blue-02 hover:text-indigo-02 w-fit transition-all"
                >
                  <span className="text-sm">Course overview</span>
                  <Icons.Arrow customClass="h-4 w-4" />
                </RouterLink>
              </div>
              <div>
                <HorizontalProgressBar
                  progress={averageProgress}
                  showProgress={!isNew}
                />
                <div className="flex items-center justify-between w-100 mt-3">
                  <div>
                    {!isNew && <Progress averageProgress={averageProgress} />}
                  </div>
                  <CourseControlButtons userId={userId} course={item} />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
    {showSeeAllButton && (
      <SeeAllButton
        route="/learn/courses"
        contentType="courses"
        customClass="self-start"
      />
    )}
  </ResponsiveWrapper>
);

export default CoursesCards;
