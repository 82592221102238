import React, { forwardRef, useState, useEffect } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import classNames from 'classnames';
import uniqid from 'uniqid';

import { isPrimitive } from 'helpers/forms';

import Icons from 'assets/icons';

const getBasicSelectStyles = (color, disabledColor, extraBasicStyles) => ({
  control: (styles, { isDisabled }) => ({
    ...styles,
    boxShadow: 'none',
    borderColor: color,
    outline: 'none',
    padding: '4px 0',
    backgroundColor: isDisabled ? disabledColor : 'transparent',
    borderRadius: 6,
    '&:hover': {
      borderColor: color,
    },
    ...extraBasicStyles,
  }),
  singleValue: (styles) => ({
    ...styles,
    color: 'inherit',
  }),
});

const multiSelectOptionStyles = {
  multiValue: (styles) => ({
    ...styles,
    display: 'flex',
    backgroundColor: 'white',
    border: '1px solid #C0CBD8',
    padding: 0,
    margin: '0px 8px 0px 0px',
    borderRadius: '6px',
    fontSize: 18,
  }),
  multiValueRemove: (styles, { isDisabled }) => ({
    ...styles,
    color: '#002D5D',
    display: isDisabled ? 'none' : 'flex',
    ':hover': {
      cursor: 'pointer',
    },
  }),
};

export const CustomSelect = forwardRef(
  (
    {
      label,
      labelFor = uniqid(),
      fieldError = {},
      customClass = '',
      customSelectClass = 'w-full',
      customSelectWrapperClass = '',
      isSearchable = true,
      isOneRowLable = true,
      helperText = '',
      options,
      isMulti = false,
      onChange,
      value: _value,
      errors,
      clearInputErr,
      disabled,
      disabledBgColor = '#F2F2F2',
      labelInTop,
      isCreateble,
      extraStyles = () => {},
      extraBasicStyles = {},
      required,
      placeholder = 'Select...',
      isClearable = false,
      ...rest
    },
    ref
  ) => {
    const [borderColor, setBorderColor] = useState('#C0CBD8');
    const [isNotValid, setIsNotValid] = useState(false);

    const [inputValue, setInputValue] = useState('');
    const [value, setValue] = useState(_value || []);

    useEffect(() => {
      if (_value && isPrimitive(_value)) {
        const selected = options.find(
          (option) => option.value === _value || option.label === _value
        );

        setValue(selected);
      } else {
        setValue(_value);
      }
    }, [_value, options]);

    const handleChange = (value) => {
      isNotValid && clearInputErr();
      setValue(value || []);
      onChange(value);
    };

    const handleKeyDown = (event) => {
      if (!inputValue) return;

      switch (event.keyCode) {
        case 9: // Tab
        case 13: // Enter
        case 32: // Space
          createValueFromInput(event);
          break;
        default:
      }
    };

    const createValueFromInput = (event) => {
      if (inputValue && inputValue.length > 0) {
        const newValue = [
          ...value,
          {
            label: inputValue,
            value: inputValue,
          },
        ];

        setInputValue('');
        setValue(newValue);
        onChange(newValue);
        event.preventDefault();
      }
    };

    const labelStyles = classNames('font-semibold mt-3 mb-1', customClass, {
      'mb-5': !labelInTop,
      'h-16': !disabled && helperText && !isOneRowLable,
      'h-11': !disabled && helperText && isOneRowLable && !labelInTop,
      'flex h-6': isOneRowLable,
    });

    useEffect(() => {
      const isError = !!Object.keys(fieldError).length;

      setBorderColor(isError ? '#D91655' : '#C0CBD8');
      setIsNotValid(isError);
    }, [fieldError]);

    const CurrentSelect = isCreateble ? CreatableSelect : Select;

    const formatCreateLabel = (inputValue) => `Use "${inputValue}"`;
    const errorMessage = fieldError && fieldError?.message;

    return (
      <div
        className={classNames('flex flex-col w-full', customSelectWrapperClass)}
      >
        <div
          className={classNames('flex', {
            'flex-col items-start': labelInTop,
            'items-center': !labelInTop,
          })}
        >
          {label && (
            <label htmlFor={labelFor} className={labelStyles}>
              {label}
              {required ? (
                <Icons.RequiredIndicator customClass="w-[7px] h-[7px] ml-1 mt-1" />
              ) : null}
            </label>
          )}
          <div className={`flex flex-col ${customSelectClass}`}>
            {isMulti && !options ? (
              <CreatableSelect
                id={labelFor}
                styles={{
                  ...getBasicSelectStyles(
                    borderColor,
                    disabledBgColor,
                    extraBasicStyles
                  ),
                  ...multiSelectOptionStyles,
                }}
                ref={ref}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                placeholder="Type..."
                inputValue={inputValue}
                isClearable={isClearable}
                isMulti
                menuIsOpen={false}
                onChange={(value) => handleChange(value)}
                onBlur={(event) => createValueFromInput(event)}
                onInputChange={(inputValue) => {
                  isNotValid && clearInputErr();
                  setInputValue(inputValue);
                }}
                onKeyDown={handleKeyDown}
                value={value}
                aria-labelledby={labelFor}
                isDisabled={disabled}
                {...rest}
              />
            ) : isMulti && options ? (
              <CurrentSelect
                isMulti
                id={labelFor}
                styles={{
                  ...getBasicSelectStyles(
                    borderColor,
                    disabledBgColor,
                    extraBasicStyles
                  ),
                  ...multiSelectOptionStyles,
                }}
                ref={ref}
                isSearchable={isSearchable}
                options={options}
                value={value}
                onChange={(value) => handleChange(value)}
                components={{
                  IndicatorSeparator: () => null,
                }}
                aria-labelledby={labelFor}
                placeholder={disabled ? 'Not selected' : placeholder}
                isDisabled={disabled}
                formatCreateLabel={formatCreateLabel}
                isClearable={isClearable}
                {...rest}
              />
            ) : (
              <CurrentSelect
                id={labelFor}
                styles={{
                  ...getBasicSelectStyles(
                    borderColor,
                    disabledBgColor,
                    extraBasicStyles
                  ),
                  ...extraStyles(borderColor, disabledBgColor),
                }}
                ref={ref}
                isSearchable={isSearchable}
                options={options}
                value={value}
                onChange={(value) => handleChange(value)}
                components={{
                  IndicatorSeparator: () => null,
                }}
                aria-labelledby={labelFor}
                placeholder={disabled ? 'Not selected' : placeholder}
                isDisabled={disabled}
                formatCreateLabel={formatCreateLabel}
                isClearable={isClearable}
                {...rest}
              />
            )}
            {errorMessage && !disabled && (
              <span
                id={`${labelFor}-error`}
                className="font-sm pt-1 text-red-00 bg-white"
                aria-errormessage={errorMessage}
              >
                {errorMessage}
              </span>
            )}
            {helperText && !disabled && !errorMessage && (
              <span id={`${labelFor}-helper`} className="font-sm pt-1 bg-white">
                {helperText}
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default CustomSelect;
