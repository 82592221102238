import React, { useState } from 'react';
import { SecureRoute } from '@okta/okta-react';

import ClientProfile from 'containers/admin/clients/client/client-profile/client-profile';

import { transformClientToFormObject } from 'helpers/forms';

const ProfileTab = ({ client }) => {
  const [isFormDisabled, setIsFormActive] = useState(true);

  return (
    <SecureRoute
      exact
      path="/admin/clients/:clientId/profile"
      render={() => (
        <ClientProfile
          defaultValues={transformClientToFormObject(client)}
          showClientDeleteBtn
          isFormDisabled={isFormDisabled}
          setIsFormActive={(value) => setIsFormActive(value)}
        />
      )}
    />
  );
};

export default ProfileTab;
