import { Link as RouterLink } from 'react-router-dom';

import CustomButton from 'components/partials/custom-button/custom-button';

import { getIconByFileType } from 'components/partials/file-uploader/file-uploader.helper';
import { formatDate } from 'helpers/dates-formatter';
import { getStatusLabel } from 'components/partials/table-component/helpers/get-status-label';

import { FILES_STATUSES } from 'containers/admin/clients/client/tabs-components/files-tab/files-tab.constants';

import Icons from 'assets/icons';

const commonStyles = 'pl-4 border-r-2 border-white font-semibold';

export const getFilesTableSettings = (clientId) =>
  [
    {
      id: 'fileExt',
      accessorKey: 'fileExt',
      header: () => <span>FILE TYPE</span>,
      cell: (info) => getIconByFileType(info.row.original.fileExt),
      headerClassName: `${commonStyles} rounded-tl-md rounded-bl-md cursor-context-menu`,
      className: 'xs:w-[10%] sm:w-[9%] md:w-[7%] xl:w-[6%]',
      enableSorting: false,
    },
    {
      id: 'name',
      accessorKey: 'name',
      header: () => <span>FILE NAME</span>,
      cell: (info) => {
        const label = getStatusLabel(info.row.original.status, FILES_STATUSES);

        return (
          <div className="flex flex-col py-3">
            <span className="w-full pr-2 pb-3 font-semibold">
              {info.renderValue()}
            </span>
            {label}
          </div>
        );
      },
      headerClassName: `${commonStyles} cursor-pointer`,
      className: 'pl-4 xs:w-[19%] sm:w-[16%] md:w-[17%]',
      enableSorting: true,
      sortingFn: 'alphanumeric',
    },
    {
      id: 'description',
      accessorKey: 'description',
      header: () => <span>DESCRIPTION</span>,
      cell: (info) => <span>{info.renderValue()}</span>,
      headerClassName: `${commonStyles} cursor-context-menu`,
      className: 'pl-4 xs:w-[21%] sm:w-[20%] lg:w-[20%]',
      enableSorting: false,
    },
    {
      id: 'createdDate',
      accessorKey: 'createdDate',
      header: () => <span>UPLOAD DATE</span>,
      cell: (info) =>
        formatDate(info.row.original.createdDate, 'MMM D, YYYY h:mm A'),
      headerClassName: `${commonStyles} cursor-pointer`,
      className: 'pl-4 xs:w-[15%]',
      enableSorting: true,
    },
    {
      id: 'isAvailableTo',
      accessorKey: 'isAvailableTo',
      header: () => <span>FILE IS AVAILABLE TO</span>,
      cell: (info) => (
        <div>
          {info?.row?.original?.isAvailableTo
            ?.map((user) => `${user.firstName} ${user.lastName}`)
            .slice(0, 4)
            .join(', ')}
        </div>
      ),
      headerClassName: `${commonStyles} cursor-context-menu`,
      className: 'pl-4 pt-3 pb-3 xs:w-[19%] sm:w-[17%]',
      enableSorting: false,
    },
    {
      id: 'button-cell',
      cell: ({
        row: {
          original: { id },
        },
      }) => (
        <RouterLink to={`/admin/clients/${clientId}/files/${id}`}>
          <CustomButton
            variant="secondary"
            size="sm"
            iconBefore={<Icons.Eye alt="EyeIcon" height="100%" width="14" />}
            customClass="mr-2 text-xs md:text-sm"
          >
            View Details
          </CustomButton>
        </RouterLink>
      ),
      headerClassName: 'h-12 rounded-tr-md rounded-br-md pl-4',
      className:
        'text-center pl-4 xs:w-[16%] sm:w-[15%] md:w-[12%] lg:w-[10%] xl:w-[9%]',
    },
  ].filter((item) => item);
