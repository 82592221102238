import React, { forwardRef } from 'react';

const ResponsiveWrapper = forwardRef(
  (
    {
      mobile = '335',
      tablet = '700',
      xs = '880',
      md = '1200',
      lg = '1360',
      customClass = '',
      style,
      children,
    },
    ref
  ) => (
    <div
      ref={ref}
      className={`w-full mobile:max-w-[${mobile}px] tablet:max-w-[${tablet}px] xs:max-w-[${xs}px] md:max-w-[${md}px] lg:max-w-[${lg}px] ${customClass}`}
      style={style}
    >
      {children}
    </div>
  )
);

export default ResponsiveWrapper;
