import { createSlice } from '@reduxjs/toolkit';
import { original } from 'immer';

import { addUser } from 'state/users/users.slice';
import {
  impersonate,
  stopImpersonating,
} from 'state/impersonation/impersonation.slice';

import { transformRecordFromServer } from 'helpers/server-data-transformers';

const currentUserSlice = createSlice({
  name: 'user',
  initialState: {
    id: null,
    data: null,
    loading: false,
    error: false,
    filesCount: 0,
  },
  reducers: {
    loadingCurrentUser: (state) => {
      state.loading = true;
      state.error = false;
    },
    loadingCurrentUserFailed: (state, { payload: error }) => {
      state.loading = false;
      state.error = error;
    },
    setCurrentUser: (state, { payload }) => {
      state.id = payload.id;
      // Store this here for ease of access, but make sure to update this
      // whenever the user record is updated (see extraReducers)
      state.data = transformRecordFromServer('user', payload);
      state.loading = false;
      state.error = false;
    },
    changingPassword: (state) => {
      state.changingPassword = true;
      state.passwordChangeError = false;
    },
    changingPasswordFailed: (state, { payload: passwordChangeError }) => {
      state.changingPassword = false;
      state.passwordChangeError = passwordChangeError;
    },
    changingPasswordSucceeded: (state) => {
      state.changingPassword = false;
      state.passwordChangeError = false;
    },
    setNewFilesCountSucceeded: (state, { payload }) => {
      state.filesCount = payload?.count;
    },
  },
  extraReducers: {
    // Whenever a user is retrieved/updated, check to see if it's our current user,
    // and sync currentUser data if so.
    [addUser]: (state, { payload: newUser }) => {
      if (newUser.id === state.id) {
        // Some logic here to preserve the user>client>consultants
        // if they exist, as they do for currentUser.

        const oldData = original(state) && original(state).data;
        const newData = transformRecordFromServer('user', newUser);
        let newState;

        if (oldData) {
          const consultants =
            (newData.client && newData.client.consultants) ||
            (oldData.client && oldData.client.consultants);

          newState = {
            ...newData,
            client: newData.client && {
              ...newData.client,
              consultants: consultants,
            },
          };
        } else {
          newState = newData;
        }

        state.data = newState;
      }
    },
    [impersonate]: (state) => {
      state.data = null;
      state.id = null;
    },
    [stopImpersonating]: (state) => {
      state.data = null;
      state.id = null;
    },
  },
});

export const { actions } = currentUserSlice;

export default currentUserSlice.reducer;
