import React from 'react';
import uniqid from 'uniqid';

import Loader from 'components/partials/loader/loader';
import Breadcrumbs from 'components/partials/beadcrumbs/breadcrumbs';
import ResponsiveWrapper from 'components/shared/others/responsive-wrapper/responsive-wrapper';
import Accordion from 'components/partials/accordion/accordion';
import Heading from 'components/partials/heading/heading';

import useFetchCourse from 'containers/user/learn/containers/content/cources/use-fetch-course';
import CourseControlButtons from 'containers/user/learn/containers/content/cources/course-controls/course-controls';
import HorizontalProgressBar from 'containers/user/learn/components/horizontal-progress-bar/horizontal-progress-bar';
import Progress from 'containers/user/learn/components/progress/progress';

import { useCurrentUser } from 'state/current-user/current-user.thunk';

import { getBlocksFromContent } from 'containers/user/learn/heplers/get-blocks-from-content';
import { COURSE_STATUSES } from 'containers/user/learn/containers/content/cources/course.constants';

const CourseOverview = ({ courseId }) => {
  const user = useCurrentUser();

  const { loading, course } = useFetchCourse(user?.data?.oktaUserId, courseId);

  const isNew = course && course.status === COURSE_STATUSES.UNKNOWN;

  if (!loading && !course) {
    return (
      <div className="my-auto">
        <p className="text-4xl">
          Course data is not available at the moment. Please try again.
        </p>
      </div>
    );
  }

  return loading ? (
    <Loader {...{ loading }} aria-label="loading" />
  ) : (
    <ResponsiveWrapper md="1120" lg="1120" customClass="flex flex-col">
      <Breadcrumbs
        breadcrumbs={[
          { label: 'Learn', href: '/learn' },
          { label: 'Courses', href: '/learn/courses' },
        ]}
        customClass="!mt-5"
      />
      <Heading customClass="text-4xl font-semibold mobile:pt-4 xs:pt-6 mb-10">
        {course.name}
      </Heading>

      <HorizontalProgressBar
        progress={course.averageProgress}
        showProgress={!isNew}
        customClass="mb-3"
      />
      {!isNew && <Progress averageProgress={course.averageProgress} />}

      <div className="w-full mobile:my-5 xs:my-10">
        {course.content?.map((component) =>
          getBlocksFromContent({ ...component, key: uniqid() })
        )}
      </div>
      <Heading variant="h2" customClass="mb-3 !text-dark-01 font-semibold">
        Lessons
      </Heading>

      <Accordion
        customClass="mobile:mb-5 xs:mb-10"
        items={course.lessons?.data.map((item) => ({
          name: item.attributes.name,
          description: item.attributes.shortDescription,
        }))}
      />

      <CourseControlButtons
        userId={user?.data?.oktaUserId}
        course={course}
        size="md"
      />
    </ResponsiveWrapper>
  );
};

export default CourseOverview;
