import * as MARKETviewApi from 'api/market-view-api';
import * as StrapiApi from 'api/strapi-api';
import * as GoalsApi from 'api/goals-api';
//import * as StrapiApi from 'api/strapi-api.local'; Use this for local development

export default {
  ...MARKETviewApi,
  ...StrapiApi,
  ...GoalsApi,
};
