import Icons from 'assets/icons';

export const getIconByFileType = (type) => {
  const TYPES = {
    pdf: <Icons.PdfFile customClass="w-9 h-11 mx-auto my-3" />,
    xlsx: <Icons.XlsFile customClass="w-9 h-11 mx-auto my-3" />,
    pptx: <Icons.PptFile customClass="w-9 h-11 mx-auto my-3" />,
    docx: <Icons.DocFile customClass="w-9 h-11 mx-auto my-3" />,
  };

  return (
    TYPES[type] || <Icons.DefaultFile customClass="w-9 h-11 mx-auto my-3" />
  );
};

export const readebleFileSize = (size) => {
  const i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));

  return `${(size / Math.pow(1024, i)).toFixed(2) * 1} ${
    ['B', 'kB', 'MB', 'GB', 'TB'][i]
  }`;
};

export const getFileFormat = (fileName = '') =>
  fileName.split('.').pop().toLowerCase();
