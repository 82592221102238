import React, { useState, useEffect, useCallback, useReducer } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { SecureRoute } from '@okta/okta-react';

import CustomButton from 'components/partials/custom-button/custom-button';
import Heading from 'components/partials/heading/heading';
import Search from 'components/partials/custom-search/custom-search';
import TableComponent from 'components/partials/table-component/table-component';
import Loader from 'components/partials/loader/loader';
import EmptySpaceContent from 'components/partials/empty-space-content/empty-space-content';
import CustomCheckbox from 'components/partials/custom-checkbox/custom-checkbox';

import { getFilesTableSettings } from 'containers/admin/clients/client/tabs-components/files-tab/files-tab-table-settings';

import { useFiles } from 'state/files/files.thunk';
import { useCurrentUser } from 'state/current-user/current-user.thunk';

import { usersReducer } from 'containers/admin/reducer';
import {
  SEARCH_CHANGE,
  FILTER_CHANGE,
  PAGE_CHANGE,
} from 'containers/admin/actions';

import Icons from 'assets/icons';

import api from 'api';

const initialState = {
  search: '',
  pageIndex: 0,
};

const FilesTab = ({ clientId }) => {
  const { load } = useFiles();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [isErrorOccured, setIsErrorOccured] = useState(false);
  const [countArchivedFile, setCountArchivedFile] = useState();

  const [state, dispatch] = useReducer(usersReducer, initialState);
  const [statusFilter, setStatusFilter] = useState(['ACTIVE', 'HIDDEN']);

  const [{ pageSize, totalRecords }, setAmount] = useState({
    pageSize: 10,
    totalRecords: 0,
  });

  const [sortingParams, setSortingParams] = useState([
    { id: 'createdDate', desc: false },
  ]);

  const handleSearch = useCallback((payload) =>
    dispatch({ type: SEARCH_CHANGE, payload })
  );

  const handleFilter = useCallback((value) => {
    dispatch({ type: FILTER_CHANGE });
    setStatusFilter(value);
  });

  const handlePagination = useCallback((payload) =>
    dispatch({ type: PAGE_CHANGE, payload })
  );

  const currentUser = useCurrentUser();

  const columns = getFilesTableSettings(clientId);

  const { search, pageIndex } = state;

  const applyStatusFilter = () => {
    const statuses = ['ACTIVE', 'HIDDEN'];

    statusFilter.length < 3 && statuses.push('ARCHIVED');

    handleFilter(statuses);
  };

  //load check archived file data
  const loadCheckArchivedFile = (search) => {
    api
      .getCheckArchivedFile({
        userId: currentUser.data.oktaUserId,
        clientId,
        search,
      })
      .then((response) => {
        setCountArchivedFile(response.data);
      })
      .catch((error) =>
        console.error('Error during fetching file.FileDetails', error)
      );
  };

  useEffect(() => {
    setIsLoading(true);
    load({
      pageIndex,
      pageSize,
      sortBy: sortingParams,
      filters: {
        clientId,
        status: statusFilter,
        search: { value: search },
      },
    })
      .then((response) => {
        setData(response?.data ?? []);

        if (response?.pagination) {
          const { totalRecords: newTotalRecords } = response.pagination;

          setAmount({ pageSize, totalRecords: newTotalRecords });
        }

        loadCheckArchivedFile(search);

        setIsLoading(false);
      })
      .catch(() => {
        setIsErrorOccured(true);
        setIsLoading(false);
      });
  }, [pageIndex, pageSize, sortingParams, search, statusFilter]);

  return (
    <SecureRoute
      exact
      path="/admin/clients/:id/files"
      render={() => (
        <>
          <div className="flex justify-between items-center mt-4 mb-4">
            <Heading variant="h2">Uploaded files</Heading>

            {!currentUser.data.fdAnalyst && (
              <RouterLink to={`/admin/clients/${clientId}/new-file`}>
                <CustomButton
                  variant="primary"
                  size="lg"
                  iconBefore={<Icons.Upload alt="upload" />}
                >
                  Upload new file
                </CustomButton>
              </RouterLink>
            )}
          </div>

          <Search
            {...{ search, setSearch: handleSearch, customClass: 'mb-5' }}
          />

          <div className="relative">
            {!!countArchivedFile && (
              <CustomCheckbox
                {...{
                  label: 'Show archived files',
                  labelFor: 'statusFilter',
                  checkboxValue: 'archived',
                  checked: statusFilter.length > 2,
                  onChange: applyStatusFilter,
                  customStylesLabel: 'absolute py-1.5',
                }}
              />
            )}

            {!isErrorOccured && !!data.length ? (
              <TableComponent
                columns={columns}
                data={data}
                isLoading={isLoading}
                pageIndex={pageIndex}
                pageSize={pageSize}
                setPagination={handlePagination}
                setAmount={setAmount}
                sortingParams={sortingParams}
                setSortingParams={setSortingParams}
                totalRecords={totalRecords}
                customHeight={'max-h-[50vh]'}
              />
            ) : isLoading && !data.length ? (
              <Loader {...{ loading: isLoading }} />
            ) : (
              <EmptySpaceContent
                image={<Icons.EmptyImage customClass="w-[210px] h-[210px]" />}
                text="There are no files to show yet."
                linkShow={!!countArchivedFile}
                linkText={'Show archived files'}
                onClick={applyStatusFilter}
              />
            )}
          </div>
        </>
      )}
    />
  );
};

export default FilesTab;
