import { Link as RouterLink } from 'react-router-dom';

import CustomButton from 'components/partials/custom-button/custom-button';
import { getStatusLabel } from 'components/partials/table-component/helpers/get-status-label';

import { formatDate } from 'helpers/dates-formatter';

import { CLIENTS_STATUSES } from 'containers/admin/clients/clients.constants';

import Icons from 'assets/icons';

//common styles for header tds
const commonStyles = 'border-r-2 border-white font-semibold cursor-pointer';

export const clientsTableSettings = [
  {
    id: 'name',
    accessorKey: 'name',
    header: () => (
      <div className="flex">
        <span>CLIENT NAME</span>
      </div>
    ),
    cell: (info) => info.renderValue(),
    headerClassName: `${commonStyles} rounded-tl-md rounded-bl-md`,
    className:
      'w-3/6 xs:w-[36%] sm:w-[40%] md:w-[46%] lg:w-[50%] text-left font-semibold pl-4',
    enableSorting: true,
    sortingFn: 'alphanumeric',
  },
  {
    id: 'state',
    accessorKey: 'state',
    header: () => <span>STATE</span>,
    cell: (info) => info.renderValue(),
    headerClassName: `${commonStyles} px-4`,
    className: 'w-[10%] md:w-[8%] text-center',
    isHeaderCentered: true,
    enableSorting: true,
  },
  {
    id: 'expireDate',
    accessorKey: 'expireDate',
    header: () => <span>EXPIRE DATE</span>,
    cell: (info) => {
      const date = formatDate(info.row.original.expireDate, 'MMM D, YYYY');

      const label = getStatusLabel(info.row.original.status, CLIENTS_STATUSES);

      return (
        <div className="flex items-center">
          <span className="w-28 pr-2">{date}</span>
          {label}
        </div>
      );
    },
    headerClassName: commonStyles,
    className: 'w-[36%] sm:w-[34%] md:w-[32%] lg:w-[30%] text-left pl-4',
    enableSorting: true,
  },
  {
    id: 'button-cell',
    cell: (info) => (
      <RouterLink to={`/admin/clients/${info.row.original.id}`}>
        <CustomButton
          variant="secondary"
          size="sm"
          iconBeforeCustomClass="pt-0.5"
          iconBefore={<Icons.Eye alt="EyeIcon" height="100%" width="14" />}
        >
          View Details
        </CustomButton>
      </RouterLink>
    ),
    headerClassName: 'h-12 rounded-tr-md rounded-br-md',
    className: 'flex justify-center min-w-[7rem] py-3',
  },
];
