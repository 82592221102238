export const REPORT_TYPES = {
  DASHBOARD_1: 'DASHBOARD_REPORT_1',
  DASHBOARD_2: 'DASHBOARD_REPORT_2',
  INCYCLE: 'REPORT_1',
  HISTORICAL_MARKET: 'REPORT_2',
  HISTORICAL_CAMPUS: 'REPORT_3',
  TILE_1: 'TILE_1',
  TILE_2: 'TILE_2',
  TILE_3: 'TILE_3',
};
