import { actions } from 'state/current-user/current-user.slice';
import { addUser } from 'state/users/users.slice';

import api from 'api';

const { setCurrentUser, loadingCurrentUserFailed, loadingCurrentUser } =
  actions;

export const currentUserInfoAction = (clientsHook) => (dispatch, getState) => {
  dispatch(loadingCurrentUser());

  const state = getState();
  const currentUserId = state.currentUser.id;

  // If the record already exists, use it.
  if (currentUserId) {
    const existingRecord = state.users.data.find((u) => u.id === currentUserId);

    if (existingRecord) {
      dispatch(setCurrentUser(existingRecord));
      dispatch(addUser(existingRecord));
      console.log(existingRecord);

      return Promise.resolve(existingRecord);
    }
  }

  return api.postLoginNotifyBackend().then(() =>
    api
      .getCurrentUser()
      .then(async (user) => {
        // While we're here, grab the client, so we can get the user's consultants
        if (user.data.client && user.data.client.id) {
          return clientsHook.find(user.data.client.id).then((client) => {
            user.data.client = client.data;

            dispatch(setCurrentUser(user.data));
            dispatch(addUser(user.data));

            return user;
          });
        } else {
          dispatch(setCurrentUser(user.data));
          dispatch(addUser(user.data));

          return user;
        }
      })
      .catch((e) => {
        dispatch(loadingCurrentUserFailed(e));
      })
  );
};
