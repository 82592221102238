import { ShowingRecordsText } from 'components/partials/table-component/showing-records-text/showing-records-text';
import { ViewByAmountSelect } from 'components/partials/table-component/view-by-amount-select/view-by-amount-select';
import { Pagination } from 'components/partials/table-component/pagination/pagination';

const PaginationWrapper = ({
  showingRecordsTextIsOn,
  viewByAmountSelectIsOn,
  table,
  pageIndex,
  pageSize,
  totalRecords,
  pagesAmount,
  setAmount,
}) => (
  <div className="flex items-center justify-end">
    {(showingRecordsTextIsOn || viewByAmountSelectIsOn) && (
      <div className="flex items-center justify-between">
        {showingRecordsTextIsOn && (
          <ShowingRecordsText
            {...{ table, pageIndex, pageSize, totalRecords }}
          />
        )}
      </div>
    )}
    <Pagination {...{ table, pagesAmount, pageIndex, totalRecords }} />
    {viewByAmountSelectIsOn && (
      <ViewByAmountSelect {...{ table, pageSize, setAmount }} />
    )}
  </div>
);

export default PaginationWrapper;
