import Icons from 'assets/icons';

const Progress = ({ averageProgress, customClass = '' }) => (
  <div className={`flex gap-1 items-center ${customClass}`}>
    <div className="font-semibold text-indigo-02">{`${averageProgress}%`}</div>
    {averageProgress === 100 && <Icons.Trophy />}
  </div>
);

export default Progress;
