import uniqid from 'uniqid';

export const ASIGNED_CONSULTANTS_FORM_FIELDS = [
  {
    name: 'principalConsultant',
    label: 'Principal',
    optionsKey: 'fdPrincipal',
    key: uniqid(),
  },
  {
    name: 'contactConsultant',
    label: 'Client Service Contact',
    optionsKey: 'fdClientService',
    key: uniqid(),
  },
  {
    name: 'analystConsultant',
    label: 'Data Analyst',
    renderComponentProps: {
      helperText: 'Internal - not visible to client',
    },
    optionsKey: 'fdAnalyst',
    key: uniqid(),
  },
];
