import React, { useEffect, useState, createContext } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import Breadcrumbs from 'components/partials/beadcrumbs/breadcrumbs';
import Heading from 'components/partials/heading/heading';
import ActionMenu from 'components/partials/action-menu/action-menu';
import FileForm from 'containers/admin/clients/client/tabs-components/files-tab/file/forms/file-form';
import FlashNotification from 'components/partials/flash-notification/flash-notification';

import Notification from 'components/partials/notification/notification.helper';

import api from 'api';

import { useCurrentUser } from 'state/current-user/current-user.thunk';
import { useClients } from 'state/clients/clients.thunk';

import { getStatusLabel } from 'components/partials/table-component/helpers/get-status-label';
import { getBreadcrumbsForFile } from 'containers/admin/clients/client/tabs-components/files-tab/file/file.helpers';
import { ALERTS } from 'containers/admin/clients/client/tabs-components/files-tab/file/file.constants';

import {
  FILE_DETAILS_FORM_VALIDATION_SCHEMA,
  FILES_STATUSES,
} from 'containers/admin/clients/client/tabs-components/files-tab/file/file.constants';
import ResponsiveWrapper from 'components/shared/others/responsive-wrapper/responsive-wrapper';

export const FileContext = createContext({
  currentUserId: 0,
  clientId: 0,
  fileId: 0,
  control: {},
  errors: {},
  clearErrors: () => {},
  reset: () => {},
  setLoading: () => {},
});

const File = ({
  match: { params },
  pageName = 'New file',
  fileData,
  isFormDisabled,
  setIsFormDisabled,
  actionMenuItems = [],
  formLoading,
  defaultValues,
  onSubmit,
}) => {
  const [loading, setLoading] = useState(false);
  const [currentClient, setCurrentClient] = useState(null);

  const clients = useClients();
  const currentUser = useCurrentUser();
  const history = useHistory();

  const clientId = params.clientId;

  const {
    control,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: defaultValues || {
      fileName: null,
      description: '',
      file: {},
    },
    resolver: FILE_DETAILS_FORM_VALIDATION_SCHEMA,
  });

  const onCancel = () => {
    defaultValues
      ? reset(defaultValues)
      : history.push(`/admin/clients/${clientId}/files`);

    if (setIsFormDisabled) {
      setIsFormDisabled(true);
    }
  };

  const onFileUpload = (data) => {
    setLoading(true);
    api
      .uploadFile({ currentUser, clientId, ...data })
      .then(({ data }) => {
        history.push(`/admin/clients/${clientId}/files/${data.id}`, {
          notificationParams: {
            ...ALERTS.SUCCESS,
          },
        });
      })
      .catch((e) =>
        Notification.flash(
          'Error during uploading file',
          'error',
          `We couldn't upload this file because of ${e.status} error. Please try again or contact support.`
        )
      )
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    if (clientId) {
      clients
        .find(clientId)
        .then((client) => {
          setCurrentClient(client.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error during fetching client.NewUserPage', error);
          setLoading(false);
        });
    }
  }, [clientId]);

  useEffect(() => {
    setIsFormDisabled && setIsFormDisabled(!!fileData);
  }, []);

  return (
    <FileContext.Provider
      value={{
        control,
        errors,
        clearErrors,
        reset,
        currentUser,
        clientId,
        setLoading,
        fileId: fileData?.id,
      }}
    >
      <ResponsiveWrapper>
        <Breadcrumbs
          breadcrumbs={getBreadcrumbsForFile(currentClient, fileData)}
        />
        <div className="flex items-center justify-between mt-4">
          <div className="flex items-center">
            <Heading variant="h1">{pageName}</Heading>
            {!!fileData && (
              <div className="ml-6">
                {getStatusLabel(fileData.status, FILES_STATUSES)}
              </div>
            )}
          </div>
          {!!actionMenuItems.length && !currentUser.data.fdAnalyst && (
            <ActionMenu
              buttonName="Edit file details"
              options={actionMenuItems}
              onEditClick={() => setIsFormDisabled(false)}
              isActionMenuDisabled={!isFormDisabled}
            />
          )}
        </div>
        <FileForm
          {...{
            handleSubmit,
            onSubmit: onSubmit || onFileUpload,
            onCancel,
            isFormDisabled,
            loading: loading || formLoading,
          }}
        />
        <FlashNotification customClass="top-4" />
      </ResponsiveWrapper>
    </FileContext.Provider>
  );
};

export default withRouter(File);
