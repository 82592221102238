import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import api from 'api';

import LoginLayout from 'containers/login/login-layout';
import Heading from 'components/partials/heading/heading';
import CustomTextInput from 'components/partials/custom-text-input/custom-text-input';
import CustomButton from 'components/partials/custom-button/custom-button';
import toast from 'components/partials/toast/toast';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Must be a valid email address')
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, 'Must be a valid email address')
    .required('Email address is required'),
});

const RequestPassword = () => {
  const {
    handleSubmit,
    control,
    setError,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: '',
    },
  });

  const history = useHistory();

  const onSubmit = async (values) => {
    try {
      await api.requestPasswordReset(values.email);
      toast.success({
        title: 'Password reset email sent',
        message: 'Check your email for a password reset link.',
      });

      history.push('/login');
    } catch (error) {
      toast.error({
        title: 'Error sending password reset email',
        message: error?.response?.data?.detail || 'Something went wrong.',
      });

      setError('email', {
        type: 'manual',
        message: 'Failed to send reset email. Please try again.',
      });
    }
  };

  return (
    <LoginLayout>
      <Heading
        variant="h2"
        customClass="font-semibold mb-4 block text-center mobile:text-xl tablet:text-3xl"
      >
        Reset Password
      </Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="email"
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <CustomTextInput
              {...field}
              label="Email"
              placeholder="Enter your email"
              errorsNames={error}
              data-testid="reset-password_email-input"
              labelInTop={true}
            />
          )}
        />

        <div className="flex justify-end mt-4">
          <CustomButton
            type="submit"
            variant="primary"
            disabled={isSubmitting}
            customClass="w-full"
          >
            {isSubmitting ? 'Sending...' : 'Continue'}
          </CustomButton>
        </div>
      </form>
    </LoginLayout>
  );
};

export default RequestPassword;
