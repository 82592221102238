import React from 'react';
import ReactDOM from 'react-dom';

const Overlay = ({ element = '#page-overlay', closeOnClick }) =>
  ReactDOM.createPortal(
    <div
      onClick={closeOnClick}
      role="overlay"
      className="fixed inset-0 w-full h-full bg-gray-03 opacity-75 z-[100]"
    ></div>,
    document.querySelector(element)
  );

export default Overlay;
