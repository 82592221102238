import React, { useState, useEffect, useReducer, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useReadOnly } from 'hooks/read-only';

import FlashNotification from 'components/partials/flash-notification/flash-notification';
import Search from 'components/partials/custom-search/custom-search';
import Loader from 'components/partials/loader/loader';
import TableComponent from 'components/partials/table-component/table-component';
import CustomButton from 'components/partials/custom-button/custom-button';
import Heading from 'components/partials/heading/heading';
import Notification from 'components/partials/notification/notification.helper';
import EmptySpaceContent from 'components/partials/empty-space-content/empty-space-content';
import FilterStatusForm from 'containers/admin/clients/client/forms/filter-status-form/filter-status-form';

import { usersReducer } from 'containers/admin/reducer';
import { clientsTableSettings } from 'containers/admin/clients/clients-table-settings';
import {
  SEARCH_CHANGE,
  FILTER_CHANGE,
  PAGE_CHANGE,
} from 'containers/admin/actions';

import { useClients } from 'state/clients/clients.thunk';

import Icons from 'assets/icons';
import ResponsiveWrapper from 'components/shared/others/responsive-wrapper/responsive-wrapper';

const TODAY = new Date();
const stringifiedToday = TODAY.toISOString().split('T')[0];

TODAY.setDate(TODAY.getDate() + 90);
const stringifiedExpireSoonDate = TODAY.toISOString().split('T')[0];

const initialState = {
  search: '',
  pageIndex: 0,
};

const ClientsAdminPage = ({ notificationParams }) => {
  const [state, dispatch] = useReducer(usersReducer, initialState);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filterStatus, setFilterStatus] = useState('all');

  const [{ pageSize, totalRecords }, setAmount] = useState({
    pageSize: 100,
    totalRecords: 0,
  });

  const { search, pageIndex } = state;

  const { load, loadingClientsError } = useClients();
  const readOnly = useReadOnly();

  //pass setSortingParams to Table in future when sorting ticket is in progress
  const [sortingParams, setSortingParams] = useState([
    { id: 'name', desc: false },
  ]);

  const handleSearch = useCallback((payload) =>
    dispatch({ type: SEARCH_CHANGE, payload })
  );

  const handleFilter = useCallback((value) => {
    dispatch({ type: FILTER_CHANGE });
    setFilterStatus(value);
  });

  const handlePagination = useCallback((payload) =>
    dispatch({ type: PAGE_CHANGE, payload })
  );

  const buildFilterParams = () => {
    let expireDate;

    switch (filterStatus) {
      case 'active':
        expireDate = { greaterThanOrEqual: stringifiedToday };
        break;

      case 'expired':
        expireDate = { lessThan: stringifiedToday };
        break;

      case 'expireSoon':
        expireDate = {
          greaterThanOrEqual: stringifiedToday,
          lessThan: stringifiedExpireSoonDate,
        };
        break;
    }

    return {
      search: { value: search },
      expireDate,
    };
  };

  useEffect(() => {
    setIsLoading(true);
    load({
      pageIndex,
      pageSize,
      sortBy: sortingParams,
      filters: buildFilterParams(),
    }).then((response) => {
      if (response?.data) setData(response?.data);

      if (response?.pagination) {
        const { totalRecords: newTotalRecords } = response.pagination;

        setAmount({ pageSize, totalRecords: newTotalRecords });
      }

      setIsLoading(false);
    });
  }, [state, pageSize, filterStatus, sortingParams]);

  useEffect(() => {
    if (loadingClientsError) setData([]);
  }, [loadingClientsError]);

  useEffect(() => {
    !!notificationParams &&
      Notification.flash(
        notificationParams.heading,
        notificationParams.variant,
        notificationParams.message
      );
  }, []);

  return (
    <ResponsiveWrapper>
      <div className="flex items-center justify-between my-5">
        <Heading variant="h1">Clients</Heading>

        {!readOnly && (
          <RouterLink to="/admin/clients/new">
            <CustomButton
              variant="primary"
              size="lg"
              iconBefore={
                <Icons.Plus alt="PlusIcon" height="100%" width="14" />
              }
            >
              Add a client
            </CustomButton>
          </RouterLink>
        )}
      </div>

      <Search {...{ search, setSearch: handleSearch, customClass: 'mb-4' }} />

      <FlashNotification customClass="mb-4" />

      <FilterStatusForm status={filterStatus} onClick={handleFilter} />

      {isLoading ? (
        <Loader
          {...{
            loading: isLoading,
            fullScreen: false,
            spinnerClassName: 'mt-[-5rem]',
          }}
        />
      ) : data.length ? (
        <TableComponent
          columns={clientsTableSettings}
          data={data}
          pageIndex={pageIndex}
          pageSize={pageSize}
          setPagination={handlePagination}
          setAmount={setAmount}
          sortingParams={sortingParams}
          setSortingParams={setSortingParams}
          totalRecords={totalRecords}
        />
      ) : (
        <EmptySpaceContent
          customClass="!mt-14"
          image={<Icons.EmptyImageAll customClass="w-[210px] h-[210px]" />}
          text={
            filterStatus === 'all' || search
              ? 'Sorry, no results found. Please try a different keyword.'
              : 'Sorry, there are no results with such filter parameters.'
          }
        />
      )}
    </ResponsiveWrapper>
  );
};

export default ClientsAdminPage;
