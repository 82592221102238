import { useState, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { history } from 'state/history';
import classNames from 'classnames';

import Confirmation from 'components/partials/confirmation/confirmation';
import CustomTextInput from 'components/partials/custom-text-input/custom-text-input';
import CustomButton from 'components/partials/custom-button/custom-button';

import Icons from 'assets/icons';

export const ClientProfileDeleteBtn = ({ clients, client, currentUser }) => {
  const [showModal, setShowModal] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleChange = useCallback(
    (event) => {
      setInputValue(event.target.value);
    },
    [setInputValue]
  );

  if (!currentUser?.data?.addAndDeleteClients) return [];

  const onConfirm = () => {
    clients
      .deleteRecord(client.id)
      .then(() => {
        history.push('/admin/clients', {
          notificationParams: {
            heading: 'Client account has been deleted.',
            variant: 'info',
            message: `You've successfully deleted ${client.name} client.`,
          },
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const bodyModal = (
    <>
      <p className="mt-2 mb-6 font-size-[18px] text-gray-03">
        By deleting this client from MARKETview{' '}
        <b className="font-semibold">all data</b> will be removed from all
        systems including any files the client has sent in the past. This action
        cannot be undone and is{' '}
        <b className="font-semibold">a permanent deletion</b> of data from
        databases and file storage.
      </p>

      <CustomTextInput
        label="Please confirm this action by typing the client name"
        labelInTop
        customClass="font-semibold text-gray-03"
        inputCustomClass="border-gray-05"
        inputWrapperCustomClass="!m-0 "
        placeholder="Client Name..."
        onChange={handleChange}
      />
    </>
  );

  return (
    <>
      <CustomButton
        customClass={classNames(
          'font-normal hover:bg-gray-00 transition duration-400 !px-4 !justify-end !text-blue-03'
        )}
        variant="text"
        iconBefore={<Icons.Delete customClass="mr-2" />}
        onClick={() => setShowModal(true)}
      >
        Delete client
      </CustomButton>

      {showModal &&
        createPortal(
          <Confirmation
            headerText="Delete client"
            onConfirmCallback={() => {
              onConfirm();
              setShowModal(false);
            }}
            onConfirmButtonLabel="Submit"
            children={bodyModal}
            disabled={inputValue !== client?.name}
            onCloseModal={() => {
              setInputValue('');
              setShowModal(false);
            }}
          />,
          document.body
        )}
    </>
  );
};
