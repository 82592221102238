import React from 'react';
import uniqid from 'uniqid';
import { Link as RouterLink } from 'react-router-dom';
import ResponsiveWrapper from 'components/shared/others/responsive-wrapper/responsive-wrapper';
import Links from 'containers/user/learn/components/links/links';
import SeeAllButton from 'containers/user/learn/components/see-all-button/see-all-button';

const CategoryContent = ({ items, title, subtitle, categoryLink }) => {
  const isArticle = title === 'Articles';

  return (
    <ResponsiveWrapper md="1120" lg="1120" customClass="mobile:mt-12 xs:mt-20">
      <div className="text-indigo-02 mb-6">
        <div className="text-4xl font-semibold mb-1">{title}</div>
        <div className="font-semibold text-lg">{subtitle}</div>
      </div>

      <div
        className={
          !isArticle
            ? 'grid mobile:grid-cols-1 tablet:grid-cols-2 xs:grid-cols-3 gap-8'
            : 'w-full'
        }
      >
        {!isArticle ? (
          items.map(({ name, coverDescription, route, coverImage }) => (
            <RouterLink
              to={route}
              key={uniqid()}
              className="border border-gray-05 rounded"
            >
              {coverImage ? (
                <img
                  src={coverImage}
                  alt={`${title} ${name}`}
                  className="mobile:w-full mobile:h-[240px] xs:h-[200px] rounded-tl rounded-tr"
                />
              ) : (
                <div className="mobile:w-full mobile:h-[240px] xs:h-[200px] tablet:bg-bottom categories-bg rounded-tl rounded-tr"></div>
              )}
              <div className="text-neutral-900 text-xl font-semibold pt-4 px-4 pb-2">
                {name}
              </div>
              <div className="text-neutral-900 text-base px-4 pb-4">
                {coverDescription}
              </div>
            </RouterLink>
          ))
        ) : (
          <Links items={items} />
        )}
      </div>

      <SeeAllButton route={categoryLink} contentType={title.toLowerCase()} />
    </ResponsiveWrapper>
  );
};

export default CategoryContent;
