import { useDispatch, useSelector } from 'react-redux';

import {
  setCurrentUserSession,
  clearCurrentUserSession,
  loadingSession,
} from 'state/okta-session/okta-session.slice';

import { useOkta } from 'utils/okta/use-okta';

export const useOktaSession = () => {
  const okta = useOkta();
  const dispatch = useDispatch();

  const session = useSelector((state) => state?.session);
  const currentUser = useSelector((state) => state?.currentUser);
  const impersonation = useSelector((state) => state?.impersonation);

  return {
    getCurrentSessionInfo: () => {
      dispatch(loadingSession());

      // Don't reload if we've already loaded this user
      if (
        session.data &&
        // Either currentUser.email or impersonator.email will match the Okta login
        ((currentUser.data && session.data.login === currentUser.data.email) ||
          (impersonation.impersonator &&
            session.data.login === impersonation.impersonator.email))
      ) {
        // Already loaded session for this user.
        return session;
      }

      if (!session.loading) {
        return okta.session
          .get()
          .then((session) => {
            const {
              createdAt,
              expiresAt,
              login,
              lastPasswordVerification,
              status,
              mfaActive,
              lastFactorVerification,
              userId,
              id,
            } = session;

            dispatch(
              setCurrentUserSession({
                createdAt,
                expiresAt,
                login,
                lastPasswordVerification,
                status,
                mfaActive,
                lastFactorVerification,
                userId,
                id,
              })
            );

            return session;
          })
          .catch(() => {
            dispatch(clearCurrentUserSession());
          });
      } else {
        // When impersonating a user as a superadmin and then going to "/" in the browser, it was
        // error from not returning a Promise. Something is setting session loading to true that
        // and it isn't being set to false before doing this check. Returning a rejected promise for
        // not instead of removing the check for session loading.
        return Promise.reject();
      }
    },
    ...session,
  };
};
