import React, { forwardRef } from 'react';

const CustomCheckbox = forwardRef(
  (
    {
      labelFor,
      label,
      checkboxValue,
      onChange,
      checked,
      customStylesLabel,
      ...rest
    },
    ref
  ) => (
    <label
      htmlFor={labelFor}
      className={`flex items-center ${customStylesLabel}`}
    >
      <input
        data-testid={rest['data-testid'] || 'custom-checkbox'}
        className="w-4 h-4 checked:accent-indigo-02"
        type="checkbox"
        id={labelFor}
        aria-labelledby="custom-checkbox"
        value={checkboxValue}
        checked={checked}
        onChange={() => {
          onChange({ userId: checkboxValue, hasAccess: !checked });
        }}
        ref={ref}
        {...rest}
      />
      <span className="ml-2">{label}</span>
    </label>
  )
);

export default CustomCheckbox;
