import React, { useState, useEffect } from 'react';
import MarkdownComponent from 'containers/user/learn/components/markdown/markdown';

import Icons from 'assets/icons';

const CALLOUTS = [
  {
    type: 'tip',
    icon: <Icons.Active alt="tip-icon" />,
    color: 'bg-green-06',
    calloutNameColor: 'text-green-07',
  },
  {
    type: 'note',
    icon: <Icons.Info alt="note-icon" />,
    color: 'bg-yellow-06',
    calloutNameColor: 'text-yellow-05',
  },
  {
    type: 'warning',
    icon: <Icons.Warning alt="warning-icon" />,
    color: 'bg-red-06',
    calloutNameColor: 'text-red-05',
  },
];

const Callout = ({ variant = 'tip', text = 'Some text' }) => {
  const [callout, setCallout] = useState({
    icon: <Icons.Active alt="tip-icon" />,
    color: 'bg-green-06',
    calloutNameColor: 'text-green-07',
  });

  useEffect(() => {
    setCallout(CALLOUTS.find((el) => el.type === variant));
  }, []);

  return (
    <div
      className={`flex flex-col ${callout?.color} rounded-md p-6 mobile:mb-6 xs:mb-8 w-full`}
    >
      <div className={`flex items-center pb-1 ${callout?.calloutNameColor}`}>
        {callout?.icon}
        <div className="pl-2 font-semibold capitalize-first">{variant}</div>
      </div>
      <MarkdownComponent text={text} />
    </div>
  );
};

export default Callout;
