import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';

import Loader from 'components/partials/loader/loader';
import Dropdown from 'components/partials/dropdown/dropdown';
import Breadcrumbs from 'components/partials/beadcrumbs/breadcrumbs';
import ResponsiveWrapper from 'components/shared/others/responsive-wrapper/responsive-wrapper';
import Heading from 'components/partials/heading/heading';
import LessonsSidebar from 'containers/user/learn/containers/content/cources/course-details/course-sidebar/course-sidebar';
import Lesson from 'containers/user/learn/containers/content/lesson/lesson';
import HorizontalProgressBar from 'containers/user/learn/components/horizontal-progress-bar/horizontal-progress-bar';
import Progress from 'containers/user/learn/components/progress/progress';

import api from 'api';
import Icons from 'assets/icons';

import { useCurrentUser } from 'state/current-user/current-user.thunk';
import { useImpersonation } from 'state/impersonation/impersonation.thunk';
import {
  countAverageProgress,
  getComponentHeights,
} from 'containers/user/learn/containers/content/cources/courses.utils';
import useFetchCourse from 'containers/user/learn/containers/content/cources/use-fetch-course';

import { COURSE_STATUSES } from 'containers/user/learn/containers/content/cources/course.constants';

const CourseDetails = () => {
  const { courseId, lessonId } = useParams();
  const [lessons, setLessons] = useState([]);
  const [currentLessonId, setCurrentLessonId] = useState(lessonId);
  const [lessonsProgress, setLessonsProgress] = useState({});
  const [componentHeights, setComponentHeights] = useState({});

  const averageProgress = countAverageProgress(lessonsProgress);
  const user = useCurrentUser();

  const { course, loading, courseMetadata } = useFetchCourse(
    user?.data?.oktaUserId,
    courseId
  );

  const isNew = course && course.status === COURSE_STATUSES.UNKNOWN;

  const { impersonatingUser } = useImpersonation();

  const latestStateRef = useRef({
    currentLessonId: lessonId,
    courseId,
    userId: user?.data?.oktaUserId,
    lessonsProgress: {},
  });

  const handleLessonProgress = useCallback(({ lessonId, progress }) => {
    setLessonsProgress((prevProgress) => ({
      ...prevProgress,
      [`lesson-${lessonId}`]: progress,
    }));
  }, []);

  const setLessonProgress = async () => {
    const { currentLessonId, courseId, userId, lessonsProgress } =
      latestStateRef.current;

    try {
      await api.setLessonProgress({
        lessonId: currentLessonId,
        courseId,
        userId,
        progress: lessonsProgress[`lesson-${currentLessonId}`],
      });
    } catch (error) {
      console.error('Failed to set lesson progress', error);
    }
  };
  const handleLessonClick = useCallback(
    async ({ event, to, id }) => {
      event && event.preventDefault();
      await setLessonProgress();
      window.history.pushState({}, '', to);
      setCurrentLessonId(id);
    },
    [currentLessonId, lessonsProgress]
  );

  useEffect(() => {
    latestStateRef.current = {
      ...latestStateRef.current,
      currentLessonId,
      courseId,
      userId: user?.data?.oktaUserId,
      lessonsProgress,
    };
  }, [currentLessonId, courseId, lessonsProgress, user?.data?.oktaUserId]);

  useEffect(() => {
    if (!course) {
      return;
    }

    const lessonsData = course.lessons.data.map((lesson) => ({
      name: lesson.attributes.name,
      id: lesson.id,
      progress: 0,
    }));

    const lessonsProgressData = courseMetadata
      ? courseMetadata.data.lessons
      : [];
    const updatedLessons = lessonsData.map((lesson) => {
      const progressEntry = lessonsProgressData.find(
        (entry) => entry.id === lesson.id
      );

      return {
        ...lesson,
        progress: progressEntry ? progressEntry.progress : 0,
      };
    });

    setLessons(updatedLessons);

    const updatedLessonsProgress = updatedLessons.reduce(
      (acc, { id, progress }) => ({
        ...acc,
        [`lesson-${id}`]: progress,
      }),
      {}
    );

    setLessonsProgress(updatedLessonsProgress);
  }, [courseId, course]);

  useEffect(() => {
    const handleResize = () => {
      const heights = getComponentHeights();

      setComponentHeights(heights);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [course]);

  useEffect(
    () => () => {
      setLessonProgress();
    },
    []
  );

  const { globalFooterHeight, globalHeaderHeight, screenHeaderHeight } =
    componentHeights;

  const sidebarTopShift = `calc(${globalHeaderHeight}px + ${screenHeaderHeight}px)`;
  const sidebarHeight = `calc(100vh - ${
    globalFooterHeight + globalHeaderHeight + screenHeaderHeight
  }px)`;

  return loading ? (
    <Loader {...{ loading }} aria-label="loading" />
  ) : (
    <ResponsiveWrapper
      md="1120"
      lg="1120"
      customClass="flex flex-col course-details"
    >
      <div
        className={classNames(
          'md:sticky z-10 bg-white pb-5 md:pb-10 course-details-header'
        )}
        style={{ top: `${globalHeaderHeight}px` }}
      >
        <Breadcrumbs
          breadcrumbs={[
            { label: 'Learn', href: '/learn' },
            { label: 'Courses', href: '/learn/courses' },
          ]}
          customClass="pb-5"
        />

        <Heading customClass="text-4xl font-semibold">{course.name}</Heading>
      </div>

      <div
        className="block sticky md:hidden z-10 py-5 bg-white"
        style={{ top: `${globalHeaderHeight}px` }}
      >
        <RouterLink
          to={`/learn/course/${courseId}`}
          className="flex font-semibold items-center gap-1 text-blue-02 hover:text-indigo-02 group mb-3"
        >
          <Icons.Arrow customClass="h-3 w-3 rotate-180 transition-transform duration-300 group-hover:-translate-x-1" />
          <span className="text-sm">See course overview</span>
        </RouterLink>
        <HorizontalProgressBar
          progress={averageProgress}
          showProgress={!isNew}
          customClass="mb-3"
        />
        {!isNew && (
          <Progress averageProgress={averageProgress} customClass="mb-3" />
        )}
        <Dropdown title="Lessons">
          <LessonsSidebar
            lessons={lessons}
            currentLessonId={currentLessonId}
            courseId={courseId}
            customWrapperClass="!w-full"
            withHeader={false}
            lessonsProgress={lessonsProgress}
            lessonOnClick={handleLessonClick}
          />
        </Dropdown>
      </div>

      <div className="flex flex-col md:flex-row">
        <div
          className="hidden md:block sticky mr-10 py-4 overflow-y-hidden overflow-x-hidden hover:overflow-y-auto custom-scroll"
          style={{
            top: sidebarTopShift,
            height: sidebarHeight,
          }}
        >
          <LessonsSidebar
            lessons={lessons}
            currentLessonId={currentLessonId}
            courseId={courseId}
            lessonsProgress={lessonsProgress}
            lessonOnClick={handleLessonClick}
          />
        </div>

        <div className="overflow-y-auto shrink-0 grow relative">
          <Lesson
            lessonId={currentLessonId}
            onProgressUpdate={handleLessonProgress}
            progress={lessonsProgress[`lesson-${currentLessonId}`]}
            handleLessonClick={handleLessonClick}
            courseId={courseId}
            lessonsIds={Object.keys(lessonsProgress)}
            isImpersonatingUser={!!impersonatingUser}
          />
        </div>
      </div>
    </ResponsiveWrapper>
  );
};

export default CourseDetails;
