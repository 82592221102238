import React from 'react';
import classNames from 'classnames';

import Icons from 'assets/icons';

const Loader = ({
  loading,
  customClass,
  overlay = 'gray-06',
  zIndex = 'z-[100]',
  overlayClassName,
  spinnerClassName,
  fullScreen = true,
}) =>
  loading ? (
    <div
      className={classNames(
        `absolute inset-0 bg-${overlay}`,
        customClass,
        zIndex,
        {
          'min-h-screen': fullScreen,
        }
      )}
    >
      <div
        className={classNames(
          'absolute inset-0 opacity-30',
          `bg-${overlay}`,
          overlayClassName
        )}
      />
      <div
        className={classNames(
          'absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2',
          spinnerClassName
        )}
      >
        <div role="status">
          <Icons.Spinner customClass="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-900" />
          <div className="loading loading:after">Loading</div>
        </div>
      </div>
    </div>
  ) : null;

export default Loader;
