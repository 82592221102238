import React, { useState, useEffect } from 'react';
import HeroContentType from 'containers/user/learn/components/hero-content-type/hero-content-type';
import api from 'api';
import { useCurrentUser } from 'state/current-user/current-user.thunk';
import Cards from 'containers/user/learn/components/cards/cards';
import NothingFound from 'containers/user/learn/components/empty-row/empty-row';
import Loader from 'components/partials/loader/loader';
import SeeMoreButton from 'containers/user/learn/components/see-more-btn/see-more-btn';

const Downloads = () => {
  const [downloads, setDownloads] = useState([]);
  const [loading, setLoading] = useState(false);
  const [displayAll, setDisplayAll] = useState(false);

  const user = useCurrentUser();

  useEffect(() => {
    setLoading(true);
    api
      .getDownloads(user?.data?.oktaUserId)
      .then((res) => {
        setDownloads(
          res?.data?.data?.map((el, ind) => ({
            name: el.attributes?.name,
            route: `/learn/content/downloads/${el.id}`,
            coverDescription: el.attributes?.coverDescription,
            coverImage: el.attributes?.coverImage?.data?.attributes?.url,
            date: el.attributes?.assetCreationDate,
          }))
        );
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, []);

  const displayedDownloads =
    displayAll || downloads.length < 10 ? downloads : downloads.slice(0, 9);

  return (
    <div className="w-full">
      <Loader loading={loading} />
      <HeroContentType
        title="Downloads"
        description="Access the latest infographics, white papers, and case studies filled with expert insights, analysis, and recommendations for navigating the higher ed marketplace."
        breadcrumbs={[
          { label: 'Learn', href: '/learn' },
          { label: 'Downloads', isActive: true },
        ]}
        customClass="pt-5"
      />
      <div className="flex flex-col items-center mt-10">
        {!displayedDownloads.length && !loading ? (
          <NothingFound contentType="downloads" />
        ) : (
          <Cards items={displayedDownloads} contentType="Download" />
        )}

        {downloads.length > 9 && (
          <SeeMoreButton
            onClick={() => setDisplayAll(!displayAll)}
            showAll={displayAll}
          />
        )}
      </div>
    </div>
  );
};

export default Downloads;
