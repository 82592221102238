import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';

import CustomTextInput from 'components/partials/custom-text-input/custom-text-input';
import Textarea from 'components/partials/textarea/textarea';

import { useGoalForm } from 'containers/admin/clients/client/tabs-components/goals-tab/goals-manager/goal/context/context';

const OtherGoalForm = ({ isDisabled }) => {
  const { control, setFocus } = useGoalForm();

  useEffect(() => {
    setFocus('name');
  }, [isDisabled]);

  return (
    <div className="flex flex-col gap-2.5">
      <Controller
        name="name"
        control={control}
        rules={{ required: true }}
        render={({ field, fieldState: { error } }) => (
          <CustomTextInput
            {...field}
            label="Goal Title"
            placeholder="Type goal title..."
            errorsNames={
              error && { ...error, message: 'This field is required' }
            }
            disabled={isDisabled}
            labelInTop={true}
            inputWrapperCustomClass="max-w-[600px]"
            maxLength={100}
          />
        )}
      />

      <Controller
        name="description"
        control={control}
        rules={{ required: true }}
        render={({ field, fieldState: { error } }) => (
          <Textarea
            {...field}
            label="Goal Description"
            placeholder="Type goal description..."
            textareaWrapperStyles="max-w-[600px]"
            disabled={isDisabled}
            errorsNames={
              error && { ...error, message: 'This field is required' }
            }
          />
        )}
      />
    </div>
  );
};

export default OtherGoalForm;
