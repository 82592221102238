import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import api from 'api';

import Breadcrumbs from 'components/partials/beadcrumbs/breadcrumbs';
import Loader from 'components/partials/loader/loader';
import ResponsiveWrapper from 'components/shared/others/responsive-wrapper/responsive-wrapper';
import Goal from 'containers/admin/clients/client/tabs-components/goals-tab/goals-manager/goal/goal';
import GoalsManagerHeader from 'containers/admin/clients/client/tabs-components/goals-tab/goals-manager/goal-manager-header/goal-manager-header';
import GoalsSidebar from 'containers/admin/clients/client/tabs-components/goals-tab/goals-manager/goals-sidebar/goals-sidebar';

import { useClients } from 'state/clients/clients.thunk';
import { useCurrentUser } from 'state/current-user/current-user.thunk';
import { history } from 'state/history';

import {
  getLastPathSegment,
  getPathToGoal,
  getUrlParams,
} from 'containers/admin/clients/client/tabs-components/goals-tab/goals-manager/goals-manager.helper';

const GoalsManager = () => {
  const [client, setClient] = useState({});
  const [loading, setLoading] = useState(true);
  const [goals, setGoals] = useState([]);
  const [goalsCount, setGoalsCount] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedGoal, setSelectedGoal] = useState('');

  const params = useParams();
  const { enteringClassYear, type, category, clientId, goalId } =
    getUrlParams(params);

  const user = useCurrentUser();
  const clients = useClients();

  const isNew = goalId === 'new' || selectedGoal === 'new';

  const handleGoalClick = useCallback(({ event, to, id }) => {
    event && event.preventDefault();
    window.history.pushState({}, '', to);
    setSelectedGoal(id);
    if (id !== 'new') {
      getGoals();
    }
  }, []);

  const handleNewGoalClick = (e) => {
    const currentUrl = getPathToGoal(window.location.pathname);

    const newPath = `${currentUrl}/new`;

    handleGoalClick({ event: e, to: newPath, id: 'new' });
  };

  const loadClient = async () => {
    try {
      const client = await clients.find(clientId);

      setClient(client.data);
    } catch (error) {
      console.error('Error during client search', error);
    }
  };

  const getGoals = useCallback(async () => {
    setLoading(true);

    try {
      const res = await api.getGoals({
        userId: user?.data?.oktaUserId,
        clientId,
        category,
        type,
        year: enteringClassYear,
      });

      const goalsData = res?.data?.content || [];

      setGoals(goalsData);
      setGoalsCount(goalsData.length);
    } catch (error) {
      console.error('Error fetching goals', error);
    } finally {
      setLoading(false);
    }
  }, [category, clientId, enteringClassYear, type, user, loading]);

  useEffect(() => {
    getGoals();
  }, []);

  useEffect(() => {
    if (goalsCount === null) {
      return;
    }

    if (goalsCount === 0 && goalId !== 'new') {
      history.push(`/admin/clients/${clientId}/goals/${enteringClassYear}`);
    }
    const goalIds = goals.map((goal) => goal.id);

    const currentGoalId = getLastPathSegment(window.location.pathname);

    const firstGoalId = goals[0]?.id;

    if (goalId !== 'new') {
      const newSelectedGoal = goalIds.includes(currentGoalId)
        ? currentGoalId
        : firstGoalId;

      selectedGoal !== newSelectedGoal && setSelectedGoal(newSelectedGoal);
    }

    if (goalId !== 'new' && !goalIds.includes(currentGoalId) && firstGoalId) {
      const currentUrl =
        goalId === 'new'
          ? getPathToGoal(window.location.pathname)
          : window.location.pathname;

      const newUrl = `${currentUrl}/${firstGoalId}`.replace('//', '/');

      window.history.pushState({}, '', newUrl);
    }
  }, [goalsCount]);

  useEffect(() => {
    loadClient();
  }, [clientId]);

  const globalFooterHeight =
    document.querySelector('.global-footer')?.clientHeight;
  const globalFooterTopMargin = 40;

  return (
    <ResponsiveWrapper
      customClass="h-full"
      style={{
        paddingBottom: `${globalFooterHeight - globalFooterTopMargin}px`,
      }}
    >
      <Breadcrumbs
        breadcrumbs={[
          { label: 'Clients', href: '/admin/clients' },
          {
            label: client?.name,
            href: `/admin/clients/${clientId}`,
          },
          {
            label: 'Goals',
            href: `/admin/clients/${clientId}/goals/${enteringClassYear}`,
          },
        ]}
        customClass="mb-3"
      />
      <GoalsManagerHeader
        {...{
          handleNewGoalClick,
          type,
          category,
          year: enteringClassYear,
          isCreation: isNew,
          isEdition: isEditMode,
          customClass: 'mb-4',
        }}
      />
      <div className="flex border-t h-full relative">
        <Loader
          loading={loading}
          overlay="white"
          customClass={`opacity-75`}
          fullScreen={false}
        />

        <GoalsSidebar
          goals={goals}
          handleGoalSelect={handleGoalClick}
          selectedGoal={selectedGoal}
          isNew={isNew}
          isEditMode={isEditMode}
        />

        <Goal
          data={goals.find((goal) => goal.id === selectedGoal)}
          isCreation={isNew}
          goalsLength={goals.length}
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
          getGoals={getGoals}
          selectedGoalId={selectedGoal}
          setLoading={setLoading}
          loading={loading}
        />
      </div>
    </ResponsiveWrapper>
  );
};

export default GoalsManager;
