import { Volume } from 'containers/admin/clients/client/tabs-components/goals-tab/goals-configuration/volume';
import { Proportion } from 'containers/admin/clients/client/tabs-components/goals-tab/goals-configuration/proportion';
import { Rate } from 'containers/admin/clients/client/tabs-components/goals-tab/goals-configuration/rate';
import { Financial } from 'containers/admin/clients/client/tabs-components/goals-tab/goals-configuration/financial';
import { Retention } from 'containers/admin/clients/client/tabs-components/goals-tab/goals-configuration/retention';
import { AcademicQuality } from 'containers/admin/clients/client/tabs-components/goals-tab/goals-configuration/academic-quality';

export const schema = {
  ...Volume,
  ...Proportion,
  ...Rate,
  ...Financial,
  ...Retention,
  ...AcademicQuality,
};
