import { Link as RouterLink } from 'react-router-dom';
import Icons from 'assets/icons';

const SeeAllButton = ({ route, contentType, customClass = '' }) => (
  <RouterLink
    to={route}
    className={`flex font-semibold items-center gap-1 hover:gap-2 text-blue-02 hover:text-indigo-02 w-fit my-6 transition-all ${customClass}`}
  >
    <div>See all {contentType}</div>
    <Icons.Arrow customClass="h-4 w-4 mt-1" />
  </RouterLink>
);

export default SeeAllButton;
