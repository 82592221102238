export const getStylesForTable = (rowInd, cellInd, rowsLength, lastCellInd) => {
  //for all middle tds in table
  let cellAditionalClass = ' border-x-0 border-b-0';

  //for case with 1 row only in table
  if (rowsLength === 1) {
    cellAditionalClass = 'border-b border-gray-05 border-x-0';
    //for any first td
    if (cellInd === 0) {
      cellAditionalClass = 'border border-r-0 rounded-tl-md rounded-bl-md';
    } else if (cellInd === lastCellInd) {
      cellAditionalClass = 'border border-l-0 rounded-tr-md rounded-br-md';
    }
  } else {
    //for cases with few rows in table
    //for any first row
    if (rowInd === 0) {
      //for first td in first row
      if (cellInd === 0) {
        cellAditionalClass = 'border-r-0 border-b-0 rounded-tl-md';
      } else if (cellInd === lastCellInd) {
        //for last td in first row
        cellAditionalClass = 'border-l-0 rounded-tr-md border-b-0';
      }
    } else if (rowInd === rowsLength - 1) {
      cellAditionalClass += 'border-b';

      // for any last row
      //for first td in last row
      if (cellInd === 0) {
        cellAditionalClass = 'border-r-0 rounded-bl-md';
      } else if (cellInd === lastCellInd) {
        //for last td in last row
        cellAditionalClass = 'border-l-0 rounded-br-md';
      }
    } else {
      //for any middle row
      //for first td in middle row
      if (cellInd === 0) {
        cellAditionalClass = 'border-r-0 border-b-0';
      } else if (cellInd === lastCellInd) {
        //for last td in middle row
        cellAditionalClass = 'border-l-0 border-b-0';
      }
    }
  }

  return cellAditionalClass;
};
