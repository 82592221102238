import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { withAuth } from '@okta/okta-react';
import FlashNotification from 'components/partials/flash-notification/flash-notification';
import Heading from 'components/partials/heading/heading';

import { getApiInfo } from 'api/api.config';
import { env } from 'env';

import Icons from 'assets/icons';

import svgBackground from 'assets/images/login-bg.svg';
import classNames from 'classnames';

const LoginLayout = ({ auth, withFooter, children }) => {
  const [authenticated, setAuthenticated] = useState(null);
  const [commitInfo, setCommitInfo] = useState('');

  const [showLogo, setShowLogo] = useState(false);
  const [showText, setShowText] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [svgBackgroundUrl, setSvgBackgroundUrl] = useState('');

  const appEnv = env.REACT_APP_ENV;
  const nonPropdEnvs = ['dev', 'qa', 'uat'];
  const isNonProdEnv = nonPropdEnvs.includes(appEnv);

  useEffect(() => {
    if (!isNonProdEnv) return;

    const fetchApiInfo = async () => {
      try {
        const resp = await getApiInfo();
        const data = resp.data;

        if (data.git.commit && data.build) {
          const infoText = `${data.git.commit.id?.abbrev} built on ${data.build.time}`;

          setCommitInfo(infoText);
        }
      } catch (err) {
        console.error('Error fetching API info:', err);
      }
    };

    fetchApiInfo();
  }, [isNonProdEnv]);

  useEffect(() => {
    // Add timestamp to the background image to force a reload because the browser caches it
    const bgUrlWithTimestamp = `${svgBackground}?${Date.now()}`;

    setSvgBackgroundUrl(bgUrlWithTimestamp);
  }, []);

  useEffect(() => {
    const checkAuthentication = async () => {
      const isAuthenticated = await auth?.isAuthenticated();

      if (isAuthenticated !== authenticated) {
        setAuthenticated(isAuthenticated);
      }
    };

    checkAuthentication();

    //timeouts for animations
    const timer1 = setTimeout(() => setShowLogo(true), 200);
    const timer2 = setTimeout(() => setShowText(true), 500);
    const timer3 = setTimeout(() => setShowForm(true), 700);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
    };
  }, [auth, authenticated]);

  return authenticated ? (
    <Redirect to={{ pathname: '/' }} />
  ) : (
    <div
      className="min-h-screen flex justify-start mobile:p-5 tablet:pt-24 pt-36 bg-cover bg-center relative login-layout-wrapper"
      style={{ backgroundImage: `url(${svgBackgroundUrl})` }}
    >
      <div
        className={classNames('w-full flex flex-col items-center', {
          'mobile:px-6 tablet:px-8': withFooter,
        })}
      >
        <FlashNotification customClass="mb-10" />

        <div className="flex flex-col justify-center items-center mb-2">
          <div
            className={classNames('transition-all duration-700', {
              'opacity-100 translate-y-0': showLogo,
              'opacity-0 -translate-y-10': !showLogo,
            })}
          >
            <Icons.LoginLogo className="mobile:w-40 mobile:h-10 tablet:h-14 tablet:w-52" />
          </div>
        </div>

        <div
          className={classNames('transition-opacity duration-700', {
            'opacity-100': showText,
            'opacity-0': !showText,
          })}
        >
          <Heading
            variant="h1"
            customClass="font-normal text-center block mobile:text-xl tablet:text-3xl"
          >
            Achieve your <span className="font-semibold">goals</span> with{' '}
            <span className="font-semibold"> actionable steps </span> informed
            by your <span className="font-semibold">Critical Elements.</span>
          </Heading>
        </div>

        <div className="w-full flex justify-center mobile:mt-5 tablet:mt-10">
          <div
            className={classNames(
              'bg-white flex flex-col items-center justify-center mobile:w-[335px] tablet:w-[500px] mobile:px-6 tablet:px-10 py-8 rounded-md transition-all duration-700',
              {
                'opacity-100 translate-y-0': showForm,
                'opacity-0 translate-y-10': !showForm,
              }
            )}
            style={{ boxShadow: '0px 2px 8px -2px rgba(0, 45, 93, 0.20)' }}
          >
            <div className="w-full">{children}</div>
            {withFooter && (
              <div className="flex justify-between w-full font-semibold custom-footer hidden">
                <a
                  className="text-green-02"
                  href="/account/request-password-reset"
                >
                  Forgot Password?
                </a>

                <a
                  href="https://verify.marketviewedu.com/help/login"
                  target="_blank"
                >
                  <div className="flex gap-1 items-center text-blue-03">
                    <Icons.Info className="h-5 w-5 shrink-0" />
                    <span>Sign-in tutorial</span>
                  </div>
                </a>
              </div>
            )}
          </div>
        </div>
        {isNonProdEnv && (
          <span className="absolute bottom-8 font-sm">{commitInfo}</span>
        )}
      </div>
    </div>
  );
};

export default withAuth(LoginLayout);
