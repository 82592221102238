import React from 'react';

const Heading = React.forwardRef(
  ({ variant, customClass = '', children }, ref) => {
    const variantClass = {
      h1: 'font-h-1',
      h2: 'font-h-2',
      h3: 'font-h-3',
    };

    const headingClass = `${variantClass[variant]} text-indigo-02 font-family ${customClass}`;

    return (
      <span ref={ref} className={headingClass}>
        {children}
      </span>
    );
  }
);

export default Heading;
