import ResponsiveWrapper from 'components/shared/others/responsive-wrapper/responsive-wrapper';
import React from 'react';

const HeroLearnPartial = () => (
  <div className="flex justify-center md:flex-row bg-indigo-02 mobile:h-[300px] xs:logo-bg xs:bg-[150%] md:bg-right">
    <ResponsiveWrapper
      md="1120"
      lg="1120"
      customClass="flex flex-col justify-center"
    >
      <div className="tablet:w-[600px]">
        <div className="text-5xl font-semibold text-white mb-4">
          MARKETview Learning Hub
        </div>
      </div>
    </ResponsiveWrapper>
  </div>
);

export default HeroLearnPartial;
