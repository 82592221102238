import axios from 'axios';
import { env } from 'env';
import { errorInterceptors } from 'api/interceptors';

import { getOktaBearerToken } from 'helpers/okta-session';

const BASE_URL = env.REACT_APP_API_URL
  ? `${env.REACT_APP_API_URL}/api`
  : '/api'; // package.json proxies this appropriately when running locally, so we can just use the relative path)

const API_HEALTH_URL = env.REACT_APP_API_URL
  ? `${env.REACT_APP_API_URL}/management/health`
  : '/management/health';

const API_INFO_URL = `${env.REACT_APP_API_URL}/management/info`;

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';

// Check api site health
export const getApiHealth = () =>
  axios
    .get(API_HEALTH_URL)
    .then((resp) => resp)
    .catch((err) => err);

export const getApiInfo = () =>
  axios
    .get(API_INFO_URL)
    .then((resp) => resp)
    .catch((err) => err);

// API conficuration
const api = axios.create({
  baseURL: BASE_URL,
  transformRequest: [
    function (data, headers) {
      let token;

      if ((token = getOktaBearerToken()))
        headers['Authorization'] = `Bearer ${token}`;

      return data;
    },
  ],
});

// Add a response interceptor
api.interceptors.response.use(
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  (response) => response,
  errorInterceptors
);

api.textpost = (path, data) =>
  api.post(path, data, {
    headers: {
      'Content-Type': 'text/plain',
    },
  });

export default api;
