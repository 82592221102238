import React from 'react';
import uniqid from 'uniqid';
import { Link as RouterLink } from 'react-router-dom';
import ResponsiveWrapper from 'components/shared/others/responsive-wrapper/responsive-wrapper';
import { formatDate } from 'helpers/dates-formatter';

const Cards = ({ items = [], contentType }) => (
  <ResponsiveWrapper
    md="1120"
    lg="1120"
    customClass="grid mobile:grid-cols-1 tablet:grid-cols-2 xs:grid-cols-3 md:grid-cols-3 gap-8"
  >
    {items.map(({ name, coverDescription, route, date, coverImage }) => (
      <RouterLink
        to={route}
        key={uniqid()}
        className="border border-gray-05 rounded mobile:w-[335px] tablet:w-[338px] xs:w-[276px] md:w-[357px]"
      >
        {coverImage ? (
          <img
            src={coverImage}
            alt={`${contentType} ${name}`}
            className="mobile:w-full mobile:h-[240px] xs:h-[200px] rounded-tl rounded-tr"
          />
        ) : (
          <div className="mobile:w-full mobile:h-[240px] xs:h-[200px] tablet:bg-bottom categories-bg rounded-tl rounded-tr"></div>
        )}

        <div className="p-5">
          <div className="flex justify-between items-center">
            <div className="bg-gray-00 px-2.5 py-1 rounded w-fit text-indigo-02 text-sm ">
              {contentType}
            </div>
            {date && (
              <div className="text-blue-03 text-sm font-semibold">
                {formatDate(date, 'MMM D, YYYY') || date}
              </div>
            )}
          </div>

          <div className="text-neutral-900 text-xl font-semibold py-2">
            {name}
          </div>
          <div className="text-neutral-900 text-base">{coverDescription}</div>
        </div>
      </RouterLink>
    ))}
  </ResponsiveWrapper>
);

export default Cards;
