import React, { useState, useEffect, useMemo } from 'react';

import CustomButton from 'components/partials/custom-button/custom-button';

import Icons from 'assets/icons';

export const Pagination = ({ table, pagesAmount, pageIndex, totalRecords }) => {
  //set range of pages to show
  const pagesNumberToShow = 10;

  const showTableFeature = pagesAmount > 1;

  //set default start and end pages
  const [{ startPage, lastPage }, setStartAndLastPages] = useState({
    startPage: 0,
    lastPage: pagesNumberToShow,
  });

  //define array with all pages list
  const allPagesList = useMemo(() => {
    if (pagesAmount)
      return Array(pagesAmount)
        .fill()
        .map((_, y) => y + 1);
  }, [pagesAmount]);

  //define array with last pages range
  const lastPagesRange = allPagesList?.slice(
    allPagesList.length - pagesNumberToShow,
    allPagesList[allPagesList.length - 1]
  );

  //set array with concrete range to display in pagination
  const pagesToShow = useMemo(
    () => allPagesList?.slice(startPage, lastPage),
    [allPagesList, startPage, lastPage]
  );

  //check whether array with range contains page to be redirected
  useEffect(() => {
    //if there is no such page in current array with range - we set new range
    if (!pagesToShow?.includes(pageIndex + 1)) {
      //but before we check whether lastPagesRange array contains page to set appropriate start and end pages
      if (lastPagesRange?.includes(pageIndex + 1)) {
        //lastPagesRange array contains page
        setStartAndLastPages({
          startPage: lastPagesRange[0] - 1,
          lastPage: lastPagesRange[lastPagesRange.length - 1],
        });
      } else {
        //lastPagesRange array does not contain page
        setStartAndLastPages({
          startPage: pageIndex,
          lastPage: pageIndex + pagesNumberToShow,
        });
      }
    }
  }, [pageIndex]);

  return (
    <div className="flex justify-center">
      <CustomButton
        variant="secondaryWithoutBorder"
        size="smSquare"
        iconBefore={
          <Icons.DoubleArrow
            customClass="rotate-180"
            alt="DoubleArrowLeftIcon"
            height="100%"
            width="14"
          />
        }
        customClass="ml-1 md:ml-2 text-indigo-02 font-semibold"
        iconBeforeCustomClass="!pr-0"
        disabled={!showTableFeature || pageIndex === 0}
        onClick={() => table.setPageIndex(0)}
      />

      <CustomButton
        variant="secondaryWithoutBorder"
        size="smSquare"
        iconBefore={
          <Icons.Arrow
            customClass="rotate-180"
            alt="ArrowLeftIcon"
            height="100%"
            width="16"
          />
        }
        customClass="ml-1 md:ml-2 text-indigo-02 font-semibold"
        iconBeforeCustomClass="!pr-0"
        disabled={!showTableFeature || pageIndex === 0}
        onClick={() => table.previousPage()}
      />

      <CustomButton
        variant="secondaryWithoutBorder"
        size="smSquare"
        iconBefore={
          <Icons.Arrow alt="ArrowRightIcon" height="100%" width="16" />
        }
        customClass="ml-1 md:ml-2 text-indigo-02 font-semibold"
        iconBeforeCustomClass="!pr-0"
        disabled={!showTableFeature || pageIndex + 1 === table.getPageCount()}
        onClick={() => table.nextPage()}
      />

      <CustomButton
        variant="secondaryWithoutBorder"
        size="smSquare"
        iconBefore={
          <Icons.DoubleArrow
            alt="DoubleArrowRightIcon"
            height="100%"
            width="14"
          />
        }
        customClass="ml-1 md:ml-2 text-indigo-02 font-semibold"
        iconBeforeCustomClass="!pr-0"
        disabled={!showTableFeature || pageIndex + 1 === table.getPageCount()}
        onClick={() => table.setPageIndex(totalRecords)}
      />
    </div>
  );
};
