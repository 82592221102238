import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

const Breadcrumb = ({ label, href, isActive, isLast, isFirst }) => {
  const baseStyles = classNames(
    'font-family font-normal first-letter:uppercase',
    {
      'px-1': !isFirst && !isLast,
      'pr-1': isFirst,
      'pl-1': isLast,
      'text-blue-04': isActive,
      'text-blue-02': !isActive,
    }
  );

  return (
    <>
      {isActive ? (
        <p className={baseStyles}>{label}</p>
      ) : (
        <Link to={href} className={baseStyles}>
          {label}
        </Link>
      )}
      {!isLast && <span className="text-blue-04">/</span>}
    </>
  );
};

export default Breadcrumb;
