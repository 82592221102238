import { useCallback } from 'react';

const useGenerateFields = (schema, type) =>
  useCallback(
    (config, details = []) => {
      const initialFields = [config];
      let currentConfig = config;

      const findSelectedOption = (options, associatedPropertyName, dataField) =>
        options.find((opt) =>
          Array.isArray(dataField[associatedPropertyName])
            ? dataField[associatedPropertyName].find(
                (option) => option === opt.value
              )
            : opt.value === dataField[associatedPropertyName]
        );

      Object.keys(details).forEach((key) => {
        if (currentConfig.options) {
          const selectedOption = findSelectedOption(
            currentConfig.options,
            currentConfig.associatedPropertyName,
            details
          );

          if (selectedOption?.nested) {
            initialFields.push(selectedOption.nested);
            currentConfig = selectedOption.nested;
          }
        }
      });

      return initialFields;
    },
    [schema, type]
  );

export default useGenerateFields;
