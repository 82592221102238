import React from 'react';
import MarkdownComponent from 'containers/user/learn/components/markdown/markdown';

const Paragraph = ({ title = '', text = '', id }) => (
  <div className="mobile:mb-6 xs:mb-8">
    {title && (
      <h4
        id={`block_${id}`}
        className="text-2xl font-semibold pb-2 text-dark-01"
      >
        {title}
      </h4>
    )}
    <MarkdownComponent text={text} />
  </div>
);

export default Paragraph;
