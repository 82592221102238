import classNames from 'classnames';

const HorizontalProgressBar = ({
  progress = 0,
  customClass = '',
  showProgress = true,
}) => (
  <div className={`w-full bg-gray-05 rounded-full ${customClass}`}>
    <div
      className={classNames('p-0.5 leading-none rounded-md', {
        'bg-indigo-02': showProgress,
      })}
      style={{ width: `${progress}%` }}
    ></div>
  </div>
);

export default HorizontalProgressBar;
