import React, { useEffect, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';

import Select from 'components/partials/custom-select/custom-select';

import { useGoalForm } from 'containers/admin/clients/client/tabs-components/goals-tab/goals-manager/goal/context/context';

import useGenerateFields from 'containers/admin/clients/client/tabs-components/goals-tab/goals-manager/goal/goal-form/hooks/useGenerateFields';
import useHandleSelectChange from 'containers/admin/clients/client/tabs-components/goals-tab/goals-manager/goal/goal-form/hooks/useHandleSelectChange';

import { schema } from 'containers/admin/clients/client/tabs-components/goals-tab/goals-configuration/goals-configuration';

const DetailFields = ({
  detailIndex,
  detailData,
  category,
  isDisabled,
  type,
}) => {
  const { resetField, clearErrors, loading, setValue, control } = useGoalForm();

  const [fields, setFields] = useState([]);
  const [renderKey, setRenderKey] = useState(0);

  const initializeFormValues = useGenerateFields(schema, type);

  const handleSelectChange = useHandleSelectChange(
    fields,
    setFields,
    setValue,
    resetField
  );

  const watchedDetail = useWatch({
    control,
    name: `details.${detailIndex}`,
  });

  useEffect(() => {
    if (loading || !category || !!fields.length) return;

    const categoryConfig = schema[type]?.categories.find(
      (cat) => cat.name === category
    );

    if (categoryConfig) {
      const initializedFields =
        detailData && !!Object.keys(detailData).length
          ? initializeFormValues(categoryConfig.details, detailData)
          : [categoryConfig.details];

      setFields(initializedFields);
    }
  }, [category, type, loading, detailIndex, detailData, fields.length]);

  //Handling fields with one option
  useEffect(() => {
    if (!fields.length || loading) return;

    fields.forEach((fieldConfig, index) => {
      const options = fieldConfig.options || [];
      const currentValue = watchedDetail[fieldConfig.associatedPropertyName];

      if (options.length === 1 && currentValue !== options[0].value) {
        const singleOption = options[0].value;

        setValue(
          `details.${detailIndex}.${fieldConfig.associatedPropertyName}`,
          singleOption
        );

        handleSelectChange({
          value: singleOption,
          field: fieldConfig,
          onChange: () => {},
          index,
          detailIndex,
        });
      }
    });
  }, [
    fields,
    detailIndex,
    loading,
    watchedDetail,
    setValue,
    handleSelectChange,
  ]);

  // Effect to manually trigger rerender if fields change
  useEffect(() => {
    setRenderKey((prevKey) => prevKey + 1);
  }, [fields]);

  const getSelectValue = (options, value, multiple) =>
    multiple
      ? options.filter((option) => value?.includes(option.value))
      : options.find((option) => option.value === value);

  const handleOnChange = ({
    selectedOption,
    multiple,
    onChange,
    field,
    index,
    detailIndex,
  }) => {
    const newValue = multiple
      ? selectedOption?.map((option) => option.value) ?? []
      : selectedOption?.value ?? '';

    handleSelectChange({
      value: newValue,
      field,
      onChange,
      index,
      detailIndex,
    });
  };

  const renderOptions = (options) =>
    options.map((option) => ({ value: option.value, label: option.label }));

  const renderField = (field, index) => {
    const { associatedPropertyName, displayName, options, required, multiple } =
      field;
    const singleOption = options.length === 1 ? options[0] : null;

    return (
      <div
        key={`${associatedPropertyName}-${detailIndex}-${renderKey}`}
        className="shrink-0"
      >
        <Controller
          name={`details.${detailIndex}.${associatedPropertyName}`}
          control={control}
          rules={{ required }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Select
              value={getSelectValue(options, value, multiple)}
              onChange={(selectedOption) =>
                handleOnChange({
                  selectedOption,
                  multiple,
                  onChange,
                  field,
                  index,
                  detailIndex,
                })
              }
              options={renderOptions(options)}
              disabled={isDisabled || singleOption}
              customSelectWrapperClass="!min-w-[150px]"
              labelInTop={true}
              label={displayName}
              isMulti={multiple}
              fieldError={
                error && { ...error, message: 'This field is required' }
              }
              clearInputErr={() =>
                clearErrors(`details.${detailIndex}.${associatedPropertyName}`)
              }
              isClearable={!required}
            />
          )}
        />
      </div>
    );
  };

  return (
    <div className="flex gap-3 flex-wrap">
      {fields.map((field, index) => renderField(field, index))}
    </div>
  );
};

export default React.memo(DetailFields);
