import { saveAs } from 'file-saver';

import api from 'api';

export const downloadFile = ({
  fileName,
  setLoading,
  currentUser,
  clientId,
  fileId,
}) => {
  setLoading(true);

  api
    .downloadFile({ currentUser, clientId, fileId })
    .then(({ data }) => {
      saveAs(data, fileName);
    })
    .catch((error) => console.log(error))
    .finally(() => {
      setLoading(false);
    });
};
