import CustomCheckbox from 'components/partials/custom-checkbox/custom-checkbox';

const commonStyles = 'pl-4 border-r-2 border-white font-semibold';

export const getFileUsersTableSettings = ({
  onUserChange,
  onSelectAllUsers,
  isAllUsersSelected,
}) =>
  [
    {
      id: 'button-cell',
      header: () => (
        <CustomCheckbox
          {...{
            labelFor: 'selectAllFiles',
            label: 'SELECT ALL',
            checkboxValue: 'allSelected',
            checked: isAllUsersSelected,
            onChange: onSelectAllUsers,
          }}
        />
      ),
      cell: ({
        row: {
          original: { hasAccess, userId },
        },
      }) => (
        <CustomCheckbox
          {...{
            labelFor: 'userForFile',
            checkboxValue: userId,
            checked: hasAccess,
            onChange: onUserChange,
          }}
        />
      ),
      headerClassName: `${commonStyles} rounded-tl-md rounded-bl-md`,
      className: 'pl-4 py-3 w-[16%]',
    },
    {
      id: 'name',
      accessorKey: 'firstName',
      header: () => <span>USER NAME</span>,
      cell: ({
        row: {
          original: { firstName, lastName },
        },
      }) => `${firstName} ${lastName}`,
      headerClassName: commonStyles,
      className: 'pl-4 py-3 w-[42%]',
      enableSorting: false,
    },
    {
      id: 'jobTitle',
      accessorKey: 'jobTitle',
      header: () => <span>JOB TITLE</span>,
      cell: (info) => info.renderValue(),
      headerClassName: 'h-12 rounded-tr-md rounded-br-md pl-4',
      className: 'pl-4 py-3 w-[42%]',
      enableSorting: false,
    },
  ].filter((item) => item);
