import React, { useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import Heading from 'components/partials/heading/heading';
import FlashNotification from 'components/partials/flash-notification/flash-notification';
import Breadcrumbs from 'components/partials/beadcrumbs/breadcrumbs';
import ActionMenu from 'components/partials/action-menu/action-menu';
import Loader from 'components/partials/loader/loader';

import ClientForm from 'containers/admin/clients/client/forms/client-form';

import { useClients } from 'state/clients/clients.thunk';

import { transformProfileFormData } from 'helpers/forms';
import ResponsiveWrapper from 'components/shared/others/responsive-wrapper/responsive-wrapper';

const ClientProfile = ({
  pageName = 'Profile information',
  isFormDisabled = false,
  setIsFormActive,
  showClientDeleteBtn,
  defaultValues = {
    name: '',
    authorizedDomains: [],
    externalId: '',
    state: '',
    expireDate: '01/01/2100',
  },
}) => {
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const clients = useClients();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    clearErrors,
  } = useForm({
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  const onSubmit = (formData) => {
    const dataToSend = transformProfileFormData(formData);

    setLoading(true);
    clients
      .createOrUpdate(dataToSend)
      .then(() => {
        history.push('/admin/clients', {
          notificationParams: {
            heading: `Client account ${dataToSend.id ? 'updated' : 'created'}`,
            variant: 'success',
            message: dataToSend.id
              ? `You've successfully updated ${dataToSend.name}`
              : `You've successfully added ${dataToSend.name} as a client`,
          },
        });
        reset();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <ResponsiveWrapper>
      <Loader {...{ loading }} />
      {!showClientDeleteBtn && (
        <Breadcrumbs
          breadcrumbs={[
            { label: 'Clients', href: '/admin/clients' },
            { label: 'New client', isActive: true },
          ]}
        />
      )}
      <div className="flex flex-col w-full mt-8">
        <div className="flex items-center justify-between">
          <Heading
            variant={!!showClientDeleteBtn ? 'h2' : 'h1'}
            customClass="mb-8"
          >
            {pageName}
          </Heading>

          {!!showClientDeleteBtn && (
            <ActionMenu
              onEditClick={() => setIsFormActive(false)}
              isActionMenuDisabled={!isFormDisabled}
            />
          )}
        </div>

        <FlashNotification customClass="mb-4 mt-0" />

        <ClientForm
          {...{
            isFormDisabled,
            onCancel: () => {
              setIsFormActive
                ? history.push(`/admin/clients/${defaultValues?.id}/profile`)
                : history.push('/admin/clients');
            },
            control,
            onSubmit,
            handleSubmit,
            errors,
            clearErrors,
          }}
        />
      </div>
    </ResponsiveWrapper>
  );
};

export default withRouter(ClientProfile);
