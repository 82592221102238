import { categories } from 'containers/admin/clients/client/tabs-components/goals-tab/goals-configuration/categories';

export const Financial = {
  Financial: {
    subType: [
      {
        value: 'NTR',
        label: 'NTR',
        valueType: 'DOLLAR',
      },
      {
        value: 'Average NTR',
        label: 'Average NTR',
        valueType: 'DOLLAR',
      },
      {
        value: 'Discount',
        label: 'Discount',
        valueType: 'PERCENT',
      },
    ],
    categories,
  },
};
