import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import CustomButton from 'components/partials/custom-button/custom-button';

import { history } from 'state/history';

import { renderConfirmationModal } from 'helpers/confirmation-render';
import {
  restartCourse,
  startCourse,
} from 'containers/user/learn/containers/content/cources/courses.utils';

import { COURSE_STATUSES } from 'containers/user/learn/containers/content/cources/course.constants';

const CourseControlButtons = ({ course, userId, size = 'sm' }) => {
  const { id: courseId, name, firstLessonId, status } = course;

  const getButtonData = (courseStatus) => {
    switch (courseStatus) {
      case COURSE_STATUSES.IN_PROGRESS:
        return { label: 'Continue' };
      case COURSE_STATUSES.DONE:
        return {
          label: 'Recap',
          variant: 'secondary',
        };
      default:
        return {
          onClick: async () =>
            await startCourse({ userId: userId, courseId: courseId }),
          label: 'Start Course',
        };
    }
  };

  const { label, onClick } = getButtonData(status);

  const handleRetake = () => {
    renderConfirmationModal({
      headerText: `Are you sure you want to retake ${name} course?`,
      text: 'This action will reset your previous progress, and you`ll start from the beginning.',
      onConfirmCallback: async () => {
        await restartCourse({
          userId,
          courseId,
        });
        history.push(`/learn/courses/${courseId}/lesson/${firstLessonId}`);
      },
      onConfirmButtonLabel: 'Retake Course',
    });
  };

  return (
    <div className="flex items-center gap-2">
      {(status === COURSE_STATUSES.DONE ||
        status === COURSE_STATUSES.IN_PROGRESS) && (
        <CustomButton variant="secondary" size={size} onClick={handleRetake}>
          Retake
        </CustomButton>
      )}

      <RouterLink to={`/learn/courses/${courseId}/lesson/${firstLessonId}`}>
        <CustomButton variant="primary" size={size} onClick={onClick}>
          {label}
        </CustomButton>
      </RouterLink>
    </div>
  );
};

export default CourseControlButtons;
