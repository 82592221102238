import React, { useEffect } from 'react';
import { withAuth } from '@okta/okta-react';
import classNames from 'classnames';

import Loader from 'components/partials/loader/loader';
import NotificationMessage from 'components/partials/notification/notification';
import ImpersonationBanner from 'components/shared/others/impersonation-banner/impersonation-banner';
import Header from 'components/shared/others/header/header';
import MainAuthenticated from 'components/shared/others/main-authenticated/main-authenticated';
import Footer from 'components/shared/others/footer/footer';

import { useCurrentUser } from 'state/current-user/current-user.thunk';

import { checkPage } from 'helpers/check-page';

const AuthenticatedApp = ({ auth }) => {
  const user = useCurrentUser();

  useEffect(() => {
    if (user.data) {
      return;
    }

    user.getInfo();

    // User is mutated from getInfo so cannot use dependency array to run useEffect
    // eslint-disable-next-line
  }, []);

  if (user.error) {
    return (
      <NotificationMessage
        customClass="m-8"
        variant="error"
        heading="An error occurred"
        message={
          (user.error && user.error.data && user.error.data.detail) ||
          (user.error.data && user.error.data.title)
        }
      />
    );
  }

  return (
    <>
      {user.loading || !user.id || !user.data ? (
        <Loader {...{ loading: true, customClass: 'mt-10' }} />
      ) : (
        <div
          className={classNames(
            'w-full h-screen flex flex-col items-center font-text font-normal leading-normal text-base p-0 text-gray-03',
            {
              '!h-full':
                checkPage('home') ||
                (!user?.data?.superAdmin && checkPage('profile')),
            }
          )}
        >
          <div className="flex flex-col w-full fixed top-0 z-[600] global-header">
            <ImpersonationBanner />
            <Header />
          </div>
          <MainAuthenticated user={user} />
          <Footer />
        </div>
      )}
    </>
  );
};

export default withAuth(AuthenticatedApp);
