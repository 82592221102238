import React, { forwardRef } from 'react';

import Icons from 'assets/icons';

const EmptySpaceContent = forwardRef(
  (
    {
      image,
      text,
      linkShow = null,
      linkText = null,
      onClick,
      customClass = '',
    },
    ref
  ) => (
    <div
      className={`flex justify-center items-center font-large text-center rounded-md bg-gray-00 relative h-[55vh] ${customClass}`}
    >
      <div className="flex flex-col items-center font-family-[Barlow] font-semibold">
        {image}

        <span className="mt-[50px] text-gray-04">{text}</span>

        {linkShow && (
          <div
            className="flex items-center mt-6 pb-6 cursor-pointer"
            onClick={onClick}
          >
            <span className="text-indigo-02">{linkText}</span>
            <Icons.Arrow customClass="ml-2 text-indigo-02 font-semibold" />
          </div>
        )}
      </div>
    </div>
  )
);

export default EmptySpaceContent;
