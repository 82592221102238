import React, { useContext, useState, useEffect } from 'react';

import Heading from 'components/partials/heading/heading';
import CustomButton from 'components/partials/custom-button/custom-button';
import Loader from 'components/partials/loader/loader';
import FilesDetailsForm from 'containers/admin/clients/client/tabs-components/files-tab/file/forms/file-details-form/file-details-form';

import { FileContext } from 'containers/admin/clients/client/tabs-components/files-tab/file/file';

const FileForm = ({
  isFormDisabled,
  loading,
  onCancel,
  onSubmit,
  handleSubmit,
}) => {
  const { control, errors } = useContext(FileContext);
  const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState(false);

  useEffect(() => {
    setIsSaveBtnDisabled(Object.keys(errors).length !== 0);
  }, [Object.keys(errors).length]);

  return (
    <div className="flex flex-col">
      <Loader {...{ loading }} />
      <Heading variant="h3" customClass="mt-4 block">
        File details
      </Heading>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-full">
        <FilesDetailsForm
          {...{
            customClass: 'mt-4',
            control,
            errors,
            isFormDisabled,
          }}
        />
        {!isFormDisabled && (
          <div className="flex justify-end mt-4">
            <CustomButton
              variant="text"
              size="md"
              onClick={onCancel}
              type="button"
            >
              Cancel
            </CustomButton>
            <CustomButton
              disabled={isSaveBtnDisabled}
              variant="primary"
              size="md"
              type="submit"
            >
              Save file
            </CustomButton>
          </div>
        )}
      </form>
    </div>
  );
};

export default FileForm;
