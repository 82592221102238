import React, { useState, useEffect } from 'react';

import Heading from 'components/partials/heading/heading';
import CustomButton from 'components/partials/custom-button/custom-button';

import BasicInfoForm from 'containers/admin/clients/client/forms/basic-info-form/basic-info-form';
import AsignedConsultantsForm from 'containers/admin/clients/client/forms/asigned-consultants-form/asigned-consultants-form';

const ClientForm = ({
  isFormDisabled,
  onCancel,
  control,
  onSubmit,
  handleSubmit,
  errors,
  clearErrors,
}) => {
  const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState(false);

  useEffect(() => {
    setIsSaveBtnDisabled(Object.keys(errors).length !== 0);
  }, [Object.keys(errors).length]);

  return (
    <div className="flex flex-col">
      <div className="flex w-full">
        <Heading variant="h3" customClass="w-3/6 mr-16">
          Basic information
        </Heading>
        <Heading variant="h3" customClass="w-3/6">
          Assigned consultants
        </Heading>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-full">
        <div className="flex">
          <BasicInfoForm
            {...{ isFormDisabled, control, errors, clearErrors }}
          />
          <AsignedConsultantsForm {...{ isFormDisabled, control }} />
        </div>
        {!isFormDisabled && (
          <div className="flex justify-end">
            <CustomButton variant="text" size="md" onClick={onCancel}>
              Cancel
            </CustomButton>
            <CustomButton
              disabled={isSaveBtnDisabled}
              variant="primary"
              size="md"
            >
              Save client
            </CustomButton>
          </div>
        )}
      </form>
    </div>
  );
};

export default ClientForm;
