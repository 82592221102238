import React, { useState, useEffect } from 'react';

const ImageComponent = ({ src = '', height, width }) => {
  const [maxWidth, setMaxWidth] = useState(780);

  const aspectRatio = height / width;
  const newHeight = aspectRatio * maxWidth;

  const breakpoints = {
    mobile: 335,
    tablet: 780,
    xs: 880,
    md: 780,
  };

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < breakpoints.tablet) {
        setMaxWidth(breakpoints.mobile);
      } else if (window.innerWidth < breakpoints.xs) {
        setMaxWidth(breakpoints.tablet);
      } else if (window.innerWidth < breakpoints.md) {
        setMaxWidth(breakpoints.xs);
      } else {
        setMaxWidth(breakpoints.md);
      }
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="mobile:mb-6 xs:mb-8">
      <img
        src={src}
        alt="image component"
        className="flex"
        style={{ minHeight: newHeight, height: newHeight }}
      />
    </div>
  );
};

export default ImageComponent;
