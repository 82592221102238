import { categories } from 'containers/admin/clients/client/tabs-components/goals-tab/goals-configuration/categories';

export const Rate = {
  Rate: {
    subType: [
      {
        value: 'Admit Rate',
        label: 'Admit Rate',
        valueType: 'PERCENT',
      },
      {
        value: 'Yield',
        label: 'Yield',
        valueType: 'PERCENT',
      },
    ],
    categories,
  },
};
