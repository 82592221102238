import { categories } from 'containers/admin/clients/client/tabs-components/goals-tab/goals-configuration/categories';

export const AcademicQuality = {
  AcademicQuality: {
    subType: [
      {
        value: 'GPA',
        label: 'GPA',
        valueType: 'RANGE_0_6',
      },
      {
        value: 'ACT',
        label: 'ACT',
        valueType: 'RANGE_0_40',
      },
      {
        value: 'SAT',
        label: 'SAT',
        valueType: 'RANGE_0_2000',
      },
    ],
    categories,
  },
};
