import React from 'react';

import Icons from 'assets/icons';

const Quote = ({ text, author }) => (
  <div className="flex mobile:flex-col tablet:flex-row bg-primary-light p-6 rounded-md mobile:mb-6 xs:mb-8">
    <div className="w-12 h-8">
      <Icons.QuoteMark width={28} height={32} />
    </div>
    <div className="mobile:pt-2 tablet:pt-0 tablet:pl-4">
      <div className="font-semibold text-lg text-indigo-03">{text}</div>
      <div className="italic text-sm text-blue-02 pt-4">{author}</div>
    </div>
  </div>
);

export default Quote;
