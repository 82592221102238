import { useSelector } from 'react-redux';

import { useGenericCollection } from 'helpers/state/use-generic-collection';

export const useFiles = () => {
  const collectionMethods = useGenericCollection('file');
  const files = useSelector((state) => state?.files);

  return {
    ...collectionMethods,
    ...files,
  };
};
