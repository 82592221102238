const useHandleSelectChange =
  (fields, setFields, setValue, resetField) =>
  ({ value, field, onChange, index, detailIndex = 0 }) => {
    const fieldName = `details.${detailIndex}.${field.associatedPropertyName}`;

    onChange(value);
    setValue(fieldName, value);

    const selectedOption = field.options.find((opt) => opt.value === value);

    if (selectedOption?.nested) {
      const newFields = [...fields.slice(0, index + 1), selectedOption.nested];

      setFields(newFields);
    }

    fields
      .slice(index + 1)
      .forEach((f) =>
        resetField(`details.${detailIndex}.${f.associatedPropertyName}`)
      );
  };

export default useHandleSelectChange;
