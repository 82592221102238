import React, { useState, useRef, useMemo } from 'react';

import CustomTextInput from '../custom-text-input/custom-text-input';

import { debounce } from 'helpers/debounce';

import Icons from 'assets/icons';

const Search = ({
  setSearch,
  customClass,
  placeholder = 'Search...',
  customClassIcon = '',
}) => {
  const [searchIsStarted, setSearchIsStarted] = useState(false);

  const ref = useRef();

  const handleOnChange = useMemo(
    () =>
      debounce((value) => {
        setSearch(value);
      }, 800),
    []
  );

  return (
    <div className={`flex items-center relative ${customClass}`}>
      {searchIsStarted ? (
        <Icons.Plus
          alt="PlusIcon"
          height="100%"
          width="14"
          customClass={`text-gray-05 absolute left-4 rotate-45 cursor-pointer ${customClassIcon}`}
          onClick={() => {
            ref.current.value = '';
            setSearchIsStarted(false);
            setSearch('');
          }}
        />
      ) : (
        <Icons.Search
          customClass={`text-gray-05 left-4 absolute ${customClassIcon}`}
        />
      )}
      <CustomTextInput
        ref={ref}
        inputCustomClass="border-gray-05 pl-11"
        inputWrapperCustomClass="!m-0 "
        placeholder={placeholder}
        onFocus={(e) => setSearchIsStarted(!!e?.target?.value)}
        onChange={(e) => {
          setSearchIsStarted(!!e?.target?.value);
          handleOnChange(e?.target?.value.trim());
        }}
      />
    </div>
  );
};

export default Search;
