import uniqid from 'uniqid';

export const USER_ROLES_FORM_FIELDS = [
  {
    label: 'Campus User',
    labelFor: 'сampus-user',
    radioValue: 'user',
    valueForEdit: 'Campus User',
    key: uniqid(),
  },
  {
    label: 'MV Principal',
    labelFor: 'mv-principal',
    radioValue: 'fdPrincipal',
    valueForEdit: 'MV Principal',
    key: uniqid(),
  },
  {
    label: 'MV Client Service',
    labelFor: 'mv-client-service',
    radioValue: 'fdClientService',
    valueForEdit: 'MV Client Service',
    key: uniqid(),
  },
  {
    label: 'MV Analyst',
    labelFor: 'mv-analyst',
    radioValue: 'fdAnalyst',
    valueForEdit: 'MV Analyst',
    key: uniqid(),
  },
  {
    label: 'Super Admin',
    labelFor: 'super-admin',
    radioValue: 'superAdmin',
    valueForEdit: 'Super Admin',
    key: uniqid(),
  },
];
