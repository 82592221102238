import React from 'react';

import Quote from 'containers/user/learn/components/quote/quote';
import Callout from 'containers/user/learn/components/callout/callout';
import Paragraph from 'containers/user/learn/components/paragraph/paragraph';
import ImageComponent from 'containers/user/learn/components/img-component/img-component';
import VideoComponent from 'containers/user/learn/components/video-component/video-component';
import VimeoComponent from 'containers/user/learn/components/vimeo-component/vimeo-component';
import UploadButton from 'containers/user/learn/components/upload-button/upload-button';

export const getBlocksFromContent = ({
  id,
  type,
  text,
  variant = '',
  title = '',
  author = '',
  videoSrc = {},
  imageSrc = {},
  file,
  btnText,
  key,
  vimeoLink,
}) => {
  switch (type) {
    case 'quote':
      return <Quote key={key} {...{ text, author }} />;
    case 'callout':
      return <Callout key={key} {...{ text, variant }} />;
    case 'paragraph':
      return <Paragraph key={key} {...{ id, text, title }} />;
    case 'image':
      return (
        <ImageComponent
          key={key}
          src={imageSrc?.data?.attributes?.url}
          height={imageSrc?.data?.attributes?.height}
          width={imageSrc?.data?.attributes?.width}
        />
      );
    case 'video':
      return <VideoComponent key={key} src={videoSrc?.data?.attributes?.url} />;
    case 'vimeoVideo':
      return <VimeoComponent key={key} vimeoLink={vimeoLink} />;
    case 'uploadButton':
      return <UploadButton key={key} label={btnText} file={file} />;
    default:
      break;
  }
};
