import Icons from 'assets/icons';

const Author = ({ avatar, name, position }) => (
  <div className="flex items-center bg-gray-00 py-2.5 px-4 rounded-md">
    {avatar ? (
      <img
        src={avatar}
        alt="Author Avatar"
        className="flex h-10 w-10 justify-center rounded-[50%] mr-1"
      />
    ) : (
      <Icons.Profile className="w-10 h-10 text-gray-05 mr-1" />
    )}
    <div>
      <div className="text-dark-01 font-semibold">{name}</div>
      <div className="text-gray-03 text-sm">{position}</div>
    </div>
  </div>
);

export default Author;
