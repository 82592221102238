import classNames from 'classnames';
import React from 'react';
import uniqid from 'uniqid';
import { useParams } from 'react-router-dom';

import GridMatrixCell from 'containers/admin/clients/client/tabs-components/goals-tab/goals-matrix/goals-matrix-cell/goals-matrix-cell';
import EmptySpaceContent from 'components/partials/empty-space-content/empty-space-content';

import { history } from 'state/history';
import Icons from 'assets/icons';
import Loader from 'components/partials/loader/loader';

const GoalsMatrix = ({
  headers,
  rows,
  data,
  showOnlyDefined,
  year,
  loading,
}) => {
  const { clientId } = useParams();

  const getCellData = (row, header) =>
    data.filter((item) => item.category === row && item.type === header);

  const handleCellClick = (cell) => {
    const goalId = cell.id ? '' : 'new';
    const type = cell.type.replace(' ', '-').toLowerCase();
    const category = cell.category.replace(' ', '-').toLowerCase();

    history.push(
      `/admin/clients/${clientId}/goals/${year}/${type}/${category}/${goalId}`
    );
  };

  const filteredHeaders = showOnlyDefined
    ? headers.filter((header) => data.some((item) => item.type === header))
    : headers;

  const filteredRows = showOnlyDefined
    ? rows.filter((row) => data.some((item) => item.category === row))
    : rows;

  const isTableEmpty =
    filteredRows.length === 0 || filteredHeaders.length === 0;

  const headerBasicStyles =
    'p-3 bg-gray-00 text-blue-03 font-semibold text-xs text-left tracking-widest';

  const gridTemplateColumns = `195px repeat(${filteredHeaders.length}, minmax(0, 1fr))`; // It's dynamic style and we can't use tailwind atomic class here

  return (
    <div className="overflow-x-auto">
      <Loader loading={loading} fullScreen={false} customClass="opacity-60" />
      {isTableEmpty ? (
        <EmptySpaceContent
          image={<Icons.EmptyImage customClass="w-[210px] h-[210px]" />}
          text="There is no goal to display yet."
        />
      ) : (
        <table className="w-full border-separate border-spacing-0 mobile:min-w-max sm:min-w-full">
          <thead>
            <tr
              className="grid mb-2"
              style={{
                gridTemplateColumns,
              }}
            >
              <th className={`rounded-l-md ${headerBasicStyles}`} scope="col">
                {'Goal Category'.toUpperCase()}
              </th>
              {filteredHeaders.map((header, i) => (
                <th
                  key={header}
                  className={classNames(
                    'border-r border-white',
                    headerBasicStyles,
                    {
                      'rounded-r-md !border-r-0':
                        i === filteredHeaders.length - 1,
                      'border-l': i === 0,
                    }
                  )}
                  scope="col"
                >
                  {header.toUpperCase()}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="border border-gray-05">
            {filteredRows.map((row, rowIndex) => (
              <tr
                key={uniqid()}
                className={classNames('grid border-spacing-0', {
                  'rounded-t-md': rowIndex === 0,
                  'rounded-b-md': rowIndex === filteredRows.length - 1,
                })}
                style={{
                  gridTemplateColumns,
                }}
              >
                <td
                  className={classNames(
                    'border border-r-0 border-gray-05 p-2 font-semibold',
                    {
                      'rounded-tl-md': rowIndex === 0,
                      'rounded-bl-md': rowIndex === filteredRows.length - 1,
                      'border-t-0': rowIndex !== 0,
                    }
                  )}
                  scope="row"
                >
                  {row}
                </td>
                {filteredHeaders.map((header, i) => {
                  const cellData = getCellData(row, header);

                  return (
                    <td
                      key={header}
                      className={classNames(
                        'border border-gray-05 cursor-pointer',
                        {
                          'rounded-tr-md':
                            rowIndex === 0 && i === filteredHeaders.length - 1,
                          'rounded-br-md':
                            rowIndex === filteredRows.length - 1 &&
                            i === filteredHeaders.length - 1,
                          'border-t-0': rowIndex !== 0,
                          'border-l-0': i !== 0,
                        }
                      )}
                    >
                      <GridMatrixCell
                        cellData={cellData}
                        onClick={handleCellClick}
                        row={row}
                        header={header}
                      />
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default GoalsMatrix;
