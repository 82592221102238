import React, {
  createContext,
  useState,
  useLayoutEffect,
  useEffect,
} from 'react';
import { Link as RouterLink } from 'react-router-dom';

import MainNav from 'components/shared/others/header/main-nav/main-nav';
import HeaderItem from 'components/shared/others/header/header-item/header-item';
import WelcomeUser from 'components/shared/others/header/welcome-user/welcome-user';

import { useCurrentUser } from 'state/current-user/current-user.thunk';

import {
  ADMIN_MENU_ITEMS,
  USER_MENU_ITEMS,
} from 'components/shared/others/header/header.constants';

import Icons from 'assets/icons';

export const HeaderContext = createContext({
  isLearnSectionOpen: false,
  setIsLearnSectionOpen: () => {},
});

const Header = () => {
  const user = useCurrentUser();

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobileMenu, setIsMobileMenu] = useState(false);
  const [isLearnSectionOpen, setIsLearnSectionOpen] = useState(false);
  const [learnSectionItemsCount, setLearnSectionItemsCount] = useState(0);
  const isAdmin = user?.data?.fdUser;
  const menuItems = isAdmin ? ADMIN_MENU_ITEMS : USER_MENU_ITEMS;

  const getActiveMenuItem = () => {
    const activeItem = menuItems.find(
      (item) => item.route && window.location.pathname.includes(item.route)
    );

    return (
      <HeaderItem
        isMobileView={true}
        iconBefore={activeItem?.icon}
        isBurgerItem={true}
      >
        {activeItem?.name}
      </HeaderItem>
    );
  };

  useLayoutEffect(() => {
    function updateSize() {
      setIsMobileMenu(window.innerWidth < 960);
      setIsMobileMenuOpen(false);
      setIsLearnSectionOpen(false);
    }
    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    !isLearnSectionOpen && setLearnSectionItemsCount(0);
  }, [isLearnSectionOpen]);

  return (
    <HeaderContext.Provider
      value={{
        isLearnSectionOpen,
        setIsLearnSectionOpen,
        setLearnSectionItemsCount,
        learnSectionItemsCount,
        isMobileMenu,
      }}
    >
      <div className="relative bg-white shadow-md grid mobile:grid-cols-[2fr,1fr] xs:grid-cols-[0.3fr,2fr,1fr]">
        <div className="flex self-baseline">
          <RouterLink
            to="/home"
            className="h-full mobile:w-[188px] tablet:w-[214px]"
          >
            <Icons.Logo alt="MARKETview logo" height="100%" width="100%" />
          </RouterLink>
        </div>
        {/* Mobile view with Burger btn */}
        <div className="grid grid-cols-inherit mr-10 justify-self-end self-center xs:hidden">
          {getActiveMenuItem()}
          <Icons.Burger
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            customClass="justify-self-end ml-2"
            alt="burger-btn"
            height="24"
            width="24"
          />
        </div>
        {(!isMobileMenu || isMobileMenuOpen) && (
          <>
            <MainNav
              isMobileMenuOpen={isMobileMenuOpen}
              setIsMobileMenuOpen={setIsMobileMenuOpen}
              isMobileMenu={isMobileMenu}
              menuItems={menuItems}
            />
            <WelcomeUser
              isAdmin={isAdmin}
              user={user}
              menuItemsLength={menuItems.length}
            />
          </>
        )}
      </div>
    </HeaderContext.Provider>
  );
};

export default Header;
