import React from 'react';

import Icons from 'assets/icons';

export const getStatusLabel = (value, statuses) => {
  const defaultIconProps = {
    width: '12px',
    height: '100%',
    className: 'pt-px',
  };

  const commonStyles =
    'flex items-center font-xs font-semibold py-1.5 px-2 rounded-md w-fit';

  const commonValueStyles = 'pl-1 lowercase first-letter:uppercase';

  switch (value) {
    case statuses.ACTIVE:
      return (
        <div className={`${commonStyles} text-green-02 bg-green-04`}>
          <Icons.Active alt="active-status" {...defaultIconProps} />
          <span className={commonValueStyles}>{value}</span>
        </div>
      );
    case statuses.EXPIRED:
      return (
        <div className={`${commonStyles} text-red-00 bg-red-03`}>
          <Icons.Expired alt="expired-status" {...defaultIconProps} />
          <span className={commonValueStyles}>{value}</span>
        </div>
      );
    case statuses.HIDDEN:
      return (
        <div className={`${commonStyles} text-yellow-02 bg-amber-01`}>
          <Icons.Hidden alt="hidden-status" {...defaultIconProps} />
          <span className={commonValueStyles}>{value}</span>
        </div>
      );
    case statuses.ARCHIVED:
      return (
        <div className={`${commonStyles} text-red-00 bg-red-03`}>
          <Icons.Archived alt="archived-status" {...defaultIconProps} />
          <span className={commonValueStyles}>{value}</span>
        </div>
      );
    case statuses.MOCKED:
      return (
        <div className={`${commonStyles} text-indigo-02 bg-sky-100`}>
          <Icons.Expired alt="mocked-status" {...defaultIconProps} />
          <span className={commonValueStyles}>{value}</span>
        </div>
      );
    default:
      return (
        <div className={`${commonStyles} text-yellow-02 bg-amber-01`}>
          <Icons.Warning alt="expiring-status" {...defaultIconProps} />
          <span className={commonValueStyles}>{value}</span>
        </div>
      );
  }
};
