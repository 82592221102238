import NotificationPoint from 'components/partials/notification-point/notification-point';

import Icons from 'assets/icons';

export const ADMIN_MENU_ITEMS = [
  {
    name: 'Clients',
    route: '/admin/clients',
    icon: <Icons.Clients />,
  },
  {
    name: 'Users',
    route: '/admin/users',
    icon: <Icons.Users />,
  },
  {
    name: 'Profile',
    route: '/admin/profile',
    icon: <Icons.Settings />,
  },
];

export const USER_MENU_ITEMS = [
  {
    name: 'Home',
    route: '/home',
    icon: <Icons.Home />,
  },
  {
    name: 'Reports',
    route: '/reports',
    icon: <Icons.Reports />,
    iconAfter: <Icons.Arrow customClass="rotate-90 w-4" />,
  },
  {
    name: 'My Files',
    route: '/files',
    icon: <Icons.MyFiles />,
    iconAfter: <NotificationPoint />,
  },
  {
    name: 'Learn',
    route: '/learn',
    icon: <Icons.Help />,
    iconAfter: <Icons.Arrow customClass="rotate-90 w-4" />,
  },
  {
    name: 'Profile',
    route: '/profile',
    icon: <Icons.Settings />,
  },
];
