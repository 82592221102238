import React, { useState, useEffect } from 'react';

import Heading from 'components/partials/heading/heading';
import Search from 'components/partials/custom-search/custom-search';
import TableComponent from 'components/partials/table-component/table-component';
import Loader from 'components/partials/loader/loader';
import EmptySpaceContent from 'components/partials/empty-space-content/empty-space-content';

import api from 'api';

import { getFilesTableSettings } from 'containers/user/files/files-table-settings';

import { useCurrentUser } from 'state/current-user/current-user.thunk';
import { useFiles } from 'state/files/files.thunk';
import { useImpersonation } from 'state/impersonation/impersonation.thunk';

import Icons from 'assets/icons';
import ResponsiveWrapper from 'components/shared/others/responsive-wrapper/responsive-wrapper';

const Files = () => {
  const { load } = useFiles();

  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [data, setData] = useState([]);
  const [isErrorOccured, setIsErrorOccured] = useState(false);

  const [{ pageIndex, pageSize, totalRecords }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
    totalRecords: 0,
  });

  const [sortingParams, setSortingParams] = useState([
    { id: 'asset.createdDate', desc: false },
  ]);

  const currentUser = useCurrentUser();
  const impersonation = useImpersonation();

  const columns = getFilesTableSettings(currentUser, setIsDownloading);

  useEffect(() => {
    setIsLoading(true);
    load({
      pageIndex,
      pageSize,
      sortBy: [{ id: 'viewed', desc: false }, ...sortingParams],
      filters: {
        search: { value: search },
      },
    })
      .then((response) => {
        if (response?.pagination) {
          const {
            pagination: { page, pageSize, totalRecords },
          } = response;

          setPagination({ pageIndex: page, pageSize, totalRecords });
          setData(response?.data);
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsErrorOccured(true);
        setIsLoading(false);
      });
  }, [pageIndex, pageSize, sortingParams, search]);

  useEffect(
    () => () => {
      if (!impersonation?.impersonator && currentUser.filesCount) {
        api.notifyFilesAreViewed(currentUser.data.oktaUserId);
        currentUser.setNewFilesCount();
      }
    },
    []
  );

  return (
    <ResponsiveWrapper customClass="flex flex-col mt-8 relative flex-1">
      <Heading customClass="mb-6" variant="h2">
        My Files
      </Heading>

      <Search
        {...{
          search,
          setSearch,
          customClass: 'mb-5',
          customClassIcon: 'left-[1rem]',
        }}
      />

      {!isErrorOccured && !!data.length ? (
        <>
          <TableComponent
            columns={columns}
            data={data}
            isLoading={isLoading}
            pageIndex={pageIndex}
            pageSize={pageSize}
            setPagination={setPagination}
            sortingParams={sortingParams}
            manualSorting={true}
            setSortingParams={setSortingParams}
            totalRecords={totalRecords}
          />
        </>
      ) : isLoading && !data.length ? (
        <div className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2">
          <Loader {...{ loading: isLoading, fullScreen: false }} />
        </div>
      ) : search ? (
        <EmptySpaceContent
          image={<Icons.EmptyImageAll customClass="w-[210px] h-[210px]" />}
          text="Sorry, no results found. Please try a different keyword."
        />
      ) : (
        <EmptySpaceContent
          image={<Icons.EmptyImage customClass="w-[210px] h-[210px]" />}
          text="No files have been assigned yet"
        />
      )}
      {isDownloading && <Loader {...{ loading: isDownloading }} />}
    </ResponsiveWrapper>
  );
};

export default Files;
