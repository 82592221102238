import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import CustomButton from 'components/partials/custom-button/custom-button';
import { useImpersonation } from 'state/impersonation/impersonation.thunk';
import { useUsers } from 'state/users/users.thunk';

const ImpersonationBanner = () => {
  const users = useUsers();
  const {
    isImpersonating,
    impersonatingUser,
    impersonate,
    stopImpersonating,
    // ...impersonation
  } = useImpersonation();

  useEffect(() => {
    if (isImpersonating() && !impersonatingUser) {
      // localStorage has an impersonation ID, but we don't have the user data in state.
      // Need to reload the relevant users.
      // (This will happen if the page is refreshed, for instance.)

      const impersonatingId = isImpersonating().id;

      users.find(impersonatingId).then((user) => {
        impersonate(user.data);
      });
    }
    // eslint-disable-next-line
  }, []);

  if (!impersonatingUser) return null;

  return (
    <div className="flex bg-blue-03 px-8 py-6 justify-center h-12 z-[600] text-white">
      <div className="flex items-center">
        <p className="mr-4 text-base">Viewing as:</p>
        <p className="font-bold text-base">
          {impersonatingUser?.client?.name || impersonatingUser?.client?.label}
        </p>
        <CustomButton
          variant="inverse"
          customClass="ml-4 bg-transparent"
          size="sm"
          onClick={() => stopImpersonating()}
        >
          Exit preview
        </CustomButton>
      </div>
    </div>
  );
};

export default withRouter(ImpersonationBanner);
