import { useState } from 'react';
import classNames from 'classnames';
import CustomButton from 'components/partials/custom-button/custom-button';
import ResponsiveWrapper from 'components/shared/others/responsive-wrapper/responsive-wrapper';
import Icons from 'assets/icons';

const SeeMoreButton = ({ onClick, showAll = false }) => {
  const [buttonText, setButtonText] = useState('See more');

  return (
    <ResponsiveWrapper md="1120" lg="1120">
      <CustomButton
        variant="secondary"
        customClass="mt-10"
        iconAfter={
          <Icons.DoubleArrow
            className={classNames('ml-2 w-3 h-3', {
              '-rotate-90': showAll,
              'rotate-90': !showAll,
            })}
          />
        }
        onClick={() => {
          onClick();
          setButtonText(showAll ? 'See more' : 'See less');
        }}
      >
        {buttonText}
      </CustomButton>
    </ResponsiveWrapper>
  );
};

export default SeeMoreButton;
