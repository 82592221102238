import { useContext, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import uniqid from 'uniqid';

import { getLearnPopoverMenuItems } from 'components/shared/others/header/learn-popover/learn-popover.constants';
import { HeaderContext } from 'components/shared/others/header/header';

import { FeatureFlagsContext } from 'app';

export const LearnMobileSection = () => {
  const [menuItems, setMenuItems] = useState([]);
  const { featureFlags } = useContext(FeatureFlagsContext);
  const { setLearnSectionItemsCount } = useContext(HeaderContext);

  useEffect(() => {
    const { articles, downloads, guides, webinars, courses } = featureFlags;
    const menuItems = getLearnPopoverMenuItems({
      articles,
      downloads,
      guides,
      webinars,
      courses,
    }).filter((el) => !el.disabled);

    setMenuItems(menuItems);

    const totalItemsCount = menuItems.reduce((acc, item) => {
      const visibleOptionsCount = item.options.filter(
        (subItem) => !subItem.disabled
      ).length;

      return acc + visibleOptionsCount;
    }, 0);

    setLearnSectionItemsCount(totalItemsCount + 1);
  }, [featureFlags]);

  const basicSubItemStyle = 'mobile:pl-11 mobile:pt-2';

  const checkSubItemIsActive = (route) =>
    window.location.pathname === route
      ? `${basicSubItemStyle} text-indigo-02`
      : basicSubItemStyle;

  return (
    <div className="grid text-gray-04">
      <RouterLink to="/learn" className={checkSubItemIsActive('/learn')}>
        Learning Hub
      </RouterLink>
      <a className="" href="/learn"></a>
      {menuItems.map(({ options }) =>
        options
          .filter((item) => !item.disabled)
          .map(({ route, name }) => (
            <RouterLink
              to={route}
              className={checkSubItemIsActive(route)}
              key={uniqid()}
            >
              {name}
            </RouterLink>
          ))
      )}
    </div>
  );
};

export default LearnMobileSection;
