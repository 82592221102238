import React, { useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';

import CustomInput from 'components/partials/custom-text-input/custom-text-input';
import CustomSelect from 'components/partials/custom-select/custom-select';

import { BASIC_INFO_FORM_FIELDS } from 'containers/admin/clients/client/forms/basic-info-form/basic-info-form-fileds.constants';

const BasicInfoForm = ({ isFormDisabled, control, errors, clearErrors }) => {
  const [basicInformationFields, setBasicInformationFields] = useState(
    BASIC_INFO_FORM_FIELDS
  );

  const filterBasicFields = (key) =>
    BASIC_INFO_FORM_FIELDS.filter(
      (field) => field.mode === key || field.mode === 'CREATE_AND_EDIT_MODE'
    );

  useEffect(() => {
    const filterKey = isFormDisabled ? 'EDIT_MODE' : 'CREATE_MODE';

    setBasicInformationFields(filterBasicFields(filterKey));
  }, []);

  return (
    <div className="flex flex-col w-3/6 mr-16">
      {basicInformationFields.map(
        ({ label, name, key, rules, isSelect, componentProps }) => (
          <Controller
            key={key}
            name={name}
            control={control}
            rules={rules}
            render={({ field }) =>
              isSelect ? (
                <CustomSelect
                  fieldError={errors[name]}
                  label={label}
                  clearInputErr={() => clearErrors(name)}
                  labelFor={name}
                  errors={errors}
                  disabled={isFormDisabled}
                  labelInTop
                  disabledBgColor={isFormDisabled && 'rgb(242 242 242)'}
                  required={!isFormDisabled}
                  {...field}
                  {...componentProps}
                />
              ) : (
                <CustomInput
                  errorsNames={errors[name]}
                  label={label}
                  labelInTop
                  labelFor={name}
                  disabled={isFormDisabled}
                  disabledClassName={'bg-gray-06'}
                  required={!isFormDisabled}
                  {...field}
                  {...componentProps}
                />
              )
            }
          />
        )
      )}
    </div>
  );
};

export default BasicInfoForm;
