import React from 'react';

import { formatDate } from 'helpers/dates-formatter';
import Author from 'containers/user/learn/components/author/author';

import Icons from 'assets/icons';

const BasicInfo = ({
  authorName = '',
  authorPosition = '',
  authorAvatar,
  readTime = 1,
  updatedAt = '',
}) => (
  <div className="flex mobile:flex-col tablet:flex-row md:flex-col justify-between items-baseline pt-3 mobile:mb-6 xs:mb-8">
    <Author
      avatar={authorAvatar?.data[0]?.attributes?.url}
      name={authorName}
      position={authorPosition}
    />
    <div className="flex text-blue-03 pt-4">
      <div className="flex items-center mr-6">
        <Icons.Calendar customClass="mr-1" />
        {`Updated on ${formatDate(updatedAt, 'MMM D, YYYY')}`}
      </div>
      <div className="flex items-center">
        <Icons.OpenBook customClass="mt-0.5 mr-1" />
        {`${readTime} min`}
      </div>
    </div>
  </div>
);

export default BasicInfo;
