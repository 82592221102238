import React from 'react';

import Reports from 'containers/user/reports/reports';

const BOTTOM_OFFSET = 150;

const ReportsPopover = () => (
  <div className="absolute top-14 left-0 right-0 mx-auto z-[700] max-w-[1440px] min-w-[880px] w-[90%]">
    <div
      className="bg-white rounded-b-lg overflow-hidden flex flex-col p-8 pt-0"
      style={{ maxHeight: `calc(100vh - ${BOTTOM_OFFSET}px)` }}
    >
      <Reports isPopover={true} />
    </div>
  </div>
);

export default ReportsPopover;
