import ResponsiveWrapper from 'components/shared/others/responsive-wrapper/responsive-wrapper';
import React from 'react';

const GetStartedGuide = () => (
  <ResponsiveWrapper customClass="flex flex-col">
    Get Started Guide Page
  </ResponsiveWrapper>
);

export default GetStartedGuide;
