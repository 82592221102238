export const HEADERS = {
  volume: 'Volume',
  proportion: 'Proportion',
  rate: 'Rate',
  financial: 'Financial',
  retention: 'Retention',
  'academic-quality': 'Academic Quality',
};

export const ROWS = {
  topline: 'Topline',
  demographic: 'Demographic',
  geographic: 'Geographic',
  'academic-program': 'Academic Program',
  'financial-profile': 'Financial Profile',
  combined: 'Combined',
  other: 'Other',
};

export const TYPES_WITHOUT_SUFFIXES = ['Volume', 'Academic Quality'];
