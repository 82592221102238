import React from 'react';

import CustomButton from 'components/partials/custom-button/custom-button';
import Icons from 'assets/icons';
import classNames from 'classnames';

const Label = ({ children }) => (
  <div className="text-indigo-04 text-base font-semibold mb-1">{children}</div>
);

const Value = ({ children }) => (
  <div className="text-indigo-02 text-xl font-bold capitalize">{children}</div>
);

const GoalsManagerHeader = ({
  type,
  category,
  year,
  handleNewGoalClick,
  isCreation,
  isEdition,
  customClass,
}) => (
  <div
    className={classNames(
      'flex justify-between items-end flex-wrap',
      customClass
    )}
  >
    <div className="flex gap-6">
      <div>
        <Label>Category</Label>
        <Value>{category}</Value>
      </div>
      <div>
        <Label>Type</Label>
        <Value>{type}</Value>
      </div>
      <div>
        <Label>Entry term</Label>
        <Value>{year}</Value>
      </div>
    </div>

    <CustomButton
      variant="primary"
      onClick={handleNewGoalClick}
      disabled={isCreation || isEdition}
      size="lg"
      iconBefore={<Icons.Plus alt="PlusIcon" width="14" />}
    >
      Add a goal
    </CustomButton>
  </div>
);

export default GoalsManagerHeader;
