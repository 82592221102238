import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';

import LoginLayout from 'containers/login/login-layout';
import OktaSignInWidget from 'containers/login/okta-widget/okta-sign-in-widget';
import ImplicitCallback from 'containers/login/okta-widget/implicit-callback';

import { useCurrentUser } from 'state/current-user/current-user.thunk';
import 'containers/login/okta-widget/okta-login.css';

const OktaLogin = ({ auth }) => {
  const user = useCurrentUser();

  useEffect(() => {
    // This local storage key seems to sometimes not be removed after a superadmin impersonates (maybe from
    // timeout). The app attempts to impersonate when this key is present however, so when the user
    // attempts to login again as a non-superadmin, a 403 is thrown as that user is not able to
    // impersonate. Using this to ensure this key is removed on the login screen
    window.localStorage.removeItem('fd_okta-impersonating-id');
  }, []);

  return (
    <>
      <Route
        path="/login"
        render={() => (
          <LoginLayout withFooter={true}>
            <OktaSignInWidget />
          </LoginLayout>
        )}
      />
      <Route
        path="/implicit/callback"
        render={() => (
          <ImplicitCallback
            afterLogin={() => {
              user.setLastLogin();
            }}
          />
        )}
      />
    </>
  );
};

export default OktaLogin;
