import uniqid from 'uniqid';

export const USER_DETAILS_FORM_FIELDS = [
  {
    name: 'email',
    label: 'Email address',
    renderComponentProps: {
      helperText:
        'Please use an email address associated with one of the following domains:',
    },
    key: uniqid(),
    mode: 'CREATE_AND_EDIT_MODE',
  },
  {
    name: 'confirmEmail',
    label: 'Confirm email',
    renderComponentProps: {
      helperText: 'Please ensure the emails match',
    },
    key: uniqid(),
    mode: 'CREATE_MODE',
  },
  {
    name: 'role',
    label: 'Role',
    isSelect: true,
    key: uniqid(),
    mode: 'CREATE_AND_EDIT_MODE',
    alwaysVisible: true,
  },
  {
    name: 'client',
    label: 'Client',
    isSelect: true,
    key: uniqid(),
    mode: 'CREATE_AND_EDIT_MODE',
  },
];
