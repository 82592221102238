import { IMPERSONATE_KEY } from 'state/impersonation/impersonation.slice';

const OKTA_KEY = 'okta-token-storage';
const REFFERER_PATH = 'secureRouterReferrerPath';

// Okta session API requests
const getOktaSessionInfo = () => {
  const oktaStorage = JSON.parse(window.localStorage.getItem(OKTA_KEY));

  return oktaStorage;
};

export const clearOktaSessionInfo = () => {
  window.localStorage.removeItem(OKTA_KEY);
  window.localStorage.removeItem(IMPERSONATE_KEY);
  window.localStorage.removeItem(REFFERER_PATH);
};

export const getOktaBearerToken = () => {
  // Add our Okta auth header
  const oktaStorage = getOktaSessionInfo();
  let token = null;

  if (oktaStorage && oktaStorage.accessToken) {
    token = oktaStorage.accessToken.accessToken;
  }

  return token;
};

export const getOktaUserId = () => {
  // First see if we're impersonating someone; if so return that ID.
  const impersonatingUserInfo = JSON.parse(
    window.localStorage.getItem(IMPERSONATE_KEY)
  );

  if (impersonatingUserInfo) return impersonatingUserInfo.oktaUserId;

  // Or grab the user ID from the Okta session object
  const oktaStorage = getOktaSessionInfo();

  if (oktaStorage && oktaStorage.idToken) {
    return oktaStorage.idToken.claims.sub;
  }

  return null;
};
