import { Link as RouterLink } from 'react-router-dom';
import classNames from 'classnames';

import CustomButton from 'components/partials/custom-button/custom-button';
import { getStatusLabel } from 'components/partials/table-component/helpers/get-status-label';

import { USERS_STATUSES } from 'containers/admin/users/users.constants';

import { formatDate } from 'helpers/dates-formatter';

import Icons from 'assets/icons';

const commonStyles = 'border-r-2 border-white font-semibold';

export const getUsersTableSettings = ({ clientId, impersonation }) =>
  [
    {
      id: 'firstName',
      accessorKey: 'firstName',
      header: () => <span>FIRST NAME</span>,
      cell: (info) => info.renderValue(),
      headerClassName: `${commonStyles} rounded-tl-md rounded-bl-md cursor-pointer`,
      className: classNames('text-left pl-4 w-[14%]'),
      enableSorting: true,
      sortingFn: 'alphanumeric',
    },
    {
      id: 'lastName',
      accessorKey: 'lastName',
      header: () => <span>LAST NAME</span>,
      cell: (info) => info.renderValue(),
      headerClassName: `${commonStyles} cursor-context-menu`,
      className: classNames('text-left pl-4', {
        'w-[14%] sm:w-[15%]': !clientId,
        'w-[14%] md:w-[16%]': clientId,
      }),
      enableSorting: false,
    },
    !clientId && {
      id: 'client.name',
      accessorKey: 'client.name',
      header: () => <span>CLIENT NAME</span>,
      cell: (info) => info.renderValue(),
      headerClassName: `${commonStyles} cursor-pointer`,
      className: classNames('text-left pl-4', {
        'w-[16%] sm:w-[15%]': !clientId,
        'w-[18%] md:w-[17%]': clientId,
      }),
      enableSorting: true,
      sortingFn: 'alphanumeric',
    },
    {
      id: 'jobTitle',
      accessorKey: 'jobTitle',
      header: () => <span>JOB TITLE</span>,
      cell: (info) => info.renderValue(),
      headerClassName: `${commonStyles} cursor-context-menu`,
      className: classNames('pl-4 w-[15%]', {
        'md:w-[13%]': !clientId,
        'md:w-[15%]': clientId,
      }),
      enableSorting: false,
    },
    {
      id: 'lastLogin',
      accessorKey: 'lastLogin',
      header: () => <span>LAST LOGIN</span>,
      cell: (info) => {
        const date = formatDate(
          info.row.original.lastLogin,
          'MMM D, YYYY h:mm A'
        );
        const label =
          info.row.original.status === 'LOCKED' &&
          getStatusLabel(info.row.original.status, USERS_STATUSES);

        return (
          <div className="flex items-center">
            <span className="pr-3">{date || 'Never'}</span>
            {label}
          </div>
        );
      },
      headerClassName: `${commonStyles} cursor-pointer`,
      className: classNames('p-4', {
        'w-[16%] sm:w-[18%] md:w-[18%]': !clientId,
        'w-[16%] md:w-[20%] ': clientId,
      }),
      enableSorting: true,
    },
    {
      id: 'profile',
      accessorKey: 'profile',
      header: () => <span>PROFILE</span>,
      cell: (info) => {
        const {
          row: {
            original: { id },
          },
        } = info;

        return (
          <div className="flex justify-center items-center">
            <RouterLink
              to={
                clientId
                  ? `/admin/clients/${clientId}/users/${id}`
                  : `/admin/users/${id}`
              }
            >
              <CustomButton
                variant="secondary"
                size="sm"
                iconBefore={
                  <Icons.Details alt="DetailsIcon" height="100%" width="14" />
                }
                customClass="text-xs md:text-sm !px-2"
                iconBeforeCustomClass="!pr-0"
              />
            </RouterLink>
          </div>
        );
      },
      headerClassName: commonStyles,
      className: classNames('p-4', {
        'w-[8%] sm:w-[8%] md:w-[7%]': !clientId,
        'w-[8%] md:w-[8%] ': clientId,
      }),
      enableSorting: false,
      isHeaderCentered: true,
    },
    {
      id: 'button-cell',
      cell: (info) => {
        const {
          row: {
            original: { client, status, viewPortalAsUser },
          },
        } = info;
        const isViewBtnShown =
          !!client &&
          client.status !== 'Expired' &&
          status !== 'LOCKED' &&
          viewPortalAsUser;

        return (
          <div className="flex justify-center items-center">
            {isViewBtnShown ? (
              <CustomButton
                customClass="max-w-fit text-xs lg:text-sm"
                onClick={() => {
                  impersonation.impersonate(
                    info.row.original,
                    window.location.pathname
                  );
                }}
                size="sm"
                variant="primary"
                iconBefore={
                  <Icons.Eye alt="EyeIcon" height="100%" width="14" />
                }
              >
                View portal as user
              </CustomButton>
            ) : client.status === 'Expired' ? (
              <div className="flex justify-center">
                <CustomButton
                  customClass="max-w-fit mt-0 text-xs lg:text-sm !leading-[11px]"
                  size="sm"
                  variant="disabled"
                  iconBefore={
                    <Icons.CellInfo alt="CellInfo" height="100%" width="16" />
                  }
                  disabled
                >
                  Subscription is expired
                </CustomButton>
              </div>
            ) : status === 'LOCKED' ? (
              <div className="flex justify-center">
                <CustomButton
                  customClass="max-w-fit mt-0 text-xs lg:text-sm !leading-[11px]"
                  size="sm"
                  variant="disabled"
                  iconBefore={
                    <Icons.CellInfo alt="CellInfo" height="100%" width="16" />
                  }
                  disabled
                >
                  User is locked
                </CustomButton>
              </div>
            ) : (
              !viewPortalAsUser && (
                <div className="flex justify-center">
                  <CustomButton
                    customClass="max-w-fit mt-0 text-xs lg:text-sm !leading-[11px]"
                    size="sm"
                    variant="disabled"
                    iconBefore={
                      <Icons.CellInfo alt="CellInfo" height="100%" width="16" />
                    }
                    disabled
                  >
                    MV internal user
                  </CustomButton>
                </div>
              )
            )}
          </div>
        );
      },
      headerClassName: 'h-12 rounded-tr-md rounded-br-md pl-4',
      className:
        'text-center py-3 px-4 w-[16%] md:w-[18%] lg:w-[18%] xl:w-[18%]',
    },
  ].filter((item) => item);
