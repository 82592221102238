import { categories as defaultCategories } from 'containers/admin/clients/client/tabs-components/goals-tab/goals-configuration/categories';

const retentionToplineCategory = {
  name: 'Topline',
  details: {
    required: true,
    associatedPropertyName: 'focusArea',
    displayName: 'Focus Area',
    options: [
      {
        value: 'Retention rate',
        label: 'Retention rate',
      },
    ],
  },
};

const categories = [
  retentionToplineCategory,
  ...defaultCategories.filter((category) => category.name !== 'Topline'),
];

export const Retention = {
  Retention: {
    subType: null,
    valueType: 'PERCENT',
    categories,
  },
};
