/**
 * These transform the JSON data from the server before storing it in the Redux store.
 **/

import { ROLES } from 'constants/roles';
import { getUserRole } from 'helpers/forms';

const transformers = {
  user: transformUser,
  client: transformClient,
};

export const transformRecordFromServer = (singularType, record) => {
  if (transformers[singularType]) return transformers[singularType](record);
  else return record;
};

// User transformer: Add name, some role methods
function transformUser(user) {
  const client = user.client && transformClient(user.client);

  return {
    ...user,
    name: `${user.firstName} ${user.lastName}`,
    superAdmin: user.authority === ROLES.SUPER_ADMIN,
    fdPrincipal: user.authority === ROLES.FD_PRINCIPAL,
    fdClientService: user.authority === ROLES.FD_CLIENT_SERVICE,
    fdAnalyst: user.authority === ROLES.FD_ANALYST,
    fdUser:
      user.authority === ROLES.SUPER_ADMIN ||
      user.authority === ROLES.FD_PRINCIPAL ||
      user.authority === ROLES.FD_CLIENT_SERVICE ||
      user.authority === ROLES.FD_ANALYST,
    basicUser: !user.authority === ROLES.SUPER_ADMIN,
    ...getUserCapabilitiesFromUserRole(user.authority),
    officeAddressFull: [
      user.officeAddress1,
      user.officeAddress2,
      user.officeCity,
      `${user.officeState} ${user.officeZip}`,
    ]
      .filter((line) => line && line.trim() !== '')
      .join(', '),

    client: client,
  };
}

const getUserCapabilitiesFromUserRole = (userAuthority) => {
  const capabilities = {
    addAndDeleteClients: false,
    addAndDeleteUsers: false,
    lockAndUnlockClients: false,
    lockAndUnlockAllUsers: false,
    lockAndUnlockClienUsers: false,
    editClient: false,
    editClientUser: false,
    editFdUserRole: false,
    sendPasswordReset: false,
    viewPortalAsUser: false,
  };

  if (!userAuthority) {
    return capabilities;
  } else {
    return {
      ...capabilities,
      addAndDeleteClients: userAuthority === ROLES.SUPER_ADMIN,

      addAndDeleteUsers: userAuthority === ROLES.SUPER_ADMIN,

      lockAndUnlockClients: userAuthority === ROLES.SUPER_ADMIN,

      lockAndUnlockAllUsers: userAuthority === ROLES.SUPER_ADMIN,

      lockAndUnlockClientUsers:
        userAuthority === ROLES.SUPER_ADMIN ||
        userAuthority === ROLES.FD_PRINCIPAL ||
        userAuthority === ROLES.FD_CLIENT_SERVICE,

      editClient:
        userAuthority === ROLES.SUPER_ADMIN ||
        userAuthority === ROLES.FD_PRINCIPAL ||
        userAuthority === ROLES.FD_CLIENT_SERVICE,

      editClientUser:
        userAuthority === ROLES.SUPER_ADMIN ||
        userAuthority === ROLES.FD_PRINCIPAL ||
        userAuthority === ROLES.FD_CLIENT_SERVICE,

      editFdUserRole:
        userAuthority === ROLES.SUPER_ADMIN ||
        userAuthority === ROLES.FD_PRINCIPAL ||
        userAuthority === ROLES.FD_CLIENT_SERVICE,

      sendPasswordReset:
        userAuthority === ROLES.SUPER_ADMIN ||
        userAuthority === ROLES.FD_PRINCIPAL ||
        userAuthority === ROLES.FD_CLIENT_SERVICE,

      viewPortalAsUser: userAuthority === 'ROLE_USER',
    };
  }
};

// Client transformer: transform nested consultant (user) records
function transformClient(client) {
  let primaryAdmin =
    client.consultants &&
    client.consultants.find((c) => c.consultantType === 'PRIMARY_ADMIN');

  // null the client record; avoid infinite loops when transforming the primary admin
  if (primaryAdmin)
    primaryAdmin = {
      ...primaryAdmin,
      user: { ...primaryAdmin.user, client: null },
    };

  return {
    ...client,
    consultants:
      client.consultants &&
      client.consultants.map((consultant) => ({
        ...consultant,
        user: transformUser(consultant.user),
      })),

    // adding this
    _primaryAdmin: primaryAdmin && transformUser(primaryAdmin.user),
  };
}
